<template>
  <div class="service-tag-container is-flex w-100 is-justify-content-center mt-1">
    <Tag
      v-for="(tag, idx) in serviceTags"
      :key="`tag-service-${tag.key}-${idx}`"
      rounded
      :label="tag.label"
      :variant="tag.variant"
    />
  </div>
</template>
<script>
import { computed } from 'vue';
import { Tag } from '@/components';
export default {
  components: { Tag },
  props: {
    data: { type: Array, default: () => [] },
  },
  setup(props) {
    const serviceTags = computed(() => {
      const { data } = props;
      const serviceTagType = {
        AUDITORIA: {
          key: 'Auditoria',
          variant: 'link',
          label: 'Audit.',
        },
        CONTABILIDAD: {
          key: 'Contabilidad',
          variant: 'purple',
          label: 'Cont.',
        },
      };
      const filterServices = data.filter((value) =>
        Object.keys(serviceTagType).includes(value.service_name.toUpperCase()),
      );
      if (!filterServices.length) return [];
      return filterServices.map((tag) => {
        if (tag.service_name === serviceTagType[tag.service_name.toUpperCase()]?.key)
          return serviceTagType[tag.service_name.toUpperCase()];
      });
    });
    return { serviceTags };
  },
};
</script>
<style lang="sass" scoped>
.service-tag-container
    gap: 8px
</style>

export default [
  'addons',
  'group-multiline',
  'grouped',
  'groupMultiline',
  'horizontal',
  'label-for',
  'label-Size',
  'label',
  'labelFor',
  'labelSize',
  'message',
  'mobile-breakpoint',
  'mobileBreakpoint',
  'override',
  'variant',
  'variant-message-class',
  'variantMessageClass',
];

import { schemas } from '@/utils/YupSchemas';
import { reactive, computed, onBeforeMount } from 'vue';
import moment from 'moment';
import * as yup from 'yup';
import Api from '../Api';

const useStages = (props = { loadStageTypes: false, formStep: null }) => {
  const EOM = computed(() => new moment().endOf('month').format('DD'));
  const FORM_TYPES = {
    stage: 'stage',
    monthlyRun: 'monthlyRun',
    plannedRun: 'plannedRun',
  };
  const STAGETYPES = reactive({
    positiveNegative: {
      type: 'positive/negative',
      key: 'positiveNegative',
      name: 'Positiva / Negativa',
      id: null,
      description: 'Una etapa que debe definirse con un valor positivo o negativo.',
      schema: {},
      config: {
        ROJO: { positive_negative_value: 'NEGATIVA' },
        VERDE: { positive_negative_value: 'POSITIVA' },
      },
    },
    positivaNegativaFecha: {
      type: 'Positiva / Negativa / Fecha',
      key: 'positivaNegativaFecha',
      name: 'Positiva / Negativa / Fecha',
      id: null,
      description: 'Una etapa que debe definirse con un valor positivo o negativo.',
      schema: {
        indicatorVerde: schemas.DaySchema,
        indicatorAmarillo: schemas.DaySchema,
        indicatorRojo: schemas.DaySchema,
      },
      config: {
        ROJO: { days: 0, positive_negative_value: 'NEGATIVA' },
        AMARILLO: { days: 0 },
        VERDE: { days: 0, positive_negative_value: 'POSITIVA' },
      },
    },
    date: {
      type: 'date',
      key: 'date',
      name: 'Ejecución por Fecha',
      id: null,
      description:
        'La etapa se marca como Ejecutada/No Ejecutada, pero la semaforización es con base en los rangos de días naturales.',
      schema: {
        indicatorVerde: schemas.DaySchema,
        indicatorAmarillo: schemas.DaySchema,
        indicatorRojo: schemas.DaySchema,
      },
      config: {
        verde: { days: 0 },
        amarillo: { days: 0 },
        rojo: { days: 0 },
      },
    },
    files: {
      type: 'files',
      key: 'files',
      name: 'Archivos',
      id: null,
      description: 'Un listado de archivos que se deben de subir.',
      schema: {},
      config: {},
    },
    filesDate: {
      type: 'files/date',
      key: 'filesDate',
      name: 'Archivos / Fecha',
      id: null,
      description:
        'Un listado de archivos que se deben de subir antes de una fecha de vencimiento, expresada en días naturales.',
      schema: {
        indicatorVerde: schemas.DaySchema,
        indicatorAmarillo: schemas.DaySchema,
        indicatorRojo: schemas.DaySchema,
      },
      config: {
        verde: { days: 0 },
        amarillo: { days: 0 },
        rojo: { days: 0 },
      },
    },
    execution: {
      type: 'status',
      key: 'execution',
      name: 'Ejecución',
      id: null,
      description: '',
      schema: {},
      config: {
        verde: { status: 2 },
      },
    },
  });
  const extencionArchivos = [
    {
      name: '.doc',
    },
    {
      name: '.docx',
    },
    {
      name: '.odt',
    },
    {
      name: '.xls',
    },
    {
      name: '.ods',
    },
    { name: '.pdf' },
    {
      name: 'Todos',
    },
  ];
  const OMISSIBLE = {
    0: { name: 'Obligatorio', value: false },
    1: { name: 'Omisible', value: true },
  };
  const RESPONSIBLE_ENTITIES = {
    USUARIO: 'USUARIO',
    CLIENTE: 'CLIENTE',
  };

  const buildDocumentJson = (files) => {
    if (files.stage_type_name !== 'Ejecución') {
      const formatFileMasks = (masks) => {
        const isAllMasks = masks.map((mask) => mask.name).includes('Todos');
        const masksFiltered = isAllMasks
          ? ['.doc', '.docx', '.odt', '.xls', '.xlsx', '.ods', '.pdf']
          : masks.map((mask) => `'${mask.name}'`);
        return masksFiltered.join(', ');
      };
      const _files = files.map((file) => ({
        ...file,
        file_masks: `[${formatFileMasks(file.file_masks)}]`,
      }));
      return JSON.stringify(_files);
    }
  };
  const getStageTypeByName = (name) => {
    const stageTypeKey = Object.keys(STAGETYPES).find((type) => STAGETYPES[type].name === name);
    return STAGETYPES[stageTypeKey];
  };
  const getStageTypeById = (id) => {
    const stageTypeKey = Object.keys(STAGETYPES).find((type) => STAGETYPES[type].id === id);
    return STAGETYPES[stageTypeKey];
  };
  const getStageTypesId = async () => {
    try {
      const { data } = await Api.get('stage-types');
      data.forEach(({ id, name }) => {
        const stageType = getStageTypeByName(name);
        if (stageType) STAGETYPES[stageType.key].id = id;
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getServiceId = async (service) => {
    try {
      const { data } = await Api.get(`/services?name=${service}`);
      return data[0].id;
    } catch (error) {
      console.log(error);
    }
  };
  const getOmissibleNameByID = (omissible_id) => OMISSIBLE[omissible_id].name;
  const getStateType = (stateType = '') => {
    if (!stateType.length) return STAGETYPES;
  };
  const getStageData = async (id) => {
    try {
      const { data } = await Api.get(`/stages/${id}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getStageNameById = async (id) => {
    try {
      const rowData = Api.get(`/stages/${id}`);
      const stageTypeData = Api.get('/stage-types');
      const promises = [rowData, stageTypeData];
      const results = await Promise.all(promises);
      const stageType = results[1].data.find((type) => type.id === results[0].data.stage_type_id);
      if (stageType) return stageType.name;
    } catch (error) {
      console.log(error);
    }
  };
  const formatName = (fileName = '') => {
    if (fileName.length <= 50) return fileName;
    else {
      const exceededCharacters = fileName.length - 47;
      return `${fileName.slice(0, -exceededCharacters).trim()}...${fileName.slice(
        fileName.length - 4,
      )}`;
    }
  };
  const getFileMasksOnString = (fileMasks) => {
    try {
      return eval(fileMasks)
        .map((ext) => ext.replace('*', ''))
        .join(', ');
    } catch (error) {
      return '';
    }
  };
  const formatedDoc = (docName, fileMasks) => {
    return addExtensionToFileName(formatName(docName), getFileMasksOnString(fileMasks));
  };
  const isDocumentRequired = (document) => {
    return document.required == 'true' ? 'required' : '';
  };
  const addExtensionToFileName = (fileName, extensions) => {
    if (extensions.length > 6) return fileName;
    return `${fileName}${extensions}`;
  };

  // SCHEMA
  const validationSchema = computed(() => {
    const { formStep } = props;
    const schema = {
      initialStep: yup.object({
        stageName: schemas.selectSchema,
        responsibleEntitie: schemas.responsibleEntitieSchema,
        stageTypeName: schemas.stageTypeSchema,
        fieldVerde: yup.string(),
        fieldAmarillo: yup.string(),
        fieldRojo: yup.string(),
        fileName: yup.string(),
        extencionField: yup.array().min(0),
        sequenceField: yup.string(),
      }),
    };
    return formStep ? schema[formStep] : {};
  });
  onBeforeMount(() => {
    if (props.loadStageTypes) getStageTypesId();
  });
  return {
    //Constants
    OMISSIBLE,
    RESPONSIBLE_ENTITIES,
    FORM_TYPES,

    // data
    extencionArchivos,

    // computed
    EOM,

    // config,
    STAGETYPES,

    //Methods
    getOmissibleNameByID,
    getStageNameById,
    getStageTypeById,
    getStateType,
    getStageData,
    getStageTypesId,
    buildDocumentJson,
    formatName,
    addExtensionToFileName,
    getServiceId,
    formatedDoc,
    isDocumentRequired,

    // schemas
    validationSchema,
  };
};
export default useStages;

//semaphoring

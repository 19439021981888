<template>
  <div class="card flex f-column" :class="classes">
    <o-loading :active="loading" :is-full-page="false" />
    <div v-if="title || $slots.header" class="card-header">
      <p v-if="title">{{ title }}</p>
      <slot name="header" />
    </div>
    <div class="card-content">
      <slot />
    </div>
    <div v-if="$slots.footer" class="card-footer">
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
export default {
  props: {
    loading: { type: Boolean, default: false },
    title: { type: String, default: '' },
    hasMinHeight: { type: Boolean, default: true },
    isLoginView: { type: Boolean, default: false },
  },
  setup(props) {
    const classes = computed(() => {
      const classes = [];
      if (props.hasMinHeight) classes.push('hasMin');
      if (props.isLoginView) classes.push('isLoginView');
      return classes.join(' ');
    });
    return { classes };
  },
};
</script>
<style lang="sass" scoped>
.card
    border-radius: 25px
    box-shadow: none
    &.isLoginView
        background: linear-gradient(180deg, #FFFFFF 70.83%, #F3F3F3 100%)
        border-radius: 25px
    &.hasMin
        min-height: 100px
</style>

<template>
  <div>
    <Field
      v-model="hours"
      v-maska="bindedObject"
      :variant="inputController.variant"
      class="time-tracker-field"
      :horizontal="horizontal"
      :label="label"
      placeholder="00:00 horas"
      :disabled="disabled"
      data-maska="##:M#"
      data-maska-tokens="{'M': { 'pattern': '[0-5]'}}"
      :model-value="modelValue"
      :required="required"
      @blur="onBlur"
    />
    <p v-if="inputController.errorMessage.length" class="text-danger">
      {{ inputController.errorMessage }}
    </p>
  </div>
</template>
<script>
import { ref, reactive, watch, toRefs } from 'vue';
import { Field } from '@/components';
export default {
  components: {
    Field,
  },
  props: {
    disabled: { type: Boolean, default: false },
    modelValue: { type: String, default: '' },
    label: { type: String, default: '' },
    isCompleted: { type: Boolean, default: false },
    horizontal: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
  },
  emits: ['update:is-completed', 'update:model-value'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const hours = ref(props.modelValue);
    const bindedObject = reactive({});
    const inputController = reactive({
      variant: '',
      errorMessage: '',
    });

    const onBlur = () => {
      const { completed, unmasked } = bindedObject;
      if (!completed && unmasked?.length) {
        const autoEstimatedTimes = {
          1: `0${unmasked}00`,
          2: `${unmasked}00`,
          3: `${unmasked}0`,
        };
        hours.value = autoEstimatedTimes[unmasked.length];
      }
    };

    watch(modelValue, (v) => (hours.value = v));
    watch(hours, (v) => emit('update:model-value', v));
    watch(
      () => bindedObject.completed,
      (v) => emit('update:is-completed', v),
    );
    watch(hours, () => {
      inputController.variant = '';
      inputController.errorMessage = '';
    });

    return { hours, bindedObject, onBlur, inputController };
  },
};
</script>
<style lang="sass" scoped>
.time-tracker-field
    > :deep(.field-label)
        margin-right: 10px
        .label
            margin: 8px 0
    > :deep(.field-body)
        max-width: 63px
.text-danger
    margin-top: 10px
    margin-bottom: 16px
    color: $danger
    font-size: 13px
    text-align: end
</style>

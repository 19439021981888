import { computed } from 'vue';
import pick from 'ramda/src/pick';
import { ComponentsProps } from '../oruga';
const usePickProps = (componentNameProps, props) => {
  return {
    propsPicked: computed(() =>
      pick(ComponentsProps[componentNameProps], { ...props?.attrs, ...props?.props }),
    ),
  };
};
export default usePickProps;

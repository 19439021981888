<template>
  <o-modal
    v-model:active="isModalActive"
    class="modal-component"
    :width="getWidth"
    :can-cancel="loading.save ? !loading.save : canCancel"
  >
    <div v-if="isModalActive" class="card">
      <div class="card-header">
        <div class="card-header-title">{{ header }}</div>
        <o-icon
          v-if="canCancel"
          icon="close"
          size="small"
          title="Cerrar"
          @click.prevent="isModalActive = false"
        />
      </div>
      <div class="card-content">
        <slot />
      </div>
      <div class="card-footer">
        <template v-if="$slots.footer">
          <slot name="footer" />
        </template>
        <template v-else>
          <Button
            v-if="saveNeed"
            variant="primary"
            :label="buttonLabel"
            :disabled="isDisabled || saveDisabled || loading.save"
            :loading="loading.save"
            @click.prevent="$emit('save')"
          />
          <Button
            variant="danger"
            :label="buttonLabelCancel"
            inverted
            :disabled="loading.save || isDisabled"
            @click.prevent="onCancel"
          />
        </template>
      </div>
    </div>
  </o-modal>
</template>
<script>
import { ref, watch, computed, toRefs } from 'vue';
import { usePickProps } from '@/components/conf/composables';
import { Button } from '@/components';
export default {
  components: { Button },
  props: {
    isOpen: { type: Boolean, default: false },
    header: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false },
    buttonLabel: { type: String, default: 'Guardar' },
    buttonLabelCancel: { type: String, default: 'Cancelar' },
    canCancel: { type: [Array, Boolean], default: () => ['x', 'outside', 'button'] },
    loading: {
      type: Object,
      default() {
        return { get: false, save: false };
      },
    },
    saveNeed: { type: Boolean, default: true },
    saveDisabled: { type: Boolean, default: false },
    size: {
      type: String,
      enum: ['is-small', 'is-medium', 'is-large', 'is-ex-large'],
      default: 'is-small',
    },
    variantCancel: { type: String, default: 'danger' },
  },
  emits: ['update:active', 'cancel', 'update:is-open', 'save'],
  setup(props, { attrs, emit }) {
    const { propsPicked: modalProps } = usePickProps('ModalProps', { props, attrs });
    const isModalActive = ref(props.isOpen);
    const isOpen = toRefs(props).isOpen;
    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
    });
    return {
      modalProps,
      isModalActive,
      onCancel: () => {
        isModalActive.value = false;
        emit('cancel');
      },
      getWidth: computed(() => {
        let width = props.size;
        switch (props.size) {
          case 'is-small':
            width = 400;
            break;
          case 'is-medium':
            width = 650;
            break;
          case 'is-large':
            width = 800;
            break;
          case 'is-ex-large':
            width = '90%';
            break;
        }
        return width;
      }),
    };
  },
};
</script>
<style lang="sass" scoped>
.modal-component
  z-index: 1000
  :deep(.animation-content)
    width: 100%
  .card
    border-radius: 5px
    width: 100%
    .card-header
      padding: 16px
      color: #FFFFFF
      background-color:  $primary
      .card-header-title
        color: white
        font-weight: 600
        padding: 0
        font-size: $f-sm
      :deep(.icon)
        margin: auto
        &:hover
          cursor: pointer
    .card-content
      max-height: 80vh
      overflow: auto
      padding: 40px 38px
    .card-footer
      display: flex
      justify-content: flex-end
      background-color: $grey-light
      padding: 17px 10px
      :deep(.button)
        margin-left: 10px
        padding: 0px 10px
        font-weight: 400
        height: 30px
        &.is-primary
          order: 1
          min-width: 151px
          border: $color-white-blue solid 1px
          &:hover:not(:disabled), &:active:not(:disabled), &:focus:not(:disabled)
            background-color: $black
</style>

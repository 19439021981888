<template>
  <div ref="iScroll" class="infinite-scroll">
    <slot v-if="oDdata.length" :data="oDdata" />
    <div
      v-if="!paused && ((totalLoaded < total_items && totalLoaded >= perPage) || oLoading)"
      class="loading"
    >
      <o-loading :active="oLoading" :class="oLoading ? 'full-height' : ''" :full-page="false" />
    </div>
  </div>
</template>
<script>
import { ref, toRefs, watch, getCurrentInstance, onMounted, onUnmounted, computed } from 'vue';
export default {
  props: {
    api: { type: String, required: true },
    autoInit: { type: Boolean, default: true },
    currentPage: { type: Number, default: 1 },
    dataPreProcesor: { type: Function, default: null },
    injectThisProps: { type: Object, default: () => {} },
    loading: { type: Boolean, default: () => true },
    params: { type: Array, default: () => [] },
    internalParams: { type: Array, default: () => [] },
    paused: { type: Boolean, default: false },
    perPage: { type: Number, default: 10 },
    modelValue: { type: Array, default: () => [] },
  },
  emits: ['update:model-value', 'update:loading', 'update:current-page', 'update', 'input'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const iScroll = ref(null);
    const { modelValue, loading, params, currentPage } = toRefs(props);
    const oDdata = ref(props.modelValue);
    const oLoading = ref(props.loading);
    const iCurrentPage = ref(props.currentPage);
    const cancelToken = ref(null);
    const scrollTimeout = ref(0);
    const totalLoaded = ref(0);
    const total_items = ref(0);

    const cancelRequest = (message = 'Avoid multiple') => {
      if (cancelToken.value) cancelToken.value.cancel(message);
      if (message != 'Avoid multiple') oLoading.value = false;
    };
    const getTotalItems = async () => {
      const { api, internalParams } = props;
      total_items.value = 0;
      const aParams = [...params.value, ...internalParams].join('&');
      const { data } = await Api.get(`${api}/total-items?${aParams}`, {
        cancelToken: cancelToken.value.token,
      });
      total_items.value = data.total_items;
    };
    const handleScroll = (e) => {
      if (props.paused) return;
      clearTimeout(scrollTimeout.value);
      const { scrollHeight, offsetHeight, scrollTop } = e.target;
      const scroll = scrollTop + offsetHeight;
      scrollTimeout.value = setTimeout(() => {
        if (scroll > scrollHeight - 200) nextPage();
      }, 300);
    };
    const getData = async () => {
      cancelRequest();
      oLoading.value = true;
      let isAborted = false;
      try {
        cancelToken.value = Api.cancelToken;
        if (!total_items.value) await getTotalItems();
        const { data } = await Api.get(`${props.api}${iParams.value}`, {
          cancelToken: cancelToken.value.value,
        });
        totalLoaded.value = totalLoaded.value + data.length;
        let resultData = data;
        if (props.dataPreProcesor) resultData = props.dataPreProcesor(resultData);
        oDdata.value = [...oDdata.value, ...resultData];
        emit('update', { page: iCurrentPage.value, items: oDdata.value });
      } catch (error) {
        isAborted = error.aborted;
      }
      if (!isAborted) oLoading.value = false;
    };
    const nextPage = () => {
      if (!oLoading.value && totalLoaded.value < total_items.value) {
        iCurrentPage.value += 1;
      }
    };
    const reset = () => {
      iCurrentPage.value = 1;
      totalLoaded.value = 0;
      total_items.value = 0;
      oDdata.value = [];
      emit('update', { page: 1, items: [] });
      emit('input', []);
    };

    const iParams = computed(() => {
      const { perPage, internalParams } = props;
      const aParams = [
        `page=${iCurrentPage.value}`,
        `per-page=${perPage}`,
        ...params.value,
        ...internalParams,
      ];
      return `?${aParams.join('&')}`;
    });

    watch(modelValue, (value) => (oDdata.value = value));
    watch(oDdata, (value) => emit('update:model-value', value));
    watch(loading, (value) => (oLoading.value = value));
    watch(oLoading, (value) => emit('update:loading', value));
    watch(currentPage, (value) => (iCurrentPage.value = value));
    watch(iCurrentPage, (value) => emit('update:current-page', value));
    watch(iParams, getData, { immediate: props.autoInit });

    onMounted(() => {
      if (iScroll.value) iScroll.value.addEventListener('scroll', handleScroll);
    });
    onUnmounted(() => {
      if (iScroll.value) iScroll.value.removeEventListener('scroll', handleScroll);
      reset();
    });
    return {
      oDdata,
      iScroll,
      oLoading,
      iCurrentPage,
      iParams,
      total_items,
      totalLoaded,
    };
  },
};
</script>
<style lang="sass" scoped>
.loading
  position: relative
  min-height: 90px
  .o-load
    width: 100%
.full-height
  height: 100%
.loading-sm
  .loading
    height: fit-content
.infinite-scroll
  height: 100%
  overflow: auto
.results
  margin-bottom: 10px
</style>

<template>
  <o-dropdown aria-role="list" class="notifications" position="bottom-left" :close-on-click="false">
    <template #trigger>
      <ModalSignOut v-if="isOpenSignOut" v-model:is-open="isOpenSignOut" />
      <div
        class="notification-btn is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
        :class="hasUnreaded ? 'is-active' : ''"
      >
        <o-icon class="mr-2" :icon="hasUnreaded ? 'bell-ring' : 'bell'" />
        <span>Notificaciones</span>
      </div>
    </template>
    <div>
      <div class="is-flex">
        <span class="n-title">Notificaciones</span>
        <span
          class="mark-read"
          :class="!hasUnreaded ? 'is-disabled' : ''"
          @click="onMarkAllAttended"
          >Marcar como leído
        </span>
      </div>
      <hr />
      <InfiniteScroll v-model="notifications" class="n-container" api="notification" :per-page="20">
        <NotificationViewer
          v-for="notification in notifications"
          :key="notification.id"
          :notification="NewNotificationParsed(notification)"
          :set-notification-attended-function="setNotificationAttended"
          @fire-modal-signout="isOpenSignOut = true"
        />
      </InfiniteScroll>
    </div>
  </o-dropdown>
</template>
<script>
import NotificationViewer from './NotificationViewer.vue';
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import { InfiniteScroll } from '@/components';
import { ModalSignOut } from '@/components';

export default {
  components: {
    NotificationViewer,
    InfiniteScroll,
    ModalSignOut,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const pushNotifications = proxy?.pushNotifications;
    const Api = proxy?.Api;
    const isOpenSignOut = ref(false);
    const notifications = ref([]);
    const loading = ref(false);

    const NewNotificationParsed = (notification) => {
      if (notification.statusId === undefined) {
        notification.statusId = 0;
      }
      if (typeof notification.jsonBody == 'string')
        notification.jsonBody = JSON.parse(notification.jsonBody);
      return notification;
    };
    const hasUnreaded = computed(() => !!notifications.value.filter((n) => !n.is_attended)[0]);
    const setPushNotification = (data) => {
      const notification = NewNotificationParsed(data);
      notification.created_at = new Date();
      notifications.value.unshift(notification);
    };

    const onMessage = (notification) => {
      notification.dispatch = true;
      setPushNotification(notification);
    };
    const init = () => {
      pushNotifications.initialize();
      pushNotifications.onMessage = onMessage;
    };
    const onMarkAllAttended = () => {
      if (!hasUnreaded.value) return;
      const unattended = notifications.value.filter((notification) => !notification.is_attended);
      loading.value = true;
      let promises = [];
      unattended.map(async (notification) => {
        const myPromise = setNotificationAttended(notification.id);
        promises.push(myPromise);
        const result = await myPromise;
        if (result) notification.is_attended = 1;
      });
      Promise.all(promises).finally(() => {
        loading.value = false;
      });
    };
    onMounted(() => {
      init();
    });
    const setNotificationAttended = async (id) => {
      if (!id) return;
      try {
        await Api.patch(`/notification/attended/${id}`);
        return true;
      } catch (error) {
        return;
      }
    };
    return {
      notifications,
      NewNotificationParsed,
      hasUnreaded,
      setNotificationAttended,
      onMarkAllAttended,
      isOpenSignOut,
    };
  },
};
</script>
<style lang="sass" scoped>
.notification-btn
  border: 1px solid $grey-300
  min-height: 50px
  font-weight: 600
  line-height: 21px
  font-size: $f-sm
  border-radius: 0
  margin-right: 1px
  background-color: $grey-300
  color: $black
  padding: 0 16px
  &:hover
    cursor: pointer
  > span
    display: inline-block
    &.icon :deep(.mdi::before)
        font-size: 20px !important
  &.is-active
    background-color: $primary
    color:  $white
    > span.icon :deep(.mdi::before)
      color: $white
.notifications.dropdown
  position: static
.dropdown.is-bottom-left :deep(.dropdown-menu)
  padding: 8px
  right: 185px
  top:115px
  max-height: 263px
  max-width: 330px
  min-width: 330px
  hr
    margin: 8px 0
  .infinite-scroll
    max-height: 206px
  .n-title
    font-weight: 600
    font-size: 14px
  .n-container
    min-height: 200px
    font-size: 14px
  .mark-read
    display: inline-block
    font-weight: 600
    color: $primary
    margin-left: auto
    font-size: 14px
    &:hover
      cursor: pointer
      text-decoration: underline
    &.is-disabled
      color: $grey-text-disable
      &:hover
        cursor: initial
        text-decoration: none
</style>

<template>
  <Modal
    id="userModal"
    v-model:is-open="isModalActive"
    :header="headerTitle"
    :button-label="buttonLabel"
    size="is-large"
    :loading="loading"
    :is-disabled="loading.save"
    :save-disabled="!meta.valid || !isEdited"
    @cancel="resetForm"
    @save="onSubmit"
  >
    <form @submit.prevent="onSubmit">
      <Field
        v-model="full_name"
        v-bind="full_nameAttrs"
        class="w-100"
        label="Nombre usuario"
        placeholder="Nombre usuario"
        maxlength="255"
        text-transform="upper"
        required
      />
      <Field
        v-model="email"
        v-bind="emailAttrs"
        maxlength="255"
        class="w-100"
        label="Correo de usuario"
        placeholder="correo@ejemplo.com"
        :pattern="emailRegex"
        type="email"
        required
      />
      <Select
        v-if="!isClient"
        v-model="service_name"
        v-bind="service_nameAttrs"
        :error-message="errors.service_name"
        api-url="/services"
        :permission="servicesPermissions.ReadAll"
        :data-pre-procesor="PreProcesorServiceName"
        class="w-100"
        label="Área de servicio"
        placeholder="Área de servicio"
        required
        @on-select="onSelect($event, 'Service')"
      />
      <Select
        v-model="user_type_name"
        v-bind="user_type_nameAttrs"
        :error-message="errors.user_type_name"
        :disabled="isClient"
        api-url="/user-types"
        :data-pre-procesor="PreProcesorTypeClient"
        :permission="userTypesPermissions.ReadAll"
        class="w-100"
        :class="isClient ? 'ml-auto' : ''"
        label="Tipo de usuario"
        placeholder="Tipo de usuario"
        required
        @on-select="onSelect($event, 'user')"
      />
      <Switch
        v-if="data.id && !isClient"
        v-model="isUserActive"
        class="mr-auto"
        :label="isUserActive ? 'Activo' : 'Inactivo'"
        horizontal
        required
      />
    </form>
  </Modal>
</template>
<script>
import { Modal, Switch, Field, Select } from '@/components';
import { ref, watch, toRefs, computed, reactive, getCurrentInstance } from 'vue';
import { emailRegex } from '@/utils/RegexValidations';
import useDialog from '@/utils/composables/useDialog';
import { Permissions } from '@/utils/Secure';
import { schemas } from '@/utils/YupSchemas';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { userRoles, serviceName } from '@/config/constants';

export default {
  components: {
    Modal,
    Switch,
    Field,
    Select,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    data: {
      type: Object,
      default: () => ({ email: '', full_name: null, service_name: null, user_type_name: null }),
    },
  },
  emits: ['update:active', 'update:is-open', 'update:data', 'success:save'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { Notify } = useDialog();

    const isModalActive = ref(props.isOpen);
    const { isOpen, data } = toRefs(props);
    const serviceAreaId = ref(undefined);
    const userTypeId = ref(undefined);

    const isUserActive = ref(true);

    //FORM

    const { defineField, isSubmitting, setValues, resetForm, handleSubmit, meta, errors } = useForm(
      {
        validationSchema: yup.object({
          email: schemas.emailSchema,
          full_name: schemas.clientNameSchema,
          user_type_name: schemas.userTypeSchema,
          service_name: schemas.serviceAreaSchema,
        }),
      },
    );

    const loading = reactive({ save: isSubmitting });

    const [full_name, full_nameAttrs] = defineField('full_name');
    const [email, emailAttrs] = defineField('email');
    const [user_type_name, user_type_nameAttrs] = defineField('user_type_name');
    const [service_name, service_nameAttrs] = defineField('service_name');

    const onSubmit = handleSubmit(async (values) => {
      try {
        const userId = data.value.id;
        const sentValues = {
          full_name: values.full_name,
          email: values.email,
          user_type_id: userTypeId.value,
          service_id: serviceAreaId.value,
        };
        if (userId) {
          await Api.put(`/users/${userId}`, sentValues);
          isUserActive.value !== !!data.value.is_active &&
            (await Api.patch(`users/${userId}/${isUserActive.value ? 'enable' : 'disable'}`));
        } else {
          await Api.post(`/users`, sentValues);
        }

        Notify(
          'success',
          userId ? 'Usuario actualizado correctamente' : 'Usuario agregado correctamente',
        );
        emit('success:save');
        resetForm();
        isModalActive.value = false;
      } catch (error) {
        console.log(error);
      }
    });
    const onSelect = (value, selectType) => {
      if (selectType === 'Service') serviceAreaId.value = value.id;
      else if (selectType === 'user') userTypeId.value = value.id;
    };
    const PreProcesorTypeClient = (data) => {
      if (isClient.value) return data.filter((type) => type.name === userRoles.CLIENTE);
      return data.filter((type) => type.name !== userRoles.CLIENTE);
    };
    const PreProcesorServiceName = (data) => {
      return data.filter(
        (type) => type.name === serviceName.CONTABILIDAD || type.name === serviceName.AUDITORIA,
      );
    };
    const isClient = computed(() => data.value.user_type_name === userRoles.CLIENTE);
    const servicesPermissions = computed(() => {
      return Permissions.Services;
    });
    const userTypesPermissions = computed(() => {
      return Permissions.UserTypes;
    });
    watch(data, (value) => {
      setValues({
        email: value.email,
        full_name: value.full_name,
        user_type_name: value.user_type_name,
        service_name: value.service_name,
      });
      serviceAreaId.value = value.service_id;
      userTypeId.value = value.user_type_id;
      isUserActive.value = !!value.is_active;
    });
    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      if (!value) resetForm();
    });
    const isEdited = computed(() => {
      return (
        full_name.value !== data.value.full_name ||
        email.value !== data.value.email ||
        user_type_name.value !== data.value.user_type_name ||
        service_name.value !== data.value.service_name ||
        isUserActive.value !== !!data.value.is_active
      );
    });

    return {
      //computed
      headerTitle: computed(() => (data.value.id ? 'Editar usuario' : 'Agregar usuario')),
      buttonLabel: computed(() => (data.value.id ? 'Guardar cambios' : 'Agregar usuario')),
      isClient,
      servicesPermissions,
      userTypesPermissions,
      isEdited,
      //data
      meta,
      errors,
      isModalActive,
      loading,
      emailRegex,
      isUserActive,
      //methods
      PreProcesorTypeClient,
      PreProcesorServiceName,
      onSelect,
      resetForm,
      onSubmit,
      //form fields
      full_name,
      full_nameAttrs,
      email,
      emailAttrs,
      user_type_name,
      user_type_nameAttrs,
      service_name,
      service_nameAttrs,
    };
  },
};
</script>
<style lang="sass" scoped>
#userModal
  form
    display: grid
    gap: 24px
    grid-template-columns: repeat(2, 1fr)
    .w-100
      margin-bottom: 0
@media (max-width: $bp-md)
  #userModal
    form
      grid-template-columns: 1fr
</style>

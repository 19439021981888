<template>
  <ModalUser v-model:is-open="isModalActive" v-model:data="data" @success:save="reload" />
  <Table
    id="table-users"
    ref="tableUser"
    v-model:loading="isLoading"
    :api-url="apiUrl"
    header="USUARIOS"
    :focusable="false"
    paginated
    :params="params"
  >
    <template #header>
      <Field
        v-model="fieldSearch.value"
        class="search"
        placeholder="Nombre de usuario"
        icon-right="magnify"
        text-transform="upper"
        :disabled="isLoadingDropdown"
      >
        <template #addonsLeft>
          <Dropdown
            v-model:selected="itemSelected"
            grouped
            :loading="isLoadingDropdown"
            label="Campos de búsqueda"
            variant="info"
            :data="dropdownItems"
          />
        </template>
      </Field>
    </template>
    <o-table-column
      v-slot="props"
      sortable
      label="Nombre usuario"
      field="full_name"
      sort-icon-size="large"
    >
      <p>
        {{ props.row.full_name }}
      </p>
    </o-table-column>
    <o-table-column
      v-slot="props"
      sortable
      label="Área de servicio"
      sort-icon-size="large"
      field="service_id"
    >
      <p v-if="props.row.service_name" class="has-text-centered">
        {{ props.row.service_name }}
      </p>
      <p v-else class="has-text-centered">-</p>
    </o-table-column>
    <o-table-column
      v-slot="props"
      sortable
      label="Tipo de usuario"
      sort-icon-size="large"
      field="user_type_id"
    >
      <p class="has-text-centered">
        {{ props.row.user_type_name }}
      </p>
    </o-table-column>
    <o-table-column
      v-slot="props"
      sortable
      label="Estado"
      sort-icon-size="large"
      :td-attrs="() => ({ class: 'has-text-centered' })"
      field="is_active"
    >
      <Tag
        class="tag-state"
        :label="isActive(props.row.is_active)"
        size="large"
        rounded
        :variant="props.row.is_active ? 'success' : 'disable'"
      />
    </o-table-column>
    <o-table-column v-slot="{ row }" label="Acciones" field="actions">
      <div class="user-actions">
        <o-tooltip variant="black" label="Editar">
          <Button
            variant="primary"
            :permission="userPermissions.Update"
            icon-right="pencil"
            @click="() => onEditModal(row)"
          />
        </o-tooltip>
        <o-tooltip
          variant="black"
          :active="row.user_type_name !== 'Cliente'"
          :label="row.is_active ? 'Desactivar' : 'Activar'"
        >
          <Switch
            class="ml-3"
            :disabled="row.user_type_name === 'Cliente'"
            :model-value="row.is_active > 0"
            :permission="userPermissions.Enable"
            @update:model-value="(value) => activeUser(row.id, value, row.is_active)"
          />
        </o-tooltip>
      </div>
    </o-table-column>
  </Table>
</template>
<script>
import { ref, toRefs, watch, getCurrentInstance, onMounted, computed, reactive } from 'vue';
import { Table, Button, Tag, ModalUser, Switch, Field, Dropdown } from '@/components';
import useDialog from '@/utils/composables/useDialog';
import { useComponentUtils } from '@/components/conf/composables';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Button,
    Dropdown,
    Field,
    ModalUser,
    Switch,
    Table,
    Tag,
  },
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ['update:loading'],
  setup(props, { emit }) {
    const tableUser = ref(null);
    const { table } = useComponentUtils();
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const loading = toRefs(props).loading;
    const isLoading = ref(props.loading);
    const isLoadingDropdown = ref(false);
    const apiUrl = ref('users');
    const isModalActive = ref(false);
    const data = ref({});
    const { Notify } = useDialog();
    const fieldSearch = reactive({ oldValue: '', value: '', timeout: null });
    const dropdownItems = ref([
      {
        name: 'Estado',
        items: [
          { name: 'Activo', params: 'is_active=1' },
          { name: 'Inactivo', params: 'is_active=0' },
        ],
      },
    ]);
    const itemSelected = ref(null);

    const reload = async () => {
      if (tableUser.value) {
        await tableUser.value.reload();
      }
    };
    const params = computed(() => {
      let eParams = [];
      if (itemSelected.value && itemSelected.value.params != 'clear')
        eParams.push(itemSelected.value.params);
      if (fieldSearch.oldValue.length) eParams.push(`full_name=${fieldSearch.oldValue}`);
      return eParams;
    });
    const onEditModal = (userData) => {
      data.value = { ...userData };
      isModalActive.value = true;
    };
    const activeUser = async (userId, value, oldValue) => {
      if (value === oldValue > 0) return value;
      isLoading.value = true;
      try {
        await Api.patch(`/users/${userId}/${value ? 'enable' : 'disable'} `);
      } catch (error) {
        Notify('warning', `No se logró ${value ? 'Activar' : 'Desactivar'}`);
        console.log(error);
      } finally {
        await reload();
        isLoading.value = false;
      }
    };

    const userPermissions = computed(() => {
      return Permissions.Users;
    });
    const getDropdownsItems = async () => {
      isLoadingDropdown.value = true;
      try {
        const { data } = await Api.get('/user-types');
        const { data: serviceData } = await Api.get('/services');
        const userTypes = data
          .filter((t) => t.name != 'Cliente')
          .map((type) => ({
            name: type.name,
            params: `user_type_id=${type.id}`,
          }));
        const serviceTypes = serviceData.map((type) => ({
          name: type.name,
          params: `service_id=${type.id}`,
        }));
        dropdownItems.value = [
          ...dropdownItems.value,
          { name: 'Tipo de usuario', items: [...userTypes] },
          { name: 'Área de servicio', items: [...serviceTypes] },
        ];
      } catch (error) {
        console.log(error);
      }
      isLoadingDropdown.value = false;
    };
    watch(loading, (value) => (isLoading.value = value));
    watch(isLoading, (value) => {
      emit('update:loading', value);
    });
    watch(itemSelected, (value, oldValue) => {
      if (value?.name && value?.name != 'Borrar selección' && oldValue == null)
        dropdownItems.value.unshift({
          name: '',
          items: [{ name: 'Borrar selección', params: 'clear' }],
        });
      else if (value?.params == 'clear') {
        dropdownItems.value.shift();
        itemSelected.value = null;
      }
    });
    watch(
      () => fieldSearch.value,
      (value) => {
        clearTimeout(fieldSearch.timeout);
        fieldSearch.timeout = setTimeout(() => {
          fieldSearch.oldValue = value;
        }, 500);
      },
    );
    onMounted(() => {
      if (tableUser.value) table.value = tableUser.value;
      getDropdownsItems();
    });
    return {
      isLoading,
      isActive: (is_active) => {
        return is_active ? 'Activo' : 'Inactivo';
      },
      itemSelected,
      isModalActive,
      data,
      onEditModal,
      apiUrl,
      activeUser,
      tableUser,
      params,
      reload,
      userPermissions,
      table,
      fieldSearch,
      isLoadingDropdown,
      dropdownItems,
    };
  },
};
</script>
<style lang="sass" scoped>
#table-users
    min-height: 500px
    :deep(.table-title)
      .search
        .button
          height: 35px
          &.is-loading
            width: 212px
    :deep(.table)
      thead tr
        th
            padding: 13.4px 0
            &:first-child > span
              margin-left: 24px
              justify-content: flex-start
      tbody tr td
        padding: 6px 3px
        &:first-child
          padding-left: 24px
    .tag-state
      max-height: 24px
      &.is-disable
        background-color: $grey-light
        color: $grey-dark
    @include from($bp-lg)
      .search
        width: 550px
.user-actions
    display: flex
    width: 74px
    align-items: center
    justify-content: space-between
    margin: auto
    :deep(.button)
        border-radius: 3px
        height: 24px
        width: 24px
        padding: 0
        .icon
            margin: 0 !important
            .mdi::before
              font-size: 18px
    :deep(.b-tooltip) .tooltip-content
      font-size: 12px
      padding: 6px
</style>

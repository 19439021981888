<template>
  <Field :label="label">
    <o-timepicker v-model="date" v-bind="{ ...timePickerProps }" @input="onInput" />
  </Field>
</template>
<script>
import { computed, ref, toRefs, watch } from 'vue';
import { usePickProps } from './conf/composables';
import moment from 'moment';
import { Field } from '@/components';
export default {
  components: {
    Field,
  },
  props: {
    modelValue: { type: Date, default: null },
    label: { type: String, default: '' },
  },
  emits: ['update:model-value', 'input'],
  setup(props, { attrs, emit }) {
    const { propsPicked: timePickerProps } = usePickProps('TimePickerProps', { props, attrs });
    const { modelValue } = toRefs(props);
    const date = ref(props.modelValue);

    const dateTimeToString = computed(() => moment(date.value).format('HH:mm:ss'));

    watch(date, (value) => emit('update:model-value', value));
    watch(modelValue, (value) => (date.value = value));
    return {
      date,
      timePickerProps,
      onInput: () => {
        emit('input', dateTimeToString.value);
      },
    };
  },
};
</script>
<style lang="sass" scoped></style>

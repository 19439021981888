<template>
  <div class="panel-client">
    <ModalClient v-model:is-open="isModalCreateClientActive" @success:save="reload" />
    <div class="button-group">
      <Button
        class="large-button"
        variant="primary"
        inverted
        icon-left="briefcase-plus"
        label="Crear cliente"
        :permission="clientsPermissions.Create"
        @click="isModalCreateClientActive = !isModalCreateClientActive"
      />
      <Button
        class="large-button"
        variant="primary"
        inverted
        icon-left="list-box-outline"
        label="Lista de clientes"
        :permission="clientsPermissions.ReadAll"
        @click="() => onChangePage('/lista-clientes')"
      />
    </div>
    <p class="type-module">Clientes</p>
  </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { Button, ModalClient } from '@/components';
import { Permissions } from '@/utils/Secure';
import { useComponentUtils } from '@/components/conf/composables';

export default {
  components: {
    Button,
    ModalClient,
  },
  emits: ['onClosePanel'],
  setup(props, { emit }) {
    const router = useRouter();
    const { table } = useComponentUtils();
    const isModalCreateClientActive = ref(false);

    const clientsPermissions = computed(() => {
      return Permissions.Clients;
    });
    return {
      clientsPermissions,
      router,
      isModalCreateClientActive,
      reload: () => {
        if (table.value) {
          table.value.reload();
        }
      },
      onChangePage: (path) => {
        emit('onClosePanel');
        router.push({ path });
      },
    };
  },
};
</script>
<style lang="sass" scoped>
.panel-client
  .button-group
    :deep(.button)
      margin-right: 24px
  .type-module
    margin-right: 24px
    text-align: center
    margin-top: 8px
    line-height: 21px
    font-weight: 400
    color: $grey-dark
    font-size: $f-sm
</style>

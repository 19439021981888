import AutocompleteProps from './AutocompleteProps';
import ButtonProps from './ButtonProps';
import DatePickerProps from './DatePickerProps';
import DropdownProps from './DropdownProps';
import FieldProps from './FieldProps';
import IconProps from './IconProps';
import InputProps from './InputProps';
import ModalProps from './ModalProps';
import SelectProps from './SelectProps';
import SwitchProps from './SwitchProps';
import TableProps from './TableProps';
import RadioProps from './RadioProps';
import TimePickerProps from './TimePickerProps';
import CheckBoxProps from './CheckBoxProps';

export const ComponentsProps = {
  AutocompleteProps,
  ButtonProps,
  DatePickerProps,
  DropdownProps,
  FieldProps,
  IconProps,
  InputProps,
  ModalProps,
  SelectProps,
  SwitchProps,
  TableProps,
  TimePickerProps,
  RadioProps,
  CheckBoxProps,
};

<template>
  <template v-if="hasPermission">
    <o-field class="switch-custome" :class="classes" v-bind="{ ...fieldProps }">
      <o-switch v-model="isActive" v-bind="{ ...switchProps }" :disabled="disabled || loading"
        >{{ text }}<o-icon v-if="loading" icon="loading" spin />
      </o-switch>
    </o-field>
  </template>
</template>
<script>
import { toRefs, watch, ref, computed } from 'vue';
import { usePickProps } from './conf/composables';
import { PermissionValidator } from '@/utils/Secure';

export default {
  props: {
    modelValue: { type: Boolean || Number, default: null },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    text: { type: String, default: '' },
    permission: { type: Object, default: null },
  },
  emits: ['update:model-value'],
  setup(props, { attrs, emit }) {
    const { propsPicked: switchProps } = usePickProps('SwitchProps', { props, attrs });
    const { propsPicked: fieldProps } = usePickProps('FieldProps', { props, attrs });

    const isActive = ref(props.modelValue);
    const active = toRefs(props).modelValue;

    const classes = computed(() => {
      const classes = [];
      if (props.disabled) classes.push('is-disabled');
      return classes.join('');
    });

    const hasPermission = computed(() => {
      if (!props.permission || props.permission === true) return true;
      return PermissionValidator(props.permission);
    });

    watch(active, (value) => (isActive.value = value));
    watch(isActive, (value) => {
      emit('update:model-value', value);
    });

    return {
      isActive,
      switchProps,
      fieldProps,
      hasPermission,
      classes,
    };
  },
};
</script>
<style lang="sass" scoped>
.switch-custome
  &.is-disabled
    color: red
    :deep(.field-label) .label
      color: $grey-dark
  &.is-horizontal
    width: fit-content
    :deep(.field-label)
      margin-right: 18px
      .label
        font-size: $fh-xs
        font-weight: 400
        margin-bottom: 0
        margin-top: 2px
    :deep(.field-body)
      margin: 0
      .switch
        margin: 0

  :deep(.field-body)
    flex-grow: 0
</style>

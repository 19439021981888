<template>
  <Modal
    id="sign-out-modal"
    v-model:is-open="isModalActive"
    size="is-small"
    header="ATENCIÓN"
    :can-cancel="false"
  >
    <p class="has-text-dark">
      Su cuenta presenta un error. Contacte al administrador para más información
    </p>
    <template #footer>
      <Button
        :loading="loading.signOut"
        class="button-end"
        label="Terminar sesión"
        @click="onSignOut()"
      />
    </template>
  </Modal>
</template>
<script>
import { useAuthStore } from '@/store';
import { Modal, Button } from '@/components';
import { toRefs, ref, watch, getCurrentInstance } from 'vue';
export default {
  components: { Modal, Button },
  props: {
    isOpen: { type: Boolean, default: false },
  },
  emits: ['update:active', 'update:is-open'],
  setup(props, { emit }) {
    const auth = useAuthStore();
    const { isOpen } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const loading = ref({ save: false });
    const { proxy } = getCurrentInstance();
    const pushNotifications = proxy?.pushNotifications;

    const onSignOut = async () => {
      loading.value.signOut = true;
      try {
        await pushNotifications.deleteToken();
        await auth.deAUTH();
      } catch (error) {
        console.log(error);
      }
      loading.value.signOut = false;
    };

    watch(isOpen, (value) => {
      console.log(value);
      isModalActive.value = value;
    });
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      if (!value) onSignOut();
    });
    return { isModalActive, loading, onSignOut };
  },
};
</script>
<style lang="sass" scoped>
#sign-out-modal
    :deep(.modal-content)
        max-width: 443px !important
:deep(.button-end)
    background-color: white !important
    min-height: 30px !important
    width: 134px
</style>

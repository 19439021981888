<template>
  <div id="layout">
    <div class="layaut-header">
      <header class="header w-100">
        <img
          class="img"
          src="../assets/img/GC_logo_H.svg"
          alt="logo header"
          title="Grupo Fiscalista y contadores"
        />
        <h1><span class="is-hidden-mobile">Control despacho contable</span></h1>
        <div class="user-info ml-auto mr-3">
          <p class="is-uppercase">{{ name }}</p>
          <UserConfigMenu :user-role="getRole" />
        </div>
      </header>
      <Toolbar />
    </div>
    <div class="layout-container">
      <template v-if="isAllowed">
        <slot />
      </template>
      <div v-else>SIN PERMISO PARA VER ESTA SECCIÓN</div>
    </div>
  </div>
</template>
<script>
import { Toolbar, UserConfigMenu } from '@/components';
import { PermissionValidator } from '@/utils/Secure';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/store';
import { useComponentUtils } from '@/components/conf/composables';
import { computed } from 'vue';
export default {
  components: {
    Toolbar,
    UserConfigMenu,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { getAUTH } = useAuthStore();
    const isAllowed = computed(() => {
      return PermissionValidator(route.meta?.secure?.permission || null);
    });
    const { formatShortName } = useComponentUtils();
    return {
      isAllowed,
      router,
      getFullName: getAUTH.full_name,
      getRole: getAUTH.role,
      name: formatShortName(getAUTH.full_name),
    };
  },
};
</script>
<style lang="sass" scoped>
#layout
  position: relative
  background-color: $grey-200
  display: flex
  height: 100%
  flex-direction: column
  justify-content: flex-start
  align-items: center
  .layaut-header
    width: 100%
    position: fixed
    z-index: 10
    .header
      display: grid
      grid-template-columns: repeat(3, 1fr)
      background-color: $black
      grid-template-rows: auto
      align-items: center
      padding: 12px 80px
      h1
        text-align: center
        color: $white
        font-weight: 500
        font-size: $fh-xs
      .user-info
        color: $color-white-blue
        :first-child
          font-size: $f-sm
  .layout-container
    width: 100%
    height: calc( 100vh - 112px )
    overflow-y: auto
    margin: 0 auto
    padding: 32px 74px
    margin-top: 112px
  @include until($bp-lg)
    min-height: 425px
    .layaut-header .header
      grid-template-columns: 133px 1fr 1fr
      gap: 6%
      padding: 12px 16px
      h1
        text-align: left
    .layout-container
      height: calc( 100vh - 126px )
      margin-top: 126px
      padding: 32px 16px
</style>

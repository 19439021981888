<template>
  <ModalPagoFactura
    v-if="allowCreateBills"
    v-model:isOpen="isOpen"
    :data="billToEdit"
    :client-id="clientId"
    @succes="getBalance(true)"
  />
  <header class="header-bills">
    <Tag v-if="!isClient" class="tag-name" size="large" variant="bluehues">
      <span>cliente:</span>
      <o-icon v-if="isLoading.client" icon="loading" spin size="small" class="mx-3" />
      <span v-else class="ml-2 has-text-weight-bold">{{ clientData.name }}</span>
    </Tag>
    <div class="container-info is-flex">
      <Tag class="tag-balance" size="large" custom-color="E3E3E3">
        <span>Balance total: </span>
        <o-icon v-if="isLoading.balance" icon="loading" spin size="small" class="mx-3" />
        <span v-else :class="`ml-2 has-text-weight-bold ${tagBalance.color}`">{{
          tagBalance.value
        }}</span>
      </Tag>
      <Field
        v-model="fieldSearch.value"
        class="field-search"
        placeholder="Buscar"
        icon-right="magnify"
        text-transform="upper"
      >
        <template #addonsLeft>
          <Dropdown
            v-model:selected="itemSelected"
            label="Campos de búsqueda"
            variant="info"
            :data="dropdownItems"
          />
        </template>
      </Field>
      <o-tooltip
        :active="Boolean(!clientData.is_active_bills && clientData.length)"
        label="Cliente con facturación deshabilitada"
        variant="black"
      >
        <Button
          v-if="allowCreateBills"
          class="add-button"
          variant="primary"
          icon-left="plus"
          label="Nuevo pago/factura"
          :disabled="Boolean(!clientData.is_active_bills || isLoading.client)"
          @click="isOpen = true"
        />
      </o-tooltip>
    </div>
  </header>
  <Table
    id="table-bills-cliente"
    ref="tableBillsClient"
    v-model:loading="isLoading.data"
    :api-url="apiUrl"
    :focusable="false"
    :params="params"
    paginated
  >
    <o-table-column
      v-slot="{ row }"
      sortable
      width="218"
      label="Tipo de movimiento"
      field="document_type"
      sort-icon-size="large"
      :td-attrs="() => ({ class: 'has-text-centered' })"
    >
      <Tag
        v-if="getDocumentType(row.document_type)"
        class="width-tag has-text-weight-bold"
        :label="getDocumentType(row.document_type).label"
        :variant="getDocumentType(row.document_type).variant"
        rounded
      />
    </o-table-column>
    <o-table-column
      v-slot="{ row }"
      width="109"
      sortable
      label="Folio"
      field="folio"
      sort-icon-size="large"
    >
      <p class="is-uppercase has-text-centered">
        {{ row.folio ?? '-' }}
      </p>
    </o-table-column>
    <o-table-column
      v-slot="{ row }"
      width="666"
      sortable
      label="Descripción"
      field="description"
      sort-icon-size="large"
    >
      <p class="is-uppercase">
        {{ row.description }}
      </p>
    </o-table-column>
    <o-table-column v-slot="{ row }" width="50" label="Monto" field="name">
      <div class="is-flex is-align-items-flex-end is-flex-direction-column">
        <p class="is-uppercase has-text-right mr-1">
          {{ dollarFormater(row.charge ?? row.credit) }}
        </p>
        <Tag v-if="row.is_paid" label="PAGADO" variant="success" rounded />
      </div>
    </o-table-column>
    <!-- <o-table-column
      v-slot="{ row }"
      width="240"
      sortable
      label="Fecha"
      field="due_date"
      sort-icon-size="large"
    >
      <p class="is-uppercase has-text-centered">
        {{ fomatDateDMY(row.due_date) }}
      </p>
    </o-table-column> -->
    <o-table-column v-if="allowEditBills" v-slot="{ row }" width="24">
      <o-tooltip label="Editar" variant="black">
        <Button class="btn-edit" variant="primary" icon-left="pencil" @click="onEditBill(row)" />
      </o-tooltip>
    </o-table-column>
  </Table>
</template>
<script>
import {
  ref,
  toRefs,
  watch,
  onMounted,
  computed,
  getCurrentInstance,
  reactive,
  onBeforeMount,
} from 'vue';
import { Table, Tag, Button, ModalPagoFactura, Field, Dropdown } from '@/components';
import { useComponentUtils } from '@/components/conf/composables';
import { Permissions } from '@/utils/Secure';
import { PermissionValidator } from '@/utils/Secure';
import { useRoute } from 'vue-router';
import { useBills } from '@/utils/composables';
import { useAuthStore } from '@/store';
import { userRoles } from '@/config/constants';

export default {
  components: {
    Button,
    Field,
    Dropdown,
    Table,
    Tag,
    ModalPagoFactura,
  },
  inheritAttrs: false,
  props: {
    clientId: { type: String, default: null },
    loading: { type: Boolean, default: false },
  },
  emits: ['update:loading'],
  setup(props, { emit }) {
    const { DOCUMENT_TYPE, getDocumentType } = useBills();
    const { getAUTH } = useAuthStore();
    //  const documentType = {
    //   I: {
    //     label: 'Factura',
    //     variant: 'primary',
    //   },
    //   P: {
    //     label: 'Pago',
    //     variant: 'warning',
    //   },
    //   E: {
    //     label: 'Credito',
    //     variant: 'info',
    //   },
    // };
    const route = useRoute();
    const isOpen = ref(false);
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const tableBillsClient = ref(null);
    const { table, dollarFormater, fomatDateDMY, isClient } = useComponentUtils();
    const loading = toRefs(props).loading;
    const isLoading = reactive({ data: props.loading, balance: false, client: false });
    const balance = ref(NaN);
    const clientData = ref({});
    const fieldSearch = reactive({ oldValue: '', value: '', timeout: null });
    const dropdownItems = ref([
      { name: 'Folio', param: 'folio' },
      { name: 'Descripción', param: 'description' },
    ]);
    const itemSelected = ref(dropdownItems.value[0]);
    const billToEdit = ref(undefined);
    const reload = async () => {
      if (tableBillsClient.value) {
        await tableBillsClient.value.reload();
      }
    };
    const getBalance = async (reloadTable = false) => {
      isLoading.balance = true;
      isLoading.data = true;
      try {
        const { data } = await Api.get(`clients/${clientUID.value}/balance`);
        balance.value = data.balance;
        if (reloadTable) await reload();
      } catch (error) {
        console.log(error);
      }
      isLoading.data = false;
      isLoading.balance = false;
    };
    const getClient = async () => {
      isLoading.client = true;
      try {
        if (getAUTH.role === userRoles.CLIENTE) {
          const { is_active_bills, full_name } = getAUTH;
          clientData.value = {
            name: full_name,
            is_active_bills,
          };
        } else {
          const { data } = await Api.get(`clients/${clientUID.value}`);
          clientData.value = data;
        }
      } catch (error) {
        console.log(error);
      }
      isLoading.client = false;
    };
    const onEditBill = (bill) => {
      billToEdit.value = bill;
      isOpen.value = true;
    };

    const apiUrl = computed(() => `clients/${clientUID.value}/bills`);
    const tagBalance = computed(() => {
      let color =
        balance.value >= 0 || isNaN(balance.value) ? 'has-text-primary' : 'has-text-danger';
      return {
        color: color,
        value: dollarFormater(balance.value || 0),
      };
    });
    const clientUID = computed(() => route.params?.billsId);
    const params = computed(() => {
      const _params = [];
      if (fieldSearch.oldValue.length)
        _params.push(`${itemSelected.value.param}=${fieldSearch.oldValue}`);
      return _params;
    });
    const allowCreateBills = computed(() => {
      const { Create } = Permissions.ClientsBills;
      return PermissionValidator(Create);
    });
    const allowEditBills = computed(() => {
      const { Update } = Permissions.Bills;
      return PermissionValidator(Update);
    });

    watch(loading, (value) => (isLoading.data = value));
    watch(
      () => isLoading.data,
      (value) => emit('update:loading', value),
    );
    watch(
      () => fieldSearch.value,
      (value) => {
        clearTimeout(fieldSearch.timeout);
        fieldSearch.timeout = setTimeout(() => (fieldSearch.oldValue = value), 500);
      },
    );
    watch(isOpen, (value) => {
      if (!value) billToEdit.value = undefined;
    });

    onBeforeMount(() => {
      getClient();
    });
    onMounted(() => {
      getBalance();
      if (tableBillsClient.value) table.value = tableBillsClient.value;
    });
    return {
      // Data
      billToEdit,
      balance,
      clientData,
      DOCUMENT_TYPE,
      dropdownItems,
      fieldSearch,
      isLoading,
      isOpen,
      itemSelected,
      reload,
      table,
      tableBillsClient,

      // method
      onEditBill,
      dollarFormater,
      fomatDateDMY,
      getBalance,
      getDocumentType,

      // computed
      apiUrl,
      allowEditBills,
      allowCreateBills,
      clientUID,
      tagBalance,
      isClient,
      params,
    };
  },
};
</script>

<style lang="sass" scoped>
.header-bills
  display: flex
  flex-direction: column
  margin-bottom: 32px

  .tag-name
    width: 100%
    margin-bottom: 16px
    white-space: break-spaces
    height: fit-content
    min-height: 37px
    text-align: center
    > span:first-child
      display: none
  .container-info
    flex-wrap: wrap
    justify-content: center
    .tag-balance
      width: 100%
      margin-bottom: 8px
      white-space: break-spaces
      height: fit-content
      min-height: 37px
      text-align: center
      > span:nth-child(2)
        word-break: break-all
      .has-text-primary
        color: $color-green-plus  !important
      .has-text-danger
        color: $color-radical-red !important
    .field-search
      width: 100%
      max-width: 600px
      :deep(.control) .has-addons
        .dropdown .button
          max-height: 37px
        > .control input
            height: 100%
    :deep(.add-button)
      max-height: 37px
      .button-wrapper
        align-items: center
        .icon
          width: fit-content
          margin-right: 7px
          .mdi::before
            font-size: 16px
  @include from($bp-md)
    .tag-name
      margin: 0 auto 16px auto
      width: fit-content
      min-width: 250px
    .container-info
      justify-content: center
      .tag-balance
        width: fit-content
        margin-right: 8px
      .field-search
        order: 1
        margin-top: 16px
  @include from($bp-xl)
    .tag-name
      margin: 0
      margin-right: auto
      margin-bottom: 8px
      width: fit-content
      min-width: none
      text-align: start
      > span:first-child
        display: inline

    .container-info
      align-items: center
      place-content: end
      .tag-balance
        width: fit-content
        margin-bottom: 8px
        margin-right: auto
      .field-search
        margin: 0
        margin-bottom: 8px
        order: 0
      :deep(.add-button)
        margin-left: 8px
        margin-bottom: 8px

  .header-title
    font-weight: 700
    color: $black
    font-size: $fh-md
    line-height: 28px
#table-bills-cliente
  .width-tag
    width: 89px
  :deep(.button).btn-edit
    border-radius: 3px
    height: 24px
    width: 24px
    padding: 0
    .icon
        margin: 0 !important
        .mdi::before
          font-size: 18px
  :deep(.b-table) .table-wrapper
    thead tr
      th
        padding: 13px 24px
        &:nth-child(3) span
          justify-content: left
    // tbody tr td
</style>

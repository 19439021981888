<template>
  <div id="step2-files">
    <h3 class="has-text-weight-semibold is-required-form mb-1">Lista de archivos:</h3>
    <p class="f-xss">
      Introduzca los nombres con los que se solicitarán los diferentes archivos en la etapa.
    </p>
    <h3 class="has-text-weight-semibold f-sm">IMPORTANTE:</h3>
    <p class="f-xss">
      No se permiten nombres de archivo que contengan alguno de los siguiente caracteres:
      <span class="has-text-weight-semibold"> &lt; > : " /\ | ? * . ^</span>
    </p>
    <p class="has-text-right has-text-weight-semibold my-2 w-100 pr-3">¿Requerido?</p>
    <section v-for="(field, idx) in fields" :key="field.key" class="is-flex w-100">
      <FileController
        v-model:fileName="field.value.document_name"
        v-model:extencion="field.value.file_masks"
        v-model:required="field.value.required"
        @update:valid="field.value.isValid = $event"
      />
      <Link
        v-if="!field.isFirst"
        role="button"
        class="remove-file"
        icon-left="minus-circle"
        variant="danger"
        @click="remove(idx)"
      />
      <div v-else class="mx-4"></div>
    </section>
    <Button
      label="Agregar archivo"
      expanded
      icon-left="plus"
      @click="push({ document_name: '', file_masks: [], required: false, isValid: false })"
    />
  </div>
</template>
<script setup>
import FileController from '@/components/FileController.vue';
import useStages from '@/utils/composables/useStages';
import { useForm, useFieldArray } from 'vee-validate';
import { Button, Link } from '@/components';
import { computed, watch, onMounted, ref } from 'vue';

const props = defineProps({
  form: { type: Array, default: () => [] },
  isStage: { type: Boolean, default: false },
  isEditable: { type: Boolean, default: false },
});
const isEdit = ref(props.isEditable);
const emit = defineEmits(['update:form', 'update:ready']);
const editRowFormData = JSON.parse(props.form.documents || '[]');
const { extencionArchivos } = useStages();

const { handleSubmit } = useForm({
  initialValues: {
    files: props.form.length
      ? props.form
      : [{ document_name: '', file_masks: [], required: false, isValid: false }],
  },
});

const { remove, push, replace, fields } = useFieldArray('files');

const onSubmit = handleSubmit(({ files }) => {
  const formatObject = files.map((file) => {
    const { file_masks, required } = file;
    const masks = file_masks.map(({ name }) => name);
    const _file_masks = masks.includes('Todos')
      ? extencionArchivos.filter(({ name }) => name != 'Todos' && name != '.')
      : file_masks.filter(({ name }) => name != '.');

    return {
      file_masks: _file_masks,
      document_name: file.document_name,
      required: required ? required.toString() : 'false',
      isValid: true,
    };
  });
  emit('update:form', formatObject);
});

const isFormValid = computed(() => {
  return fields.value.every(({ value }) => value.isValid);
});
watch(
  isFormValid,
  (value) => {
    emit('update:ready', value);
    if (value) onSubmit();
  },
  { inmediate: true },
);
watch(
  fields,
  () => {
    if (isFormValid.value) onSubmit();
  },
  { deep: true },
);
onMounted(async () => {
  if (isEdit.value) {
    editRowFormData.forEach((item) => {
      if (typeof item.file_masks === 'string') {
        const masksArray = item.file_masks.replace(/[[\]']+/g, '').split(',');
        item.file_masks = masksArray.map((mask) => ({ name: mask.trim() }));
      } else editRowFormData.map((item) => (item.file_masks = eval(item.file_masks)));
    });
    if (editRowFormData.length) replace(editRowFormData);
  }
});
onMounted(() => {
  emit('update:ready', isFormValid.value);
});
</script>
<styles lang="sass" scoped>
#step2-files
  color: $black
  .w-100
    width: 100%
  .file-controller
    flex-grow: 1
  .remove-file
    margin: 0 2px
  .is-required-form
    &::after
      color: $color-radical-red
      content: '*'
</styles>

<template>
  <article class="stage-card" :class="`stage-card__${stagestyle.variant}`">
    <div class="is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap">
      <div class="mb-5">
        <p class="stage-type-title">
          <o-icon custom-size="fh-xs" :icon="stagestyle.icon" :variant="stagestyle.variant" />
          <span class="is-uppercase has-text-weight-semibold"> {{ data.stage_name }}</span>
        </p>
        <Tag v-if="stageStatusDatetime" class="ml-5 mr-3" :label="stageStatusDatetime" />
        <Tag :class="`${stageStatusDatetime ? '' : 'ml-5'}`" size="small" label="Cliente" rounded />
      </div>
      <p class="mb-5">
        <span class="f-xs has-text-weight-semibold">Estado:</span
        ><Tag
          class="slim-tag ml-2"
          :label="data.monthly_run_stage_status_name"
          rounded
          :variant="stagestyle.variant"
        />
      </p>
    </div>
    <div class="is-relative">
      <div class="is-flex">
        <p class="has-text-weight-semibold mb-2 mr-auto">Comentarios:</p>
        <p
          v-if="commentsHandler.needExpand"
          class="has-text-link f-xs link"
          roel="button"
          @click="onExpandComments"
        >
          Ver comentarios
        </p>
      </div>
      <o-loading v-if="loading.details" :active="loading.details" :full-page="false" />
      <p v-else-if="!comments.length" class="empty-coments">Sin comentarios</p>
      <div v-else class="commets-container" :class="commentsHandler.classes">
        <div ref="listCommentsHtml" class="commets-list">
          <p v-for="(comment, index) in comments" :key="`comment-${index}`" class="f-sm">
            {{ index + 1 }}. {{ comment }}
          </p>
        </div>
      </div>
    </div>
    <article
      v-for="(doc, idx) in documents"
      :key="`${idx}-${doc.file_name}`"
      class="document-container"
    >
      <DownloadDocument
        v-if="doc.file_name"
        :api-url="`/monthly-run/${data.monthly_run_id}/monthly-run-details/${data.id}/presigned/download`"
        icon-left="download"
        :file-name="doc.file_name"
      />
    </article>
  </article>
</template>
<script>
import { Tag, DownloadDocument } from '@/components';
import { useComponentUtils } from './conf/composables';
import { computed, toRefs, ref, onMounted, getCurrentInstance, reactive, watch } from 'vue';
import { useContabilidad } from '@/utils/composables';

export default {
  components: { DownloadDocument, Tag },
  props: {
    data: { type: Object, default: () => ({}) },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const listCommentsHtml = ref(null);
    const commentsHandler = reactive({
      needExpand: false,
      isExpanded: false,
      classes: '',
    });
    const { stageHandleStyle } = useContabilidad();
    const { fomatDateDMY } = useComponentUtils();
    const { data } = toRefs(props);
    const stagestyle = ref({});
    const detailsData = ref({});
    const stageStatusDatetime = computed(() => {
      const date = props.data.monthly_run_stage_status_datetime;
      return date ? fomatDateDMY(date) : false;
    });
    const loading = reactive({ details: false });

    const onExpandComments = () => {
      commentsHandler.isExpanded = !commentsHandler.isExpanded;
      if (commentsHandler.isExpanded) {
        commentsHandler.classes = 'commets-container__show';
      } else commentsHandler.classes = '';
    };
    const getDetails = async () => {
      loading.details = true;
      try {
        const { monthly_run_id, id } = props.data;
        const { data: stageDetailsData } = await Api.get(
          `/monthly-run/${monthly_run_id}/monthly-run-details/${id}`,
        );
        detailsData.value = stageDetailsData;
      } catch (error) {
        console.log(error);
      }
      loading.details = false;
    };

    const documents = computed(() => {
      const { documents } = detailsData.value;
      return JSON.parse(documents ?? '[]');
    });
    const comments = computed(() => JSON.parse(detailsData.value?.observations ?? '[]'));

    watch(listCommentsHtml, (value) => {
      if (value?.scrollHeight > 80) commentsHandler.needExpand = true;
    });

    onMounted(() => {
      const { monthly_run_stage_status_id, indicator } = data.value;
      stagestyle.value = stageHandleStyle(monthly_run_stage_status_id, indicator);
      getDetails();
    });
    return {
      loading,
      documents,
      stageStatusDatetime,
      stagestyle,
      detailsData,
      comments,
      listCommentsHtml,
      commentsHandler,
      onExpandComments,
    };
  },
};
</script>
<style lang="sass" scoped>
$stageColorCard: ( primary: ('color': $primary ), link: ('color': $color-blue-plus ), neutral: ('color': $grey-500 ), danger: ('color': $color-radical-red), warning: ('color': $color-orange))
.stage-card
    padding: 32px 40px
    border-radius: 0px 10px 10px 0px
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
    background: $grey-200
    .document-container
      display: flex
      margin-top: 24px
      flex-wrap: wrap
      gap: 10px

    .link
      font-weight: 500
      &:hover
        text-decoration: underline
    .commets-container
      transition: all 200ms ease-in-out
      display: grid
      grid-template-rows: minmax(80px, 0fr)
      &.commets-container__show
        grid-template-rows: minmax(80px, 1fr)
        transition: all 300ms ease-in-out
      .commets-list
        overflow: hidden
    .empty-coments
      color: $grey-dark
      font-weight: 600
      opacity: .7
    @each $className, $object in $stageColorCard
      &.stage-card__#{$className}
        border-left: solid 5px map-get($object, 'color' )
    .slim-tag
        line-height: 21px
        height: fit-content
    .stage-type-title
        margin-bottom: 6px
        > span:not(.icon)
            vertical-align: top
</style>

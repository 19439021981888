//! sort exports alphabetically
export default {
  AccumulatedTime: {
    Create: { method: 'POST', path: '/operation/{id}/accumulated-time' },
    Read: { method: 'GET', path: '/operation/{id}/accumulated-time/{accumulated_time_id}' },
    ReadAll: { method: 'GET', path: '/operation/{id}/accumulated-time' },
    Update: { method: 'PUT', path: '/operation/{id}/accumulated-time/{accumulated_time_id}' },
  },
  Auditing: {
    Create: { method: 'POST', path: '/auditing' },
    ChangeStatus: { method: 'PATCH', path: '/auditing/{id}/change-status' },
    Read: { method: 'GET', path: '/auditing/{id}' },
    ReadAll: { method: 'GET', path: '/auditing' },
    Update: { method: 'PUT', path: '/auditing/{id}' },
    download: { method: 'POST', path: '/auditing/{id}/presigned/download' },
  },
  AuditingTypes: {
    Create: { method: 'POST', path: '/auditing/types' },
    Read: { method: 'GET', path: '/auditing/types/{id}' },
    ReadAll: { method: 'GET', path: '/auditing/types' },
    Update: { method: 'PUT', path: '/auditing/types/{id}' },
    Delete: { method: 'DELETE', path: '/auditing/types/{id}' },
  },
  AuditingConcepts: {
    Create: { method: 'POST', path: '/auditing/{id}/concept' },
    Read: { method: 'GET', path: '/auditing/{id}/concept/{concept_id}' },
    ReadAll: { method: 'GET', path: '/auditing/{id}/concept' },
    Update: { method: 'PUT', path: '/auditing/{id}/concept/{concept_id}' },
    ChangeStatus: { method: 'PATCH', path: '/auditing/{id}/concept/{concept_id}/change-status' },
    Approved: { method: 'PATCH', path: '/auditing/{id}/concept/{concept_id}/approved' },
    Disapproved: { method: 'PATCH', path: '/auditing/{id}/concept/{concept_id}/disapproved' },
    Prepared: { method: 'PATCH', path: '/auditing/{id}/concept/{concept_id}/Prepared' },
    Unprepared: { method: 'PATCH', path: '/auditing/{id}/concept/{concept_id}/Unprepared' },
    AssistantTime: { method: 'PATCH', path: '/auditing/{id}/concept/{concept_id}/assistant-time' },
    ResponsibleTime: {
      method: 'PATCH',
      path: '/auditing/{id}/concept/{concept_id}/responsible-time',
    },
    Conclusion: { method: 'PATCH', path: '/auditing/{id}/concept/{concept_id}/conclusion' },
    Observations: { method: 'PATCH', path: '/auditing/{id}/concept/{concept_id}/observations' },
  },
  Bills: {
    Read: { method: 'GET', path: '/bills/{id}' },
    ReadAll: { method: 'GET', path: '/bills' },
    Update: { method: 'PUT', path: '/bills/{id}' },
    Delete: { method: 'DELETE', path: '/bills/{id}' },
  },
  ClientsBills: {
    Create: { method: 'POST', path: '/clients/{id}/bills' },
    ReadAll: { method: 'GET', path: '/clients/{id}/bills' },
    GeneralBalance: { method: 'GET', path: '/clients/balance' },
    ClientBalance: { method: 'GET', path: '/clients/{id}/balance' },
    enable: { method: 'PATCH', path: '/clients/{id}/enable' },
    disable: { method: 'PATCH', path: '/clients/{id}/disable' },
  },
  ConceptoOperations: {
    Create: { method: 'POST', path: '/concept/{id}/operation' },
    Read: { method: 'GET', path: '/concept/{id}/operation/{operation_id}' },
    ReadAll: { method: 'GET', path: '/concept/{id}/operation' },
    Update: { method: 'PUT', path: '/concept/{id}/operation/{operation_id}' },
    Delete: { method: 'DELETE', path: '/concept/{id}/operation/{operation_id}' },
    Observations: { method: 'PATCH', path: '/concept/{id}/operation/{operation_id}/observations' },
    Recomendations: {
      method: 'PATCH',
      path: '/concept/{id}/operation/{operation_id}/recomendations',
    },
    Conclusions: { method: 'PATCH', path: '/concept/{id}/operation/{operation_id}/conclusions' },
    Filename: { method: 'PATCH', path: '/concept/{id}/operation/{operation_id}/filename' },
    Upload: { method: 'POST', path: '/concept/{id}/operation/{operation_id}/presigned/upload' },
    Download: { method: 'POST', path: '/concept/{id}/operation/{operation_id}/presigned/download' },
    DeleteFileFinal: {
      method: 'DELETE',
      path: '/concept/{id}/operation/{operation_id}/file/final',
    },
    DeleteFilePreliminar: {
      method: 'DELETE',
      path: '/concept/{id}/operation/{operation_id}/file/preliminar',
    },
  },
  conceptTypes: {
    Create: { method: 'POST', path: '/concept-types' },
    Read: { method: 'GET', path: '/concept-types/{id}' },
    Patch: { method: 'PATCH', path: '/concept-types/{id}/color' },
    ReadAll: { method: 'GET', path: '/concept-types' },
    Update: { method: 'PUT', path: '/concept-types/{id}' },
  },
  Clients: {
    Create: { method: 'POST', path: '/clients' },
    Disabled: { method: 'PATCH', path: '/clients/{id}/disabled' },
    Enable: { method: 'PATCH', path: '/clients/{id}/enable' },
    Read: { method: 'GET', path: '/clients/{id}' },
    ReadAll: { method: 'GET', path: '/clients' },
    Update: { method: 'PUT', path: '/clients/{id}' },
  },
  clientsAssociates: {
    Create: { method: 'POST', path: '/users/clients/{customer_id}/associates' },
    Read: { method: 'GET', path: '/clients/{id}/associates/{associate_id}' },
    delete: { method: 'DELETE', path: '/clients/{id}/associates/{associate_id}' },
    ReadAll: { method: 'GET', path: '/clients/{id}/associates' },
  },
  ClientServices: {
    setUser: { method: 'POST', path: '/clients/services/{client_service_id}/users' },
    setAssociate: { method: 'POST', path: '/clients/services/{client_service_id}/associates' },
    Create: { method: 'POST', path: '/clients/{id}/services' },
    Disabled: { method: 'PATCH', path: '/clients/{id}/services/{service_id}/disable' },
    Enable: { method: 'PATCH', path: '/clients/{id}/services/{service_id}/enable' },
    Read: { method: 'GET', path: '/clients/{id}/services/{service_id}' },
    ReadAll: { method: 'GET', path: '/clients/{id}/services' },
    Update: { method: 'PUT', path: '/clients/{id}/services/{service_id}' },
    SetResponsible: { method: 'PATCH', path: '/clients/{id}/services/{service_id}' },
  },
  MeetingStatuses: {
    Read: { method: 'GET', path: '/meeting-statuses/{id}' },
    ReadAll: { method: 'GET', path: '/meeting-statuses' },
  },
  MonthlyRun: {
    ChangeStatus: { method: 'PATCH', path: '/monthly-run/{id}/change-status' },
    Create: { method: 'POST', path: '/monthly-run' },
    Delete: { method: 'DELETE', path: '/monthly-run/{id}' },
    Disabled: { method: 'PATCH', path: '/monthly-run/{id}/disabled' },
    Enable: { method: 'PATCH', path: '/monthly-run/{id}/enable' },
    MonthlyRunDetails: {
      ChangeStatus: {
        method: 'PATCH',
        path: '/monthly-run/{id}/monthly-run-details/{monthly_run_details_id}/change-status',
      },
      Delete: {
        method: 'DELETE',
        path: '/monthly-run/{id}/monthly-run-details/{monthly_run_details_id}',
      },
      Disabled: {
        method: 'PATCH',
        path: '/monthly-run/{id}/monthly-run-details/{monthly_run_details_id}/disabled',
      },
      Enable: {
        method: 'PATCH',
        path: '/monthly-run/{id}/monthly-run-details/{monthly_run_details_id}/enable',
      },
      Read: {
        method: 'GET',
        path: '/monthly-run/{id}/monthly-run-details/{monthly_run_details_id}',
      },
      ReadAll: { method: 'GET', path: '/monthly-run/{id}/monthly-run-details' },
      Update: {
        method: 'PUT',
        path: '/monthly-run/{id}/monthly-run-details/{monthly_run_details_id}',
      },
    },
    Read: { method: 'GET', path: '/monthly-run/{id}' },
    ReadAll: { method: 'GET', path: '/monthly-run' },
    Update: { method: 'PUT', path: '/monthly-run/{id}' },
  },
  Notifications: {
    ReadAll: { method: 'GET', path: '/notification' },
    Read: { method: 'GET', path: '/notification/{id}' },
  },
  PlannedRun: {
    Create: { method: 'POST', path: '/planned-run' },
    Delete: { method: 'DELETE', path: '/planned-run/{id}' },
    Disabled: { method: 'PATCH', path: '/planned-run/{id}/disabled' },
    Enable: { method: 'PATCH', path: '/planned-run/{id}/enable' },
    Read: { method: 'GET', path: '/planned-run/{id}' },
    ReadAll: { method: 'GET', path: '/planned-run' },
    Update: { method: 'PUT', path: '/planned-run/{id}' },
  },
  Services: {
    Create: { method: 'POST', path: '/services' },
    Delete: { method: 'DELETE', path: '/services/{id}' },
    Disabled: { method: 'PATCH', path: '/services/{id}/disabled' },
    Enable: { method: 'PATCH', path: '/services/{id}/enable' },
    Read: { method: 'GET', path: '/services/{id}' },
    ReadAll: { method: 'GET', path: '/services' },
    Update: { method: 'PUT', path: '/services/{id}' },
  },
  Stages: {
    Create: { method: 'POST', path: '/stages' },
    Delete: { method: 'DELETE', path: '/stages/{id}' },
    Disabled: { method: 'PATCH', path: '/stages/{id}/disabled-default' },
    Enable: { method: 'PATCH', path: '/stages/{id}/enable-default' },
    Read: { method: 'GET', path: '/stages/{id}' },
    ReadAll: { method: 'GET', path: '/stages' },
    Update: { method: 'PUT', path: '/stages/{id}' },
  },
  StageTypes: {
    Read: { method: 'GET', path: '/stage-types/{id}' },
    ReadAll: { method: 'GET', path: '/stage-types' },
  },
  TaxRegimes: { ReadAll: { method: 'GET', path: '/users' } },
  Users: {
    Create: { method: 'POST', path: '/users' },
    Disabled: { method: 'PATCH', path: '/users/{id}/disabled' },
    Enable: { method: 'PATCH', path: '/users/{id}/enable' },
    Read: { method: 'GET', path: '/users/{id}' },
    ReadAll: { method: 'GET', path: '/users' },
    Update: { method: 'PUT', path: '/users/{id}' },
  },
  UserTypes: {
    Read: { method: 'GET', path: '/user-types/{id}' },
    ReadAll: { method: 'GET', path: '/user-types' },
    Routes: {
      Create: { method: 'POST', path: '/user-types/{id}/routes' },
      Delete: { method: 'DELETE', path: '/user-types/{id}/routes/{route_id}' },
      Read: { method: 'GET', path: '/user-types/{id}/routes' },
      ReadAll: { method: 'GET', path: '/user-types/retrieve-routes' },
    },
  },
};

<template>
  <div>
    <InitialStep
      v-if="stateFormsController.initialStepForm.isActive"
      v-model:stage="stageData"
      v-model:stageType="stageTypeData"
      v-model:form="initialFormData"
      v-model:isStage="fromStage"
      v-model:isEditable="isEditable"
      :monthly-run-id="monthlyRunId"
      @update:ready="stateFormsController.initialStepForm.isReady = $event"
    />
    <FilesStep
      v-if="stateFormsController.filesForm.isActive"
      v-model:form="filesData"
      v-model:isEditable="isEditable"
      @update:ready="stateFormsController.filesForm.isReady = $event"
    />
    <AditionalData
      v-if="stateFormsController.aditionalDataForm.isActive"
      v-model:form="addionalData"
      v-model:isStage="fromStage"
      v-model:isEditable="isEditable"
      @update:ready="stateFormsController.aditionalDataForm.isReady = $event"
    />
  </div>
</template>
<script>
import { reactive, ref, watch, toRefs, onBeforeMount } from 'vue';
import { InitialStep, FilesStep, AditionalData } from '@/components/Forms';
import useStages from '@/utils/composables/useStages';

export default {
  components: {
    InitialStep,
    FilesStep,
    AditionalData,
  },
  props: {
    monthlyRunId: { type: [String, Number], default: null },
    isEdit: { type: Boolean, default: false },
    isStage: { type: Boolean, default: false },
    rowData: { type: Object, default: () => ({}) },
    stateForms: {
      type: Object,
      default: () => ({
        initialStepForm: {
          type: 'initialStepForm',
          isActive: true,
          isReady: false,
          isInitialStep: true,
          next: 'aditionalDataForm',
        },
        filesForm: {
          type: 'filesForm',
          isActive: false,
          isReady: false,
          next: 'aditionalDataForm',
          prev: 'initialStepForm',
        },
        aditionalDataForm: {
          type: 'aditionalDataForm',
          isActive: false,
          isReady: false,
          isFinalStep: true,
          prev: 'initialStepForm',
        },
      }),
    },
  },
  emits: ['update:stage', 'update:state-forms'],
  setup(props, { emit }) {
    const { STAGETYPES, buildDocumentJson } = useStages({ loadStageTypes: true });
    const { stateForms } = toRefs(props);
    const stateFormsController = ref(props.stateForms);
    const loading = reactive({ save: false, get: false });
    const stageData = ref({});
    const stageTypeData = ref({});
    const initialFormData = ref({});
    const filesData = ref([]);
    const addionalData = ref([]);
    const rowInitialFormData = ref(props.rowData);
    const isEditable = ref(props.isEdit);
    const fromStage = ref(props.isStage);
    const setArrayNewStage = () => {
      const { date, files, filesDate, execution, positivaNegativaFecha, positiveNegative } =
        STAGETYPES;
      const name = initialFormData.value.stageName;
      const responsible_entity = initialFormData.value.responsibleEntitie;
      const { description, main, is_omissible, is_default, visibility_level } = addionalData.value;
      const estimatedTime = addionalData.value.estimated_time
        ? addionalData.value.estimated_time
        : '00:00';
      const timeArray = estimatedTime.split(':');
      const hours = timeArray[0];
      const minutes = timeArray[1];
      const seconds = '00';
      const estimated_time = `${hours}:${minutes}:${seconds}`;
      const stageSelected = initialFormData.value.stageTypeName;
      const stageTypeIds = {
        Ejecución: execution.id,
        'Ejecución por Fecha': date.id,
        Archivos: files.id,
        'Archivos / Fecha': filesDate.id,
        'Positiva / Negativa / Fecha': positivaNegativaFecha.id,
        'Positiva / Negativa': positiveNegative.id,
      };
      const stage_type_id = stageTypeIds[stageSelected];
      const stageType = {
        Ejecución: execution.type,
        'Ejecución por Fecha': date.type,
        Archivos: files.type,
        'Archivos / Fecha': filesDate.type,
        'Positiva / Negativa / Fecha': positivaNegativaFecha.type,
        'Positiva / Negativa': positiveNegative.type,
      };
      const stage_type = stageType[stageSelected];
      const { fieldVerde, fieldAmarillo, fieldRojo } = initialFormData.value;
      const VERDE = fieldVerde ? fieldVerde : '[]';
      const AMARILLO = fieldAmarillo ? fieldAmarillo : '[]';
      const ROJO = fieldRojo ? fieldRojo : '[]';
      const { extencionField, fileName } = initialFormData.value;
      const { type } = stageTypeData.value;
      const isPositiveNegative = [positivaNegativaFecha.type, positiveNegative.type].includes(type);
      let positiveNegativeDocument = [];
      if (fileName) {
        positiveNegativeDocument = [
          {
            document_name: fileName,
            file_masks: extencionField,
            required: initialFormData.value.requiredDoc.toString(),
          },
        ];
      }
      const objSemaphoring = {
        type: stage_type,
        config: {
          ROJO: { days: parseInt(ROJO) },
          VERDE: { days: parseInt(VERDE) },
          AMARILLO: { days: parseInt(AMARILLO) },
        },
      };
      const semaphoring = JSON.stringify(objSemaphoring);
      return {
        name,
        description,
        stage_type_id,
        is_default,
        is_omissible,
        responsible_entity,
        estimated_time,
        visibility_level,
        semaphoring,
        main,
        documents:
          stage_type === 'status' || stage_type === 'date'
            ? '[]'
            : buildDocumentJson(isPositiveNegative ? positiveNegativeDocument : filesData.value),
      };
    };

    const getFormatObject = () => {
      const { positivaNegativaFecha, positiveNegative } = STAGETYPES;
      const { sequenceField, extencionField, fileName } = initialFormData.value;
      const { id: stage_id } = stageData.value;
      const { is_omissible } = addionalData.value;
      const { type } = stageTypeData.value;
      const isPositiveNegative = [positivaNegativaFecha.type, positiveNegative.type].includes(type);
      const positiveNegativeDocument = [
        {
          document_name: fileName,
          file_masks: extencionField,
          required: initialFormData.value.requiredDoc ?? false,
        },
      ];
      return {
        stage_id,
        sequence: sequenceField,
        is_omissible: parseInt(is_omissible),
        documents:
          type === 'status' || type === 'date'
            ? '[]'
            : buildDocumentJson(isPositiveNegative ? positiveNegativeDocument : filesData.value),
      };
    };

    const setFiles = () => {
      if (!isEditable.value) {
        const _documents = JSON.parse(stageData.value.documents ?? '[]');
        filesData.value = _documents.map((file) => {
          let fileMasks = file.file_masks;
          if (typeof file.file_masks === 'string') fileMasks = eval(file.file_masks);

          const formatMask = fileMasks.map((ext) => ({ name: ext.replace('*', '') }));
          return {
            ...file,
            required: file.required === 'true',
            file_masks: formatMask,
            isValid: true,
          };
        });
      }
    };

    watch(stateForms, (value) => (stateFormsController.value = value));
    watch(stateFormsController, (value) => emit('update:state-forms', value));
    watch(
      stageTypeData,
      (value) => {
        if (value.key) {
          const { files, filesDate, positivaNegativaFecha, positiveNegative } = STAGETYPES;
          if ([files.key, filesDate.key].includes(value.key)) {
            stateFormsController.value.initialStepForm.next = 'filesForm';
            stateFormsController.value.aditionalDataForm.prev = 'filesForm';
            setFiles();
          } else {
            stateFormsController.value.initialStepForm.next = 'aditionalDataForm';
            stateFormsController.value.aditionalDataForm.prev = 'initialStepForm';
          }
          if ([positivaNegativaFecha.key, positiveNegative.key].includes(value.key)) setFiles();
        }
      },
      { deep: true },
    );
    watch(stageData, (value) => {
      addionalData.value = {
        is_omissible: value.is_omissible.toString(),
        main: value.main.toString(),
        estimated_time: value.estimated_time,
        visibility_level: value.visibility_level,
        description: value.description,
      };
    });
    onBeforeMount(() => {
      if (isEditable.value) {
        initialFormData.value = rowInitialFormData.value;
        addionalData.value = rowInitialFormData.value;
        filesData.value = rowInitialFormData.value;
      }
    });
    return {
      // data
      addionalData,
      filesData,
      fromStage,
      initialFormData,
      isEditable,
      loading,
      rowInitialFormData,
      stageData,
      stageTypeData,
      stateFormsController,

      // methods
      getFormatObject,
      setArrayNewStage,
    };
  },
};
</script>
<style lang="sass" scoped>
#stageModal
    :deep(.modal-content) > .card .card-content
      height: 70vh
      max-height: 70vh
      overflow-y: auto
</style>

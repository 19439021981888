<template>
  <div :class="classes">
    <o-icon icon="circle" size="23" :variant="customeColor" />
    <p v-if="lastMonth">{{ label }}</p>
    <p v-if="lastMonth" class="mt-0">{{ fiscal_year }}</p>
    <p v-else class="not-info">Sin info</p>
  </div>
</template>
<script>
import { computed, toRefs } from 'vue';
export default {
  props: {
    status: {
      type: String,
      enum: ['done', 'doing', 'toDo', 'stoped', 'needAtention'],
      default: null,
    },
    label: { type: String, default: '' },
    lastMonth: { type: Object, default: null },
  },
  setup(props) {
    const { status } = toRefs(props);
    const { fiscal_year, monthly_run_indicator } = toRefs(props.lastMonth ?? {});
    const customeColor = computed(() => {
      return monthly_run_indicator?.value || null;
    });
    return {
      classes: computed(() => {
        let classes = ['traffic-light'];
        if (status.value) classes.push(`is-${status.value}`);
        return classes.join(' ');
      }),
      customeColor,
      fiscal_year,
    };
  },
};
</script>
<style lang="sass" scoped>
.traffic-light
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    p
        font-weight: 500
        font-size: 16px
        color: #000000
        text-transform: uppercase
        &.not-info
          color:$black
    :deep(.icon)
      color: $grey-500
    .has-text-VERDE
      color: $success-light !important
    .has-text-AMARILLO
      color: $color-bright-sun !important
    .has-text-ROJO
      color: $color-radical-red !important
    @each $className, $object in $monthlyTrafficLight
        &.is-#{$className}
            :deep(.icon) i::before
                color: map-get($object, 'color')
</style>

import Question from './Question.vue';
import ValidateErrors from './library/ValidateErrors.js';

import { ref } from 'vue';
export default {
  components: {
    Question,
  },
  props: {
    sections: { type: Array, default: null },
    isVisualizator: { type: Boolean, default: false },
    showCancelPreview: { type: Boolean, default: false },
    presigned: { type: Object, default: null },
    authorizationToken: { type: String, default: '' },
    endText: { type: String, default: () => 'Finalizar' },
    nextText: { type: String, default: () => 'Siguiente' },
    previousText: { type: String, default: () => 'Anterior' },
  },
  emits: ['endQuestionaire', 'cancelQuestionaire'],
  setup(props, { emit }) {
    const steps = ref([]);
    const activeStep = ref({ index: 1 });
    const mySections = ref(props.sections);

    const handleUpdateQuestion = ({ sectionId, item }) => {
      if (!item?.value) return;
      const currentSection = mySections.value.find((s) => s.id == sectionId);
      let currentQuestion = currentSection.questions.find((q) => q.id == item.id);
      if (currentQuestion.value === item.value && currentQuestion.updated) return;
      currentSection.questions.map((q) => {
        if (q.id == item.id) q = { updated: true, ...item };
      });
    };
    const validate_required = (question) => {
      const { validate, error, type } = question;
      let { value } = question;
      if (type == 'date') value = value.date || value.hour;
      if (type == 'checkbox') value = Boolean(value.length);
      if (type == 'map') value = value.address;
      if (validate.required && !value && !error) {
        question.error = ValidateErrors.required;
      }
      return question.error ? false : true;
    };
    const validate_maxLength = (question) => {
      const { value, validate, error } = question;
      const { maxlength } = validate;
      if (!maxlength || !value) return true;
      if (value.length > maxlength && !error)
        question.error = ValidateErrors.length.replace('{{value}}', maxlength);
      return question.error ? false : true;
    };
    const validate_regex = (regex, string) => {
      let str_RegExp;
      switch (regex) {
        case 'email':
          str_RegExp = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
          break;
        default:
          str_RegExp = regex;
          break;
      }
      const myRegex = new RegExp(str_RegExp);
      return myRegex.exec(string);
    };
    const validate_email = (question) => {
      const { value, error } = question;
      if (!validate_regex('email', value) && !error) question.error = ValidateErrors.email;
      return question.error ? false : true;
    };
    const validate_pattern = (question) => {
      const { value, validate, error } = question;
      const { pattern, required } = validate;
      if (!pattern || (!value && !required)) return true;
      if (!validate_regex(pattern, value) && !error)
        question.error = ValidateErrors.pattern.replace('{{value}}', pattern);
      return question.error ? false : true;
    };
    const validate_ranges = (question) => {
      const { value, validate } = question;
      if (validate.maxNumber && value) if (value > validate.maxNumber) return false;
      if (validate.minNumber && value) if (value < validate.minNumber) return false;
      return true;
    };
    const validate_questions = () => {
      const index = activeStep.value.index;
      const step = mySections.value[index - 1];
      const { questions } = step;
      let valid = true;
      questions.map((question) => {
        let validations = [];
        if (question.error === undefined) question.error = '';
        validations.push(validate_required(question));
        validations.push(validate_maxLength(question));
        if (question.validate.pattern) validations.push(validate_pattern(question));
        if (question.inputType === 'email') validations.push(validate_email(question));
        if (question.type === 'number') validations.push(validate_ranges(question));
        if (valid) valid = validations.every((v) => v);
      });
      return valid;
    };
    const validate_step = () => {
      const result = validate_questions();
      // const index = this.activeStep.index;
      // const step = this.mySections[index];
      // this.activeStep = { index, ...step };
      return result;
    };
    const onStepChanged = () => {};
    const previousStep = () => {
      activeStep.value.index--;
    };
    const nextStep = () => {
      // const valid = true;
      const valid = validate_step();
      if (valid) activeStep.value.index++;
    };
    const endQuestionaire = () => {
      let valid = true;
      if (!props.isVisualizator) {
        valid = validate_step();
      }
      if (valid) emit('endQuestionaire');
    };
    const cancelQuestionaire = () => {
      emit('cancelQuestionaire');
    };
    const filterById = (array, id) => {
      return array.filter((item) => {
        if (item.id == id) return true;
      });
    };
    const getValidationFromTypeQuestion = (question, expected_value) => {
      if (question.value) {
        if (typeof question.value === 'object') {
          return Boolean(question.value.val == expected_value);
        } else if (Array.isArray(question).value == Array)
          return Boolean(expected_value in question.value);
        else return Boolean(question.value == expected_value);
      }
    };
    const handlerConditionalHideQuestion = (question) => {
      if (question.conditional) {
        if (question.conditional.answer) {
          const { section, question_id, validation_type, expected_value } =
            question.conditional.answer;
          const _section = filterById(mySections.value, section);
          const conditional_question = filterById(_section[0].questions, question_id);
          const validation = getValidationFromTypeQuestion(conditional_question[0], expected_value);
          if (validation_type) return validation;
          else if (!validation_type) return !validation;
          return false;
        }
      }
      return true;
    };
    return {
      steps,
      activeStep,
      mySections,
      // Metodos
      handleUpdateQuestion,
      validate_required,
      validate_maxLength,
      validate_email,
      validate_pattern,
      validate_ranges,
      validate_questions,
      validate_step,
      onStepChanged,
      previousStep,
      nextStep,
      endQuestionaire,
      cancelQuestionaire,
      filterById,
      getValidationFromTypeQuestion,
      handlerConditionalHideQuestion,
    };
  },
};

<template>
  <div class="radio-container">
    <Field :label="fieldLabel">
      <o-radio
        v-model="radio"
        :disabled="disabled"
        :native-value="oNativeValue"
        v-bind="{ ...radioProps }"
      >
        {{ label }}
      </o-radio>
    </Field>
    <div v-if="$slots.moreinfo" class="radio-more-info">
      <slot name="moreinfo" />
    </div>
    <div v-if="$slots.default && radio === nativeValue" class="radio-content">
      <slot name="default" />
    </div>
  </div>
</template>
<script>
import { Field } from '@/components';
import { usePickProps } from './conf/composables';
import { ref, toRefs, watch } from 'vue';
export default {
  components: {
    Field,
  },
  props: {
    disabled: { type: Boolean, default: false },
    fieldLabel: { type: String, default: '' },
    label: { type: String, default: '' },
    modelValue: { type: String || Number || Boolean || Array, default: '' },
    nativeValue: { type: String || Number || Boolean || Array, default: '' },
  },
  emits: ['update:model-value', 'update:native-value'],
  setup(props, { attrs, emit }) {
    const { propsPicked: radioProps } = usePickProps('RadioProps', { props, attrs });
    const { modelValue, nativeValue } = toRefs(props);
    const radio = ref(props.modelValue);
    const oNativeValue = ref(props.nativeValue);

    watch(modelValue, (value) => (radio.value = value));
    watch(radio, (value) => {
      emit('update:model-value', value);
    });
    watch(nativeValue, (value) => (oNativeValue.value = value));
    watch(oNativeValue, (value) => {
      emit('update:native-value', value);
    });
    return {
      radio,
      radioProps,
      oNativeValue,
    };
  },
};
</script>
<style lang="sass" scoped>
.radio-container
    .field
        line-height: 1
        margin: 0
    .radio-content, .radio-more-info
        margin-left: 30px
    :deep(.radio)
      .check
        border-color: $grey-dark
      & > .check:checked ~ .control-label
        color: $black
</style>

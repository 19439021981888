const COOKIE_ID = process.env.VUE_APP_COOKIE_ID || null;

export const setLocalStorage = (name, value) => {
  localStorage.setItem(`${COOKIE_ID}_${name}`, value);
};

export const getLocalStorage = (name) => {
  return localStorage.getItem(`${COOKIE_ID}_${name}`);
};

export const removeLocalStorage = (name) => {
  localStorage.removeItem(`${COOKIE_ID}_${name}`);
};

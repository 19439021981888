<!-- eslint-disable -->
<template>
  <div>
    <div v-if="!isPreview">
      <section v-if="title.show" :class="title.cssClass">
        <h2 class="title has-text-centered">{{ survey.survey.name }}</h2>
      </section>
      <section>
        <slot name="header" />
      </section>
      <steps
        :sections="survey.survey.sections"
        :isVisualizator="isVisualizator"
        :showCancelPreview="showCancelPreview"
        :presigned="presigned"
        :authorizationToken="authorizationToken"
        :endText="endText"
        :nextText="nextText"
        :previousText="previousText"
        @endQuestionaire="endQuestionaire"
        @cancelQuestionaire="cancelQuestionaire"
      ></steps>
      <section>
        <slot name="footer" />
      </section>
    </div>
    <span v-if="isPreview">
      <question :question="survey.survey" :isPreview="true" :index="0"></question>
    </span>
  </div>
</template>

<script>
import Steps from './Steps.vue';
import Question from './Question';

export default {
  components: { Steps, Question },
  props: {
    survey: {
      type: Object,
      required: true,
    },
    title: {
      type: Object,
      required: false,
      default: () => {
        return { cssClass: '', show: true };
      },
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    isVisualizator: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    showCancelPreview: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    presigned: {
      type: Object,
      required: false,
      default: () => {},
      // default: () => {
      //   return {
      //     resourceKey: `resources/${2}`,
      //     pathPresigned: 'https://gestorapi.hmhsistemas.com.mx/presigned_url',
      //     content_type: 'application/vnd.ms-excel',
      //     method: 'POST',
      //     headers: {
      //       Authorization: new Date().toDateString(),
      //     },
      //   };
      // },
    },
    authorizationToken: {
      type: String,
      default: () => {
        return new Date().toDateString();
      },
    },
    endText: {
      type: String,
      default: () => {
        return 'Finalizar';
      },
    },
    nextText: {
      type: String,
      default: () => {
        return 'Siguiente';
      },
    },
    previousText: {
      type: String,
      default: () => {
        return 'Anterior';
      },
    },
  },
  emits: ['endQuestionaire', 'cancelQuestionaire'],
  setup(props, { emit }) {
    return {
      endQuestionaire: () => {
        emit('endQuestionaire', props.survey);
        console.log('Emitido: endQuestionaire');
        console.log(JSON.stringify(props.survey));
      },
      cancelQuestionaire: () => {
        emit('cancelQuestionaire', props.survey);
        console.log('Emitido: cancelQuestionaire');
      },
    };
  },
};
</script>

<style lang="sass" src="./library/style.sass"></style>

<template>
  <template v-if="hasPermission">
    <o-skeleton v-if="skeletonLoading" width="80px" height="40px" animated />
    <o-button
      v-else
      :disabled="isDisabled || loading"
      v-bind="{ ...buttonProps }"
      :icon-right="!loading ? buttonProps['icon-right'] : undefined"
      :icon-left="!loading ? buttonProps['icon-left'] : undefined"
      @click.stop="onClick"
    >
      <o-loading v-if="loading" :active="loading" :full-page="false" />
      <slot v-else-if="$slots.default" />
      <template v-else>
        {{ label }}
      </template>
    </o-button>
  </template>
</template>
<script>
import { PermissionValidator } from '@/utils/Secure';
import { usePickProps } from './conf/composables';
import { computed, toRefs, ref, watch } from 'vue';

export default {
  inheritAttrs: false,
  props: {
    label: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    permission: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
    skeletonLoading: { type: Boolean, default: null },
  },
  emits: ['click'],
  setup(props, { attrs, emit }) {
    const { propsPicked: buttonProps } = usePickProps('ButtonProps', { props, attrs });
    const isDisabled = ref(props.disabled);
    const disabled = toRefs(props).disabled;

    watch(disabled, (value) => (isDisabled.value = value));
    const hasPermission = computed(() => {
      if (!props.permission || props.permission === true) return true;
      return PermissionValidator(props.permission);
    });

    const onClick = (e) => {
      emit('click', e);
    };

    return {
      hasPermission,
      buttonProps,
      isDisabled,
      onClick,
    };
  },
};
</script>
<style lang="sass" scoped>
.b-skeleton
  width: auto
:deep(.o-load).loading
  .o-load__overlay
    background: unset
  .icon .mdi::before
    font-size: 24px !important
</style>

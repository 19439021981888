export default [
  // 'disabled',
  'expanded',
  'iconBoth',
  'icon-both',
  'iconLeft',
  'icon-left',
  'iconPack',
  'icon-pack',
  'iconRight',
  'icon-right',
  'inverted',
  'nativeType',
  'native-type',
  'outlined',
  'override',
  'rounded',
  'size',
  'tag',
  'variant',
  'class',
  'tabindex',
];

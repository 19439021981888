<template>
  <o-dropdown
    v-model="oSelected"
    :disabled="disabled || loading"
    :class="classes"
    aria-role="list"
    :position="position"
  >
    <template #trigger="{ active }">
      <o-button
        :icon-left="iconLeft"
        :variant="variant"
        :size="btnSize"
        :class="loading ? 'is-loading' : ''"
      >
        <o-icon v-if="loading" icon="loading" spin />
        <template v-else>
          <span class="d-label"> {{ labelSelected }}</span>
          <o-icon v-if="!hideMenuIcon" :icon="active ? 'menu-up' : 'menu-down'"></o-icon>
        </template>
      </o-button>
    </template>
    <slot v-if="$slots.default" />
    <template v-if="!grouped">
      <o-dropdown-item
        v-for="(item, index) in data"
        :key="`${index}-${item[model]}`"
        :value="item"
        aria-role="listitem"
        >{{ item[model] }}</o-dropdown-item
      >
    </template>
    <template v-else-if="grouped">
      <template v-for="({ name, items }, index) in data" :key="`${index}-${name}`">
        <p v-if="name.length" class="drop-title-group">{{ name }}</p>
        <o-dropdown-item
          v-for="item in items"
          :key="`${index}-${item[name]}`"
          :value="item"
          aria-role="listitem"
          >{{ item[model] }}</o-dropdown-item
        >
      </template>
    </template>
  </o-dropdown>
</template>
<script>
import { computed, ref, toRefs, watch } from 'vue';

export default {
  props: {
    hideMenuIcon: { type: Boolean, default: false },
    btnSize: { type: String, enum: ['small', 'medium', 'large'], default: 'medium' },
    class: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
    grouped: { type: Boolean, default: false },
    label: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    model: { type: String, default: 'name' },
    modelSelected: { type: String, default: '' },
    position: { type: String, default: null },
    selected: { type: Object, default: null },
    iconLeft: { type: String, default: undefined },
    variant: { type: String, enum: ['primary', 'info', 'warning', 'danger'], default: 'primary' },
  },
  emits: ['update:selected'],
  setup(props, { emit }) {
    const { selected } = toRefs(props);
    const oSelected = ref(props.selected);

    watch(selected, (value) => (oSelected.value = value));
    watch(oSelected, (value) => emit('update:selected', value));
    return {
      oSelected,
      labelSelected: computed(() => {
        const { label, model, modelSelected } = props;
        if (!oSelected.value) return label;
        return modelSelected.length ? oSelected.value[modelSelected] : oSelected.value[model];
      }),
      classes: computed(() => {
        let classes = ['o-dropdown'],
          _class = props.class.split(' ');
        classes.push(`is-${props.variant}`);
        if (_class.length) classes = [...classes, ..._class];
        return classes.join(' ');
      }),
    };
  },
};
</script>
<style lang="sass" scoped>
.o-dropdown
    .drop-title-group
      font-size: $f-xs
      font-weight: 600
      text-align: center
      padding: 0px 16px
      color: $black
      padding-top: 12px
    .dropdown-trigger
        .button
            padding: 4px 14px
            :deep(.button-wrapper)
              .icon
                vertical-align: middle
                .mdi
                  line-height: 24px
            .d-label
                font-size: $f-sm
    @each $className, $object in $bgcolors
        &.is-#{$className} .dropdown-content .dropdown-item.is-active
            background: map-get($object, 'background' )
</style>

const useBills = () => {
  const DOCUMENT_TYPE = {
    FACTURA: {
      key: 'I',
      label: 'Factura',
      variant: 'primary',
    },
    PAGO: {
      key: 'P',
      label: 'Pago',
      variant: 'warning',
    },
    CREDITO: {
      key: 'E',
      label: 'Credito',
      variant: 'info',
    },
  };
  const getDocumentType = (key) => {
    for (const document in DOCUMENT_TYPE) {
      if (DOCUMENT_TYPE[document].key === key) return DOCUMENT_TYPE[document];
    }
    return null;
  };
  return {
    DOCUMENT_TYPE,

    // methods
    getDocumentType,
  };
};
export default useBills;

<template>
  <Modal
    id="modal-upload-documents"
    v-model:is-open="isModalActive"
    size="is-large"
    header="Editar documentos"
    :can-cancel="false"
  >
    <h3 class="has-text-dark has-text-weight-semibold mb-3">Lista de archivos:</h3>
    <section class="files">
      <div
        v-for="(document, key) in documnenstMonthlyDetails"
        :key="`document-upload-${key}`"
        class="file-content"
      >
        <o-tooltip
          :active="document.document_name.length >= 50"
          :label="document.document_name"
          variant="black"
        >
          <p :class="document.required ? 'is-required' : ''">
            {{
              document.file_name
                ? formatName(document.file_name)
                : addExtensionToFileName(formatName(document.document_name), document.file_masks)
            }}
          </p>
        </o-tooltip>
        <div v-if="document.file_name" class="is-flex is-align-items-center">
          <DownloadDocument
            :disabled="document.loading"
            icon-left="download"
            class="mr-auto"
            :api-url="apiUrl.download"
            :file-name="document.file_name"
            :label="`Descargar (${'.' + document.extencion})`"
          />
          <Button
            class="tiny-button ml-3"
            variant="danger"
            icon-right="close"
            :loading="document.loading"
            @click="handleDelete(document)"
          />
        </div>
        <FilePicker
          v-else
          :title="`Subir archivo (${docMasks(document.file_masks)}) `"
          :api-url="apiUrl.upload"
          :set-file-name-by-patch="apiUrl.filename"
          :document-name="document.document_name"
          :accept="document.file_masks"
          @success="onSuccess($event, document.document_name)"
        />
      </div>
    </section>
    <template #footer>
      <Button
        label="Cerrar"
        variant="info"
        inverted
        :disabled="loading.save"
        @click="isModalActive = false"
      />
    </template>
  </Modal>
</template>
<script>
import { Modal, Button, DownloadDocument, FilePicker } from '@/components';
import { toRefs, ref, watch, onMounted, computed, getCurrentInstance, reactive } from 'vue';
import { useStages } from '@/utils/composables';
export default {
  components: { Modal, Button, DownloadDocument, FilePicker },
  props: {
    isOpen: { type: Boolean, default: false },
    documents: { type: [Array, Object], default: () => [] },
    apiUploadDocumentsId: {
      type: Object,
      default: () => ({
        monthlyId: null,
        monthlyDetailsId: null,
      }),
    },
  },
  emits: ['update:active', 'update:is-open', 'success'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { isOpen } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const loading = reactive({ save: false });
    const documnenstMonthlyDetails = ref(props.documents);
    const { formatName, addExtensionToFileName } = useStages();

    const setDocuments = () => {
      const { documents } = props;
      let _documents = documents.map((doc) => {
        let fileMasks = doc.file_masks;
        if (typeof doc.file_masks === 'string') fileMasks = eval(doc.file_masks);
        if (doc.file_name) doc.extencion = doc.file_name.split('.')[1];
        return {
          ...doc,
          required: doc.required,
          file_masks: fileMasks.map((ext) => ext.replace('*', '')).join(', '),
          loading: false,
        };
      });
      documnenstMonthlyDetails.value = _documents;
    };
    const onSuccess = (fileName, documentName) => {
      const idx = documnenstMonthlyDetails.value.findIndex(
        (doc) => doc.document_name === documentName,
      );
      documnenstMonthlyDetails.value[idx].extencion = fileName.split('.')[1];
      documnenstMonthlyDetails.value[idx].file_name = fileName;
    };
    const setLoadingDocument = (documentName, isLoading) => {
      const idx = documnenstMonthlyDetails.value.findIndex(
        ({ document_name }) => document_name == documentName,
      );
      documnenstMonthlyDetails.value[idx].loading = isLoading;
    };
    const removeFileNameDocument = (fileName) => {
      documnenstMonthlyDetails.value.forEach((document) => {
        const { file_name } = document;
        if (file_name && file_name === fileName) {
          delete document.file_name;
          delete document.extencion;
        }
      });
    };
    const handleDelete = async ({ file_name, document_name }) => {
      setLoadingDocument(document_name, true);
      loading.save = true;
      try {
        await Api.delete(apiUrl.value.delete, {
          file_name,
          document_name,
        });
        removeFileNameDocument(file_name);
      } catch (error) {
        console.log(error);
      }
      setLoadingDocument(document_name, false);
      loading.save = false;
    };

    const apiUrl = computed(() => {
      const {
        apiUploadDocumentsId: { monthlyId, monthlyDetailsId },
      } = props;
      const baseUrl = `/monthly-run/${monthlyId}/monthly-run-details/${monthlyDetailsId}/`;
      return {
        upload: `${baseUrl}presigned/upload`,
        download: `${baseUrl}presigned/download`,
        filename: `${baseUrl}filename`,
        delete: `${baseUrl}documents`,
      };
    });
    const docMasks = (docMasks) => {
      const totalNumberMask = 6;
      if (docMasks.split(',').length === totalNumberMask) return '.*';
      return docMasks;
    };

    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      if (!value) emit('success', props.apiUploadDocumentsId.monthlyId);
    });
    onMounted(setDocuments);
    return {
      // data
      documnenstMonthlyDetails,
      isModalActive,
      loading,

      // methods
      formatName,
      handleDelete,
      onSuccess,
      docMasks,
      addExtensionToFileName,

      // computed
      apiUrl,
    };
  },
};
</script>
<style lang="sass" scoped>
#modal-upload-documents
  :deep(.modal-content)
    overflow: visible
    max-width: 1000px !important
    .card .card-content
      overflow: unset
      overflow-y: visible !important
  .files
    .file-content
      margin-bottom: 10px
      display: flex
      justify-content: space-between
      color: $black
      :deep(.b-tooltip)
        align-items: center
        .tooltip-content
          width: 700px
          white-space: normal
          text-align: justify
      p.is-required::after
       color: $color-radical-red
       content: '*'
      :deep(.field).file
        width: fit-content
        .button
          padding: 0 6px
          height: 30px
          span
            font-weight: 400 !important
          &.is-primary
            min-width: 30px
      :deep(.tiny-button)
        min-width: 16px
        min-height: 16px
        width: 16px
        height: 16px
        padding: 0
        .icon
          .mdi-loading::before
            font-size: 10px !important
:deep(.icon)
  margin: 0 !important
  .mdi::before
    font-size: 14px
  .mdi-loading::before
    font-size: 18px !important
</style>

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      isPublic: true,
      title: 'login',
      secure: { permission: null, redirect: null },
    },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Public/Login'),
  },
  {
    path: '/restablecer-contrasena',
    name: 'registerPassword',
    meta: {
      isPublic: true,
      title: 'Restablecer contraseña',
      secure: { permission: null, redirect: null },
    },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Public/Login'),
  },
];

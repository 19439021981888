export default [
  'animation',
  'appendToBody',
  'append-to-body',
  'appendToBodyCopyParent',
  'append-to-body-copy-parent',
  'ariaRole',
  'aria-role',
  'canClose',
  'can-close',
  'closeOnClick',
  'close-on-click',
  'disabled',
  'expanded',
  'inline',
  'maxHeight',
  'max-height',
  'menuTag',
  'menu-tag',
  'mobileBreakpoint',
  'mobile-breakpoint',
  'mobileModal',
  'mobile-modal',
  'multiple',
  'override',
  'position',
  'scrollable',
  'trapFocus',
  'trap-focus',
  'triggerTabindex',
  'trigger-tabindex',
  'triggers',
];

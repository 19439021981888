<template>
  <o-dropdown id="date-filter" aria-role="list" v-bind="{ ...dropdownProps }" :mobile-modal="false">
    <template #trigger="{ active }">
      <o-button
        class="button-trigger"
        :inverted="!(rangeDates.initialDate && rangeDates.endDate) && !active"
        icon-left="calendar-blank"
        variant="primary"
        expanded
      >
        <span>{{ label }}</span>
      </o-button>
    </template>
    <div class="dropdown-conteiner">
      <div class="pickers-date-container">
        <DatePicker
          v-model="rangeDates.initialDate"
          :placeholder="placeholder"
          :type="type"
          :max-date="minMax.maxDate"
          class="w-50"
          label="Inicio:"
          @click="() => onSelect(optionsDates.custome)"
        />
        <DatePicker
          v-model="rangeDates.endDate"
          :placeholder="placeholder"
          :type="type"
          :min-date="minMax.minDate"
          class="w-50"
          label="Fin:"
          @click="() => onSelect(optionsDates.custome)"
        />
      </div>
      <Button
        class="btn-options"
        :class="selected === 'today' ? 'is-selected' : ''"
        :label="optionsDates.today.label"
        expanded
        @click="() => onSelect(optionsDates.today)"
      />
      <Button
        class="btn-options"
        :class="'sixMonthsAgo' === selected ? 'is-selected' : ''"
        label="Hace 6 meses"
        expanded
        @click="() => onSelect(optionsDates.sixMonthsAgo)"
      />
      <Button
        class="btn-options"
        :class="selected === 'annual' ? 'is-selected' : ''"
        label="Anual"
        expanded
        @click="() => onSelect(optionsDates.annual)"
      />
    </div>
  </o-dropdown>
</template>
<script>
import { usePickProps } from './conf/composables';
import { DatePicker, Button } from '@/components';
import { reactive, ref, watch, onMounted } from 'vue';
import moment from 'moment';
export default {
  components: {
    DatePicker,
    Button,
  },
  props: {
    type: { type: String, default: undefined },
    placeholder: { type: String, default: 'MES' },
    modelValue: { type: Object, default: () => ({ initialDate: undefined, endDate: undefined }) },
    defaultFilter: {
      type: String,
      enum: ['custome', 'today', 'sixMonthsAgo', 'annual'],
      default: 'today',
    },
  },
  emits: ['update:model-value'],
  setup(props, { attrs, emit }) {
    const { propsPicked: dropdownProps } = usePickProps('DropdownProps', { props, attrs });
    const rangeDates = reactive({ ...props.modelvalue });
    const optionsDates = reactive({
      custome: { type: 'custome', label: 'Filtrar por fecha' },
      today: { type: 'today', label: props.type === 'month' ? 'Mes actual' : 'Hoy' },
      sixMonthsAgo: { type: 'sixMonthsAgo', label: 'Hace 6 meses' },
      annual: { type: 'annual', label: 'Anual' },
    });
    const minMax = reactive({ minDate: undefined, maxDate: undefined });
    const label = ref('Filtrar por fecha');
    const selected = ref('');
    const dateFormater = (_date) => {
      let format = moment(_date).format('DD/MM/YYYY');
      if (props.type === 'month') format = moment(_date).format('MMMM YY').toUpperCase();
      return format;
    };
    const changeDateFilter = (type) => {
      const { custome, today, sixMonthsAgo, annual } = optionsDates;
      switch (type) {
        case today.type:
          rangeDates.initialDate = moment().startOf('day').subtract(1, 'month').toDate();
          rangeDates.endDate = moment().endOf('day').subtract(1, 'month').toDate();
          break;
        case sixMonthsAgo.type:
          rangeDates.initialDate = moment().subtract(6, 'months').startOf('day').toDate();
          rangeDates.endDate = moment().endOf('day').toDate();
          break;
        case annual.type:
          rangeDates.initialDate = moment().subtract(1, 'year').startOf('day').toDate();
          rangeDates.endDate = moment().endOf('day').toDate();
          break;
        case custome.type:
          minMax.minDate = rangeDates.initialDate;
          minMax.maxDate = rangeDates.endDate;
          if (rangeDates.initialDate && rangeDates.endDate)
            label.value = `${dateFormater(rangeDates.initialDate)} a ${dateFormater(
              rangeDates.endDate,
            )}`;
          break;
        default:
          rangeDates.initialDate = undefined;
          rangeDates.endDate = undefined;
          break;
      }
    };
    const onSelect = (dateOption) => {
      const { custome } = optionsDates;
      if (dateOption.type != selected.value && dateOption.type !== custome.type) {
        selected.value = dateOption.type;
        label.value = dateOption.label;
      } else {
        label.value = custome.label;
        selected.value = dateOption.type === custome.type ? custome.type : '';
      }
      changeDateFilter(selected.value);
    };

    watch(rangeDates, () => {
      if (rangeDates.initialDate && rangeDates.endDate) {
        emit('update:model-value', rangeDates);
        if (selected.value === optionsDates.custome.type)
          label.value = `${dateFormater(rangeDates.initialDate)} a ${dateFormater(
            rangeDates.endDate,
          )}`;
      } else if (!rangeDates.initialDate && !rangeDates.endDate) {
        emit('update:model-value', rangeDates);
      }
    });
    onMounted(() => {
      if (props.defaultFilter) onSelect(optionsDates[props.defaultFilter]);
    });
    return {
      dropdownProps,
      label,
      optionsDates,
      onSelect,
      selected,
      minMax,
      rangeDates,
    };
  },
};
</script>
<style lang="sass" scoped>
#date-filter
    min-width: 300px
    border: 1px solid $grey-light
    .button-trigger
      border-radius: 0
      height: 100%
      &.is-primary.is-inverted
        color: $black
    :deep(.dropdown-trigger)
        width: 100%
        .button-wrapper > .icon .mdi::before
            font-size: 15px
    > :deep(.dropdown-content)
        z-index: 1
        margin-top: 8px
        padding: 0
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        min-width: 328px
        border-radius: 8px
        > .dropdown-conteiner
            display: grid
            grid-auto-flow: row
            gap: 22px
            margin: 0 auto
            padding: 16px
            .datepicker
              .dropdown-trigger .icon.is-left
                width: 25px
                height: 35px
                .mdi
                  height: fit-content
                  &::before
                    font-size: 15px
              .dropdown-content
                z-index: 1
                .pagination-list .control
                  width: fit-content
            .btn-options
              background-color: $color-white-blue
              border: solid 1px $grey-light
              font-weight: 500
              color: $black
              &:hover
                color: $white
                background-color: $primary
              &.is-selected
                background-color: $primary
                color: $white
                &:hover
                  background-color: $primary-hover
            .pickers-date-container
                display: flex
                justify-content: space-between
                .field
                    margin: 0
.w-50
    width: calc( 50% - 8px )
</style>

import { computed, ref } from 'vue';
import { PermissionValidator } from '@/utils/Secure';
import { Permissions } from '@/utils/Secure';
import moment from 'moment';
import timestamp from '@/utils/timestamp';
import { useContabilidad } from '@/utils/composables';

const isEnableSort = ref(false);
const isLoadingSort = ref(false);

const useTableOperation = () => {
  const { INDICATOR } = useContabilidad();
  const { VERDE, AMARILLO, ROJO } = INDICATOR;

  const formatLastUpdate = (time) => {
    return time
      ? moment.utc(time).utcOffset('GMT-06:00:').format('DD/MM/YYYY - hh:mm A')
      : '00/00/00 - 00:00 PM';
  };
  const statusIcon = (status) => {
    let icon = 'clipboard-alert';
    if (status == 'EN PROGRESO') icon = 'timelapse';
    else if (status == 'FINALIZADA') icon = 'checkbox-marked-circle';
    return icon;
  };
  const setIndicator = (_indicator) => {
    const _semaphoring = {};
    switch (_indicator) {
      case VERDE.value:
        _semaphoring.variant = 'primary';
        _semaphoring.icon = 'comment-check-outline';
        _semaphoring.label = 'Terminado sin observaciones o recomendaciones';
        break;
      case AMARILLO.value:
        _semaphoring.variant = 'warning';
        _semaphoring.icon = 'comment-alert-outline';
        _semaphoring.label = 'Terminado con recomendaciones';
        break;
      case ROJO.value:
        _semaphoring.variant = 'danger';
        _semaphoring.icon = 'comment-remove-outline';
        _semaphoring.label = 'Terminado con observaciones';
        break;
    }
    return _semaphoring;
  };
  const accumulatedTime = (time) => {
    return time ? timestamp.format('h:m', time) : '00:00';
  };
  const isOutOfRange = (worked, planned) => {
    const hoursWorked = accumulatedTime(worked)
      .split(':')
      .map((h) => Number(h));
    const hoursPlanned = accumulatedTime(planned)
      .split(':')
      .map((h) => Number(h));

    return planned
      ? hoursWorked[0] > hoursPlanned[0] ||
          (hoursWorked[0] === hoursPlanned[0] && hoursWorked[1] > hoursPlanned[1])
      : false;
  };

  const allowAddHour = computed(() => {
    const { Create } = Permissions.AccumulatedTime;
    return PermissionValidator(Create);
  });
  const allowDeleteOperation = computed(() => {
    const { Delete } = Permissions.ConceptoOperations;
    return PermissionValidator(Delete);
  });
  const allowUpdate = computed(() => {
    const { Update } = Permissions.AccumulatedTime;
    return PermissionValidator(Update);
  });
  const allowUpload = computed(() => {
    const { Upload } = Permissions.ConceptoOperations;
    return PermissionValidator(Upload);
  });

  return {
    // Data
    isEnableSort,
    isLoadingSort,

    // Method
    accumulatedTime,
    formatLastUpdate,
    setIndicator,
    statusIcon,
    isOutOfRange,

    // Computed
    allowAddHour,
    allowDeleteOperation,
    allowUpdate,
    allowUpload,
  };
};
export default useTableOperation;

<template>
  <div ref="toolbar" v-click-outside="closeToolbar" class="toolbar w-100" :class="classesToolbar">
    <div class="toolsItems">
      <Button v-if="isClient" icon-left="home" label="Inicio" @click="onNavigate('/')" />
      <Button
        v-if="(isClient && hasContabilidadService) || (allowDashboard && !isClient)"
        icon-left="chart-line-variant"
        label="Contabilidad"
        @click="onNavigate(isClient ? '/contabilidad' : '/')"
      />
      <Button
        v-if="allowUserPanel"
        v-model:class="isActivePanelUser"
        icon-left="bank"
        label="Usuarios"
        @click="transition('users')"
      />
      <Button
        v-if="allowClientPanel"
        v-model:class="isActivePanelClient"
        icon-left="account-circle"
        label="Clientes"
        @click="transition('client')"
      />

      <Button v-if="allowStages" icon-left="flag" label="Etapas" @click="onNavigate('/etapas')" />

      <Button v-if="!isWIP" icon-left="flag" label="Etapas" @click="onNavigate()" />
      <Button
        v-if="!isWIP"
        icon-left="chart-timeline-variant"
        label="Corridas"
        @click="onNavigate()"
      />
      <Button v-if="!isWIP" icon-left="email" label="Correos" @click="onNavigate()" />
      <Button
        v-if="(allowAuditoria && !isClient) || (isClient && hasAuditingService)"
        icon-left="eye"
        label="Auditorías"
        @click="onNavigate('/auditorias')"
      />
      <Button
        v-if="allowBills || isClient"
        icon-left="receipt-text"
        label="Facturación"
        @click="billingNavigation"
      />
      <Notifications v-if="allowNotifications" class="ml-auto" />
      <Button
        :class="allowNotifications ? '' : 'ml-auto'"
        class="logout"
        icon-right="logout"
        label="Cerrar sesión"
        :disabled="loading.signOut"
        :loading="loading.signOut"
        @click="onSignOut()"
      />
    </div>
    <Panel v-model="isShow" @after-leave="onClosePanel">
      <ClientPanel v-if="active == 'client'" />
      <UserPanel v-else-if="active == 'users'" />
    </Panel>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { Button } from '@/components';
import Notifications from '@/components/Notifications.vue';
import Panel from './Panel';
import { serviceName, userRoles } from '@/config/constants';
import ClientPanel from './ClientPanel';
import UserPanel from './UserPanel';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store';
import { Permissions } from '@/utils/Secure';
import { PermissionValidator } from '@/utils/Secure';

export default {
  components: {
    Button,
    Panel,
    ClientPanel,
    UserPanel,
    Notifications,
  },
  setup() {
    const auth = useAuthStore();
    const router = useRouter();
    const isShow = ref(false);
    const active = ref('');
    const loading = ref({ signOut: false });
    const toolbar = ref(null);
    const classesToolbar = ref('');
    const isWIP = ref(true);
    const { getAUTH } = useAuthStore();

    const allowUserPanel = computed(() => {
      const { Read, Create } = Permissions.Users;
      return PermissionValidator(Read) || PermissionValidator(Create);
    });
    const allowClientPanel = computed(() => {
      const { ReadAll, Create } = Permissions.Clients;
      return PermissionValidator(ReadAll) || PermissionValidator(Create);
    });
    const allowNotifications = computed(() => {
      const { ReadAll } = Permissions.Notifications;
      return PermissionValidator(ReadAll);
    });
    const allowAuditoria = computed(() => {
      const { ReadAll, Create, Read } = Permissions.Auditing;
      return (
        PermissionValidator(ReadAll) || PermissionValidator(Create) || PermissionValidator(Read)
      );
    });
    const allowBills = computed(() => {
      const { ReadAll } = Permissions.Bills;
      return PermissionValidator(ReadAll);
    });
    const allowDashboard = computed(() => {
      const { ReadAll, Create, Read } = Permissions.MonthlyRun;
      return (
        PermissionValidator(ReadAll) || PermissionValidator(Create) || PermissionValidator(Read)
      );
    });
    const allowStages = computed(() => {
      const { ReadAll } = Permissions.Stages;
      return PermissionValidator(ReadAll);
    });

    const isActivePanelUser = computed(() => {
      if (active.value == 'users') return 'isSelected';
      return '';
    });
    const isActivePanelClient = computed(() => {
      if (active.value == 'client') return 'isSelected';
      return '';
    });
    const hasAuditingService = computed(() => auth.getClientServicesName(serviceName.AUDITORIA));
    const hasContabilidadService = computed(() =>
      auth.getClientServicesName(serviceName.CONTABILIDAD),
    );
    const isClient = computed(() => auth.getAUTH?.role === userRoles.CLIENTE);
    const billingNavigation = () => {
      if (isClient.value) onNavigate(`/facturacion/${getAUTH.customer_id}`);
      else onNavigate('/facturacion');
    };
    const onSignOut = async () => {
      loading.value.signOut = true;
      try {
        await auth.deAUTH();
      } catch (error) {
        console.log(error);
      }
      loading.value.signOut = false;
    };
    const onClosePanel = () => (classesToolbar.value = '');
    const closeToolbar = () => (isShow.value = false);
    const onNavigate = (path) => {
      isShow.value = false;
      if (path) router.push({ path });
    };

    watch(isShow, (value) => {
      if (!value) active.value = '';
    });

    return {
      isClient,
      billingNavigation,
      hasAuditingService,
      hasContabilidadService,
      allowUserPanel,
      allowClientPanel,
      allowAuditoria,
      allowBills,
      allowDashboard,
      allowStages,
      isShow,
      closeToolbar,
      allowNotifications,
      classesToolbar,
      active,
      loading,
      isActivePanelUser,
      isActivePanelClient,
      onSignOut,
      onNavigate,
      toolbar,
      onClosePanel,
      transition: (item) => {
        if (item === active.value) {
          isShow.value = !isShow.value;
          active.value = '';
        } else if (active.value === '') {
          isShow.value = !isShow.value;
          active.value = item;
        } else {
          active.value = item;
        }
        if (active.value.length) classesToolbar.value = 'border-bottom-enabled';
      },
      isWIP,
    };
  },
};
</script>
<style lang="sass" scoped>
.toolbar
  min-height: 50px
  background-color: $grey-300 !important
  border: 1px solid $grey-300 !important
  padding: 0
  .toolsItems
    overflow: auto
    flex-wrap: nowrap
    display: flex
    padding: 0 60px
    :deep(.button)
      border: 1px solid $grey-300
      min-height: 50px
      font-weight: 600
      line-height: 21px
      font-size: $f-sm
      border-radius: 0
      margin-right: 1px
      background-color: $grey-300
      color: $black
      padding: 0 16px
      &:focus:not(:active), &.is-focused:not(:active)
        box-shadow: 0 0 0 0.125em rgba(146, 153, 158, 0.25)
      &:enabled:hover
        background-color: $white
        color: #069481
      .icon
        margin-right: 6px
        .mdi::before
          font-size: $fh-sm

  &.border-bottom-enabled .toolsItems :deep(.button)
    border-bottom: 1px solid $grey-300
  :deep(.button)
    &.logout
      min-width: 158px
    &.isSelected
      background-color: $white !important
      color: #069481 !important
      border: 1px solid $grey-300 !important
      border-bottom: 1px solid $white !important
@media only screen and (max-width: $bp-md)
  .toolbar
    .toolsItems
      padding: 0 5px
</style>

<template>
  <Modal
    id="modal-end-auditing"
    v-model:is-open="isModalActive"
    header="ADVERTENCIA - Cerrar auditoría"
    button-label="Cerrar auditoría"
    :save-disabled="!readyToClose"
    :loading="loading"
    @save="saveFiles"
  >
    <p class="has-text-black f-xss">Subir los archivos correspondientes para cerrar:</p>
    <template v-for="document in filesToclose" :key="document.key">
      <div v-if="document.file.name" class="is-flex is-align-items-center">
        <p class="f-sm has-text-weight-semibold has-text-info mr-auto">
          {{ document.typeName }}
        </p>
        <Button
          class="tiny-button ml-2"
          variant="danger"
          icon-right="close"
          @click="handleDelete(document.key)"
        />
      </div>
      <FilePicker
        v-else
        v-model:file="document.file"
        accept="pdf"
        :autosave="false"
        :title="`Subir ${document.typeName}`"
      />
    </template>
    <p class="docs-accept W-100 has-text-centered f-xxs">Archivos permitidos: pdf</p>
  </Modal>
</template>
<script>
import { Modal, FilePicker, Button } from '@/components';
import { uploadMultipleFiles } from '@/utils/Presigned';
import useDialog from '@/utils/composables/useDialog';
import { ref, watch, toRefs, computed, reactive, getCurrentInstance } from 'vue';
export default {
  components: { Modal, FilePicker, Button },
  props: {
    auditingId: { type: [Number, String], default: null },
    auditingName: { type: [Number, String], default: null },
    isOpen: { type: Boolean, default: false },
  },
  emits: ['update:active', 'update:is-open', 'success'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const loading = reactive({ save: false });
    const { Notify } = useDialog();
    const { isOpen } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const filesToclose = reactive({
      DICTAMEN: {
        key: 'DICTAMEN',
        typeName: 'Dictamen de estados financieros',
        file: {},
        fileName: '',
      },
      COMUNICADO: {
        key: 'COMUNICADO',
        typeName: 'Comunicado de observaciones',
        file: {},
        fileName: '',
      },
    });

    const resetModal = () => {
      filesToclose.COMUNICADO.file = {};
      filesToclose.DICTAMEN.file = {};
      isModalActive.value = false;
    };
    const handleDelete = (key) => {
      const { DICTAMEN, COMUNICADO } = filesToclose;
      if (DICTAMEN.key === key) DICTAMEN.file = {};
      else COMUNICADO.file = {};
    };
    const saveFiles = async () => {
      loading.save = true;
      try {
        const { auditingId, auditingName } = props;
        const { DICTAMEN, COMUNICADO } = filesToclose;
        // formatFileName();
        DICTAMEN.fileName = `${DICTAMEN.typeName}.${DICTAMEN.file.name.split('.')[1]}`;
        COMUNICADO.fileName = `${COMUNICADO.typeName}.${COMUNICADO.file.name.split('.')[1]}`;
        await uploadMultipleFiles({
          files: {
            DICTAMEN: DICTAMEN.file,
            COMUNICADO: COMUNICADO.file,
          },
          apiUrl: `/auditing/${auditingId}/presigned/upload`,
          fileNames: {
            DICTAMEN: DICTAMEN.fileName,
            COMUNICADO: COMUNICADO.fileName,
          },
        });
        await Api.patch(`/auditing/${auditingId}/filename`, {
          file_name: {
            DICTAMEN: DICTAMEN.fileName,
            COMUNICADO: COMUNICADO.fileName,
          },
        });
        await Api.patch(`/auditing/${auditingId}/change-status`, {
          status: 'FINALIZADA',
        });
        emit('success');
        Notify(
          'success',
          'Auditoría finalizada',
          `La Auditoría <b>${auditingName}</b> fue finalizada de manera exitosa.`,
        );
        resetModal();
      } catch (error) {
        console.log(error);
      }
      loading.save = false;
    };

    const readyToClose = computed(() => {
      const { COMUNICADO, DICTAMEN } = filesToclose;
      return DICTAMEN.file.name && COMUNICADO.file.name;
    });

    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
    });

    return {
      resetModal,
      // Data
      filesToclose,
      isModalActive,
      loading,

      // computed
      readyToClose,

      // method
      handleDelete,
      saveFiles,
      //   formatFileName,
    };
  },
};
</script>
<style lang="sass" scoped>
#modal-end-auditing :deep(.modal-content) .card .card-content
    display: flex
    flex-direction: column
    gap: 10px
    padding: 40px 20px
    height: 225px
    .docs-accept
      color:$grey-info
    .tiny-button
       min-width: 16px
       min-height: 16px
       width: 16px
       height: 16px
       padding: 0
       .icon
         margin: 0 !important
         .mdi::before
           font-size: 14px
         .mdi-loading::before
           font-size: 10px !important
</style>

import moment from 'moment';
const timestamp = (() => {
  let rtf, tokensRtf;
  const tokens = /[Dhmsf][#~]?|"[^"]*"|'[^']*'/g,
    map = [
      { t: [['D', 1], ['D#'], ['D~', 'day']], u: 86400000 },
      { t: [['h', 2], ['h#'], ['h~', 'hour']], u: 3600000 },
      { t: [['m', 2], ['m#'], ['m~', 'minute']], u: 60000 },
      { t: [['s', 2], ['s#'], ['s~', 'second']], u: 1000 },
      { t: [['f', 3], ['f#'], ['f~']], u: 1 },
    ],
    locale = (value, style = 'long') => {
      try {
        rtf = new Intl.RelativeTimeFormat(value, { style });
      } catch (e) {
        if (rtf) throw e;
        return;
      }
      const h = rtf.format(1, 'hour').split(' ');
      tokensRtf = new Set(
        rtf
          .format(1, 'day')
          .split(' ')
          .filter((t) => t != 1 && h.indexOf(t) > -1),
      );
      return true;
    },
    fallback = (t, u) => u + ' ' + t.fmt + (u == 1 ? '' : 's'),
    mapper = {
      number: (t, u) => (u + '').padStart(t.fmt, '0'),
      string: (t, u) =>
        rtf
          ? rtf
              .format(u, t.fmt)
              .split(' ')
              .filter((t) => !tokensRtf.has(t))
              .join(' ')
              .trim()
              .replace(/[+-]/g, '')
          : fallback(t, u),
    },
    replace = (out, t) => out[t] || t.slice(1, t.length - 1),
    toMilliseconds = (value) => {
      let timestampParse;
      if (value.match(/(?:[0-9]{0,2} (day||days), [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2})/gm)) {
        timestampParse = value.split(',');
        timestampParse[0] = timestampParse[0].replace(/\D/gm, '');
        timestampParse = [timestampParse[0], ...timestampParse[1].split(':')];
        return moment
          .duration({
            seconds: timestampParse[3],
            minutes: timestampParse[2],
            hours: timestampParse[1],
            days: timestampParse[0],
          })
          .asMilliseconds();
      } else if (value.match(/(?:[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2})/gm))
        return moment.duration(value).asMilliseconds();
    },
    format = (pattern, value) => {
      if (typeof pattern !== 'string') throw Error('invalid pattern');
      if (typeof value === 'string') value = toMilliseconds(value);
      if (!Number.isFinite(value)) throw Error('invalid value');
      if (!pattern) return '';
      const out = {};
      value = Math.abs(value);
      pattern.match(tokens)?.forEach((t) => (out[t] = null));
      map.forEach((m) => {
        let u = null;
        m.t.forEach((t) => {
          if (out[t.token] !== null) return;
          if (u === null) {
            u = Math.floor(value / m.u);
            value %= m.u;
          }
          out[t.token] = '' + (t.fn ? t.fn(t, u) : u);
        });
      });
      return pattern.replace(tokens, replace.bind(null, out));
    };
  map.forEach(
    (m) =>
      (m.t = m.t.map((t) => ({
        token: t[0],
        fmt: t[1],
        fn: mapper[typeof t[1]],
      }))),
  );
  locale('es');
  return { format, locale, toMilliseconds };
})();
export default timestamp;

<template>
  <o-dropdown
    id="filter-dropdown"
    aria-role="list"
    v-bind="{ ...dropdownProps }"
    :mobile-modal="false"
  >
    <template #trigger="{ active }">
      <o-button
        class="button-trigger"
        :icon-left="iconLeft"
        variant="primary"
        :inverted="!active && isActive < 1"
        expanded
      >
        <span>{{ headerTitle }}</span>
      </o-button>
    </template>
    <div class="dropdown-conteiner">
      <slot name="body" />
    </div>
  </o-dropdown>
</template>
<script>
import { usePickProps } from './conf/composables';

export default {
  props: {
    isActive: { type: [Boolean, Number], default: false },
    iconLeft: { type: String, default: null },
    headerTitle: { type: String, default: '' },
  },
  setup(props, { attrs }) {
    const { propsPicked: dropdownProps } = usePickProps('DropdownProps', { props, attrs });

    return { dropdownProps };
  },
};
</script>
<style lang="sass" scoped>
#filter-dropdown
  min-width: 300px
  height: 100%
  border: 1px solid $grey-light
  .button-trigger
      border-radius: 0
      height: 100%
      overflow: hidden
      font-weight: 600
      &.is-primary.is-inverted
        color: $black
  > :deep(.dropdown-trigger)
    width: 100%
    .icon .mdi::before
      font-size: 15px
  > :deep(.dropdown-content)
    z-index: 1
    margin-top: 8px
    padding: 0
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
    min-width: 100%
    border-radius: 8px
    .dropdown-conteiner
      display: grid
      grid-auto-flow: row
      gap: 22px
      margin: 0 auto
      padding: 16px
</style>

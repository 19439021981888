<template>
  <Button
    v-for="{ key } in auditingFiles"
    :key="`${auditingId} - ${key}`"
    :label="key"
    variant="info"
    icon-left="download"
    :loading="auditingFiles[key].isDownloading"
    @click="onDownloadDocument(key)"
  />
</template>
<script>
import { Button } from '@/components';
import { downloadFile } from '@/utils/Presigned';
import { reactive } from 'vue';
export default {
  components: {
    Button,
  },
  props: {
    auditingId: { type: [String, Number], default: null },
  },
  setup(props) {
    const auditingFiles = reactive({
      DICTAMEN: {
        key: 'DICTAMEN',
        isDownloading: false,
      },
      COMUNICADO: {
        key: 'COMUNICADO',
        isDownloading: false,
      },
    });
    const onDownloadDocument = async (key) => {
      auditingFiles[key].isDownloading = true;
      try {
        const src = await downloadFile({
          apiUrl: `/auditing/${props.auditingId}/presigned/download`,
          fileName: key,
        });
        if (src) window.location = src;
      } catch (error) {
        console.log(error);
      }
      auditingFiles[key].isDownloading = false;
    };
    return {
      auditingFiles,
      onDownloadDocument,
    };
  },
};
</script>
<style lang="sass" scoped></style>

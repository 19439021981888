<template>
  <div class="empty-data">
    <img class="img-error" src="../assets/img/Not_found.svg" alt="" />
    <h4 class="msg-error" v-html="emptyMessage" />
  </div>
</template>
<script>
export default {
  props: {
    emptyMessage: { type: String, default: 'No se encontraron resultados' },
  },
};
</script>
<style lang="sass" scoped>
.empty-data
    display: flex
    min-height: 210px
    flex-direction: column
    justify-content: center
    align-items: center
    .img-error
        width: 128px
        height: fit-content
        margin-bottom: 30px
    .msg-error
        color: $black
        line-height: 120%
        font-size: $fh-md
</style>

export default {
  length: 'Tamaño máximo {{value}}',
  required: 'El campo es requerido',
  email: 'Correo electrónico inválido',
  pattern: 'Se esperaba otro formato: {{value}}',
  extension: 'Extensión de archivo inválida',
  size: 'Archivo es demasiado grande, máximo {{value}} MB',
  presigned: 'No se puede cargar este archivo',
  fileLoad: 'Surgió un error cargando este archivo',
};

<template>
  <article class="comment" :class="classes" @click="onMessageClick(position)">
    <o-loading v-if="loading" v-model:active="loading" :full-page="false" />
    <ol :start="position + 1">
      <li>
        {{ data.message }}
        <p class="has-text-right observations-text mt-2">- {{ data.user_name }}, {{ data.date }}</p>
      </li>
    </ol>
  </article>
</template>
<script>
import { computed, ref, toRefs, watch } from 'vue';

export default {
  props: {
    disableActions: { type: Boolean, default: false },
    message: { type: Object, default: () => ({}) },
    position: { type: Number, default: null },
    target: { type: String, default: '' },
    onClickAction: { type: Function, default: null },
  },
  emits: ['update:message'],
  setup(props, { emit }) {
    const loading = ref(false);
    const { message } = toRefs(props);
    const data = ref(props.message);

    const classes = computed(() => {
      const classes = [];
      if (data.value.is_attended) classes.push('is-attended');
      if (props.disableActions) classes.push('disableActions');
      return classes.join(' ');
    });
    const setAttended = () => (data.value.is_attended = 1);
    const onMessageClick = async () => {
      loading.value = true;
      if (props.onClickAction) await props.onClickAction(props.position, props.target, setAttended);
      emit('update:message', data);
      loading.value = false;
    };

    watch(message, (value) => (data.value = value));
    watch(data, (value) => emit('update:message', value));
    return { loading, data, onMessageClick, classes };
  },
};
</script>
<style lang="sass" scoped>
.observations-text
    color: $grey-info
    font-size: $f-xss
.comment
  position: relative
  :deep(.o-load)
    min-width: unset !important
  &:not(.is-attended)
    cursor: pointer
    &::before
      display: block
      border-radius: 10px
      position: absolute
      content: ' '
      right: 10px
      width: 10px
      height: 10px
      background: $danger
    &::active
      background: red
  &.disableActions
    cursor: initial !important
    &::before
      content: unset !important
</style>

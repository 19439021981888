import { Permissions } from '@/utils/Secure';
import { serviceName } from '@/config/constants';

export default [
  {
    path: '/contabilidad',
    name: 'Contabilidad',
    meta: {
      title: 'Contabilidad',
      service: serviceName.CONTABILIDAD,
      secure: { permission: Permissions.MonthlyRun.ReadAll, redirect: null },
      props: {
        _currentPage: NaN,
        _totalItems: NaN,
        _balance: NaN,
      },
    },
    component: () =>
      import(/* webpackChunkName: "Contabilidad" */ '../views/Client/Contabilidad.vue'),
  },
  {
    path: '/contabilidad/:id(\\d+)',
    name: 'ContabilidadShow',
    meta: {
      title: 'Contabilidad',
      service: serviceName.CONTABILIDAD,
      secure: { permission: Permissions.MonthlyRun.ReadAll, redirect: null },
      props: {
        _currentPage: NaN,
        _totalItems: NaN,
        _balance: NaN,
      },
    },
    props: true,
    component: () =>
      import(/* webpackChunkName: "Contabilidad" */ '../views/Client/Contabilidad.vue'),
  },
];

<template>
  <div class="status-tag">
    <div v-if="tagTittle" class="status-title">
      <o-icon :icon="statusStyle.icon" size="small" :variant="statusStyle.variant"> </o-icon>
      <p class="tag-tittle">{{ tagTittle }}</p>
    </div>
    <p v-if="responsable.length" class="f-sm pl-5">
      Responsable: <Tag label="Cliente" size="small" rounded />
    </p>
    <Tag rounded class="f-xs" :variant="statusStyle.variant" :label="label" :size="size" />
  </div>
</template>
<script>
import { Tag } from '@/components';
import { toRefs, watch, onBeforeMount, ref } from 'vue';
import { useContabilidad } from '@/utils/composables';

export default {
  components: {
    Tag,
  },
  props: {
    label: { type: String, default: '' },
    tagTittle: { type: String, default: '' },
    responsable: { type: String, default: '' },
    statusId: { type: Number, default: null },
    indicator: {
      type: String,
      enum: ['AMARILLO', 'ROJO', 'VERDE'],
      default: 'VERDE',
    },
    size: { type: String, enum: ['small', 'medium', 'large'], default: 'medium' },
  },
  setup(props) {
    const statusStyle = ref({ variant: 'neutral', icon: 'progress-question' });
    const { indicator, statusId } = toRefs(props);
    const { stageHandleStyle } = useContabilidad();

    watch(statusId, (value) => {
      statusStyle.value = stageHandleStyle(value);
    });
    onBeforeMount(() => {
      statusStyle.value = stageHandleStyle(props.statusId, indicator.value);
    });
    return {
      statusStyle,
    };
  },
};
</script>
<style lang="sass" scoped>
.status-tag
    .status-title
      display: flex
      flex-direction: row
      :deep(.icon)
          z-index: 0
          margin-right: 8px
          font-size: 13px
          margin-top: 2px
          .mdi
            z-index: 0
      .tag-tittle
          font-weight: 600
          font-size: $f-sm
          color: $black
    > :deep(.tag)
        height: 18px
        line-height: 18px
        margin-top: 6px
        margin-left: 24px
</style>

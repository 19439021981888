import { ref, computed } from 'vue';
import moment from 'moment';
import { useAuthStore } from '@/store';

const table = ref(null);
const useComponentUtils = () => {
  const { getAUTH } = useAuthStore();
  const CleanText = (text) => text.replace(/[<>\\'"`]/g, '').normalize();

  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency,
    });
    return formatter.format(value);
  }
  const dollarFormater = (value) =>
    // outpout:  $123,456.00
    currencyFormatter({
      currency: 'USD',
      value,
    });
  const fomatDateDMY = (date) => (date ? moment(date).format('DD/MM/YYYY') : '-');
  const replaceCumulative = (str, find, replace) => {
    for (var i = 0; i < find.length; i++) {
      const scapable = ['.'];
      const findVal = scapable.indexOf(find[i]) >= 0 ? `\\${find[i]}` : find[i];
      const replaceVal = replace[i] || replace[0];
      str = str.replace(new RegExp(findVal, 'g'), replaceVal);
    }
    return str;
  };
  const Normalize = (text, options = {}) => {
    const { encodeUri, lower, replace, upper } = options;
    if (Number(text)) return String(text);
    if (!text || typeof text !== 'string') return text;

    text = text.trim();
    if (lower) text = text.toLowerCase();
    if (upper) text = text.toUpperCase();
    if (replace) text = replaceCumulative(text, replace.find, replace.value);
    if (encodeUri) text = window.encodeURI(text);
    return text;
  };
  const roles = {
    Administrador: 'Administrador',
    Auditor: 'Auditor',
    Operador: 'Operador',
    Cliente: 'Cliente',
  };
  const isClient = computed(() => getAUTH?.role === roles.Cliente);

  return {
    fomatDateDMY,
    dollarFormater,
    table,
    Normalize,
    formatShortName: (full_name) => {
      if (!full_name) return ' ';
      let fullName = Normalize(full_name, { replace: { find: ['.', ','], value: [''] } }).split(
        ' ',
      );
      if (fullName.length == 1) return full_name;
      else if (fullName.length == 2) return `${fullName[1]}, ${fullName[0]}`;
      else return `${fullName.pop()}, ${fullName[0]}`;
    },
    formatPhoneNumber: (number) => number.replace(/([0-9]{3})+([0-9]{3})+([0-9]{4})/g, '$1 $2 $3'),
    isClient,
    roles,
    CleanText,
  };
};
export default useComponentUtils;

<template>
  <div ref="root" :class="isPreview ? 'is-12' : ''">
    <Field
      id="important"
      class="question-title"
      :message="myQuestion.error"
      :type="myQuestion.error ? 'is-danger' : ''"
      :label="questionTitle(myQuestion)"
    >
      <template v-if="myQuestion.type == 'select' ? true : false">
        <section v-if="!isVisualizator">
          <o-select
            ref="focus"
            v-model="myQuestion.value"
            :placeholder="questionTitle(myQuestion)"
            :required="myQuestion.validate.required"
            :value="myQuestion.value"
            :icon="myQuestion.iconClass"
            :readonly="myQuestion.validate.readOnly || false"
            :disabled="isVisualizator || false"
            class="is-disabled"
            expanded
            @input="onInput"
            @keyup.enter="onEnter"
          >
            <option value="" :label="myQuestion.placeholder" />
            <option
              v-for="option in myQuestion.data"
              :key="option.val"
              :value="option"
              :label="option.text"
              :selected="option.checked"
            />
          </o-select>
        </section>
        <section v-else>
          <o-input v-model="myQuestion.value.text" :icon="myQuestion.iconClass" :disabled="true" />
        </section>
      </template>
      <template v-else-if="myQuestion.type == 'date' ? true : false">
        <span v-if="myQuestion.returnedValue == 'hour'">
          <o-timepicker
            v-model="selected_hour"
            :placeholder="myQuestion.placeholder"
            :required="myQuestion.validate.required"
            :min-time="myQuestion.validate.minHour ? new Date(myQuestion.validate.minHour) : null"
            :max-time="myQuestion.validate.maxHour ? new Date(myQuestion.validate.maxHour) : null"
            :icon="myQuestion.iconClass"
            trap-focus
            :disabled="isVisualizator || false"
            @input="selectHour"
            @keyup.enter="onEnter"
          />
        </span>
        <span v-else-if="myQuestion.returnedValue == 'date'">
          <o-datepicker
            v-model="selected_date"
            :placeholder="myQuestion.placeholder"
            :required="myQuestion.validate.required"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :day-names="['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']"
            :min-date="myQuestion.validate.minDate ? new Date(myQuestion.validate.minDate) : null"
            :max-date="myQuestion.validate.maxDate ? new Date(myQuestion.validate.maxDate) : null"
            :icon="myQuestion.iconClass"
            trap-focus
            :disabled="isVisualizator || false"
            @input="selectDate"
            @keyup.enter="onEnter"
          >
          </o-datepicker>
        </span>
        <span v-else>
          <o-datetimepicker
            v-model="selected_date"
            rounded
            trap-focus
            :datepicker="{
              monthNames: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
              ],
              dayNames: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            }"
            :placeholder="myQuestion.placeholder"
            :required="myQuestion.validate.required"
            :icon="myQuestion.iconClass"
            :min-datetime="minDatetime"
            :max-datetime="maxDatetime"
            :disabled="isVisualizator || false"
            @keyup.enter="onEnter"
            @input="selectDate"
          >
          </o-datetimepicker>
        </span>
      </template>
      <template v-else-if="myQuestion.type == 'map' ? true : false">
        <div>
          <section v-if="isPreview">
            <o-field label="Dirección" :message="'Dirección no disponible durante el preview'">
              <o-input type="text" value="Calculando..." />
            </o-field>
          </section>
          <section v-else>
            <o-field
              label="Dirección"
              :message="address_error ? 'No se pudo encontrar la dirección' : ''"
              :type="address_error || myQuestion.error ? 'is-danger' : ''"
              @input="onInput"
            >
              <o-input
                v-model="myQuestion.value.address"
                type="text"
                :value="myQuestion.value.address"
                placeholder="Eje. Colima Colima"
                disabled
              />
            </o-field>
          </section>
          <section v-if="myQuestion.returnedValue == 'coordinates'">
            <div class="columns">
              <div class="column">
                <o-field label="Latitud">
                  <o-input
                    v-model="myQuestion.value.latitude"
                    type="text"
                    placeholder="Eje. 101.232"
                    disabled
                    @input="onInputAdress"
                  />
                </o-field>
              </div>
              <div class="column">
                <o-field label="Longitud">
                  <o-input
                    v-model="myQuestion.value.longitude"
                    type="text"
                    placeholder="Eje. -101.23222"
                    disabled
                  />
                </o-field>
              </div>
            </div>
          </section>
          <br />
          <section v-if="isVisualizator">
            <GMapMap
              :center="center_marker"
              :zoom="myQuestion.zoom"
              style="width: 100%; height: 400px"
              :disabled="isVisualizator || false"
            >
              <GMapMarker
                :position="marker.position"
                @click="center_marker = marker.position"
              ></GMapMarker>
            </GMapMap>
          </section>
          <section v-else>
            <GMapMap
              ref="focus"
              :center="center_marker"
              :zoom="myQuestion.zoom"
              style="width: 100%; height: 400px"
              :clickable="true"
              :draggable="true"
              @click="handlerAddMarker"
            >
              <GMapMarker
                :position="marker.position"
                :draggable="true"
                @click="center_marker = marker.position"
              ></GMapMarker>
            </GMapMap>
          </section>
        </div>
      </template>
      <template v-else-if="myQuestion.type == 'radio' ? true : false">
        <section>
          <div v-for="(item, i) in myQuestion.data" :key="i">
            <o-radio
              v-if="item.text !== 'Otro(a)'"
              ref="focus"
              v-model="myQuestion.value"
              :native-value="item.val"
              :required="myQuestion.validate.required || false"
              :checked="item.checked"
              :disabled="isVisualizator || false"
              @input="onInput"
              @click="
                () => {
                  if (!isVisualizator) {
                    show_other_input = false;
                    myQuestion.data[myQuestion.data.length - 1].checked = false;
                  }
                }
              "
            >
              {{ item.text }}
            </o-radio>
          </div>
          <div v-if="myQuestion.validate.activeOther">
            <o-radio
              v-model="show_other_input"
              :native-value="true"
              :disabled="isVisualizator || false"
              @input="onInput"
              @click="
                () => {
                  if (!isVisualizator) {
                    myQuestion.value = '';
                    myQuestion.data[myQuestion.data.length - 1].checked = true;
                  }
                }
              "
            >
              Otro(a)
            </o-radio>
            <span v-if="show_other_input || myQuestion.data[myQuestion.data.length - 1].checked">
              <o-input
                v-model="myQuestion.value"
                type="text"
                :placeholder="myQuestion.placeholder"
                :required="myQuestion.validate.required || false"
                :disabled="isVisualizator || false"
                @keyup.enter="onEnter"
                @input="onInput"
              />
            </span>
          </div>
        </section>
      </template>
      <template v-else-if="myQuestion.type == 'checkbox' ? true : false">
        <section>
          <div v-for="(item, i) in myQuestion.data" :key="i">
            <o-checkbox
              ref="focus"
              v-model="myQuestion.value"
              :native-value="item.val"
              :required="myQuestion.validate.required || false"
              :checked="item.checked"
              :disabled="isVisualizator || false"
              @input="onInput"
            >
              {{ item.text }}
            </o-checkbox>
          </div>
        </section>
      </template>
      <template v-else-if="myQuestion.type == 'file' ? true : false">
        <div>
          <section v-if="!isVisualizator">
            <o-field>
              <o-upload
                v-model="file"
                :required="myQuestion.validate.required || false"
                :accept="myQuestion.inputValid.join()"
                :readonly="myQuestion.validate.readOnly || false"
                :disabled="isVisualizator || loading_file || false"
                name="file"
                @input="fileValidations"
                @keyup.enter="onEnter"
              >
                <a class="button is-success">
                  <o-icon :icon="myQuestion.iconClass"></o-icon>
                  <span>Subir Archivo</span>
                </a>
              </o-upload>
              <div class="file-name">
                {{ file ? file.name : null }}
              </div>
            </o-field>
            <p class="help is-danger">{{ error_file }}</p>
            <section v-if="loading_file">
              <p>A quyi va un progres</p>
              <!-- <o-progress type="is-success" show-value>Cargando...</o-progress> -->
            </section>
          </section>
          <section v-else>
            <o-field
              :type="myQuestion.value ? '' : 'is-danger'"
              :message="myQuestion.value ? '' : 'Sin archivo'"
            >
              <o-button
                type="is-success"
                :disabled="myQuestion.value ? false : true"
                @click="handlerViewFileUploaded"
              >
                Ver archivo
              </o-button>
            </o-field>
          </section>
        </div>
      </template>
      <template v-else-if="myQuestion.type == 'number' ? true : false">
        <o-input
          v-model="myQuestion.value"
          type="number"
          :icon="myQuestion.iconClass"
          :value="myQuestion.value"
          :placeholder="myQuestion.placeholder"
          :max="myQuestion.validate.maxNumber"
          :min="myQuestion.validate.minNumber"
          :step="myQuestion.validate.step"
          :required="myQuestion.validate.required || false"
          :readonly="myQuestion.validate.readOnly || false"
          :disabled="isVisualizator || false"
          @input="onInput"
          @keyup.enter="onEnter"
        />
      </template>
      <template v-else>
        <o-input
          v-model="myQuestion.value"
          :type="myQuestion.inputType"
          :icon="myQuestion.iconClass"
          :value="myQuestion.value"
          :placeholder="myQuestion.placeholder"
          :maxlength="myQuestion.validate.maxlength"
          :minlength="myQuestion.validate.minlength"
          :required="myQuestion.validate.required || false"
          :readonly="myQuestion.validate.readOnly || false"
          :disabled="isVisualizator || false"
          @input="onInput"
          @keyup.enter="onEnter"
        />
      </template>
    </Field>
  </div>
  <!-- Divider -->
  <Field v-if="isLoading" style="position: relative">
    <o-notification :closable="false" style="position: relative">
      <o-loading v-model:active="isLoading" :is-full-page="false" :can-cancel="true" />
    </o-notification>
  </Field>
</template>
<script>
import { ref, toRefs, watch, computed, onMounted } from 'vue';
import { Field } from '@/components';
import format from 'date-fns/format';
import ValidateErrors from './library/ValidateErrors.js';
import ERRORS from './library/errors';
import axios from 'axios';
import { useProgrammatic } from '@oruga-ui/oruga-next';

const googleApiKey = 'AIzaSyCnTGvHk5eqeDlv-tyLNLUSQUySBuPA0-0';

const Format = format;
export default {
  components: {
    Field,
  },
  inheritAttrs: false,
  props: {
    index: { type: Number, default: 0 },
    sectionId: { type: Number, default: 0 },
    question: {
      type: Object,
      required: true,
      validator: (question) => {
        let valid = true;
        'sequence' in question ? valid : (valid = false);
        'type' in question ? valid : (valid = false);
        'name' in question ? valid : (valid = false);
        'value' in question ? valid : (valid = false);
        'columns' in question ? valid : (valid = false);
        'label' in question ? valid : (valid = false);
        'validate' in question ? valid : (valid = false);
        !valid ? console.error('Invalid Question Format!') : valid;
        return valid;
      },
    },
    isPreview: { type: Boolean, default: false },
    isVisualizator: { type: Boolean, default: true },
    presigned: { type: Object, default: () => ({}) },
    authorizationToken: { type: String, default: () => new Date().toDateString() },
  },
  emits: ['updateQuestion', 'enterKey'],
  setup(props, { emit }) {
    const { question } = toRefs(props);
    const { oruga } = useProgrammatic();
    const myQuestion = ref({ error: null, value: null, ...props.question });
    const isLoading = ref(false);
    const address_error = ref(false);
    const center_marker = ref(
      myQuestion.value.type == 'map'
        ? {
            lat: myQuestion.value.value.latitude,
            lng: myQuestion.value.value.longitude,
          }
        : null,
    );
    const marker = ref(
      myQuestion.value.type == 'map'
        ? {
            position: {
              lat: myQuestion.value.value.latitude,
              lng: myQuestion.value.value.longitude,
            },
          }
        : null,
    );
    const selected_date = ref(
      myQuestion.value.type == 'date'
        ? myQuestion.value.value.date
          ? new Date(myQuestion.value.value.date)
          : null
        : null,
    );
    const selected_hour = ref(
      myQuestion.value.value.type == 'date'
        ? myQuestion.value.value.hour
          ? new Date(myQuestion.value.value.hour)
          : null
        : null,
    );
    const show_other_input = ref(
      myQuestion.value.type == 'radio'
        ? props.isVisualizator && myQuestion.value.data[myQuestion.value.data.length - 1].checked
          ? true
          : false
        : false,
    );
    const file = ref(null);
    const loading_file = ref(false);
    const error_file = ref('');
    const format = ref(Format);
    const data_from_url = ref(null);
    const root = ref(null);

    const getValidateFromQuestion = (quest) => quest.validator || {};
    const questionTitle = (quest) => {
      const validate = getValidateFromQuestion(quest);
      const requiredMark = validate.required ? '*' : '';
      const { label, placeholder } = quest;
      return `${requiredMark} ${label || placeholder}`;
    };
    const onEnter = () => {
      emit('enterKey');
    };
    const onInput = () => {
      setTimeout(() => {
        if (myQuestion.value.error) myQuestion.value.error = '';
      }, 5);
    };
    const selectDate = () => {
      myQuestion.value.value.date = selected_date.value;
      onInput();
    };
    const selectHour = () => {
      myQuestion.value.value.hour = selected_hour.value;
      onInput();
    };
    const handlerAddMarker = (place) => {
      const _marker = {
        lat: place.latLng.lat(),
        lng: place.latLng.lng(),
      };
      marker.value = { position: _marker };
      myQuestion.value.value.longitude = place.latLng.lng();
      myQuestion.value.value.latitude = place.latLng.lat();
      address_error.value = false;
      onInputAdress();
    };
    const onInputAdress = () => {
      if (!props.isPreview) {
        const url = `
          https://maps.googleapis.com/maps/api/geocode/json?latlng=${myQuestion.value.value.latitude},${myQuestion.value.value.longitude}&key=${googleApiKey}`;
        axios
          .get(url)
          .then((resp) => {
            myQuestion.value.value.address = resp.data.results[0].formatted_address;
            console.log(myQuestion.value.value.address);
          })
          .catch((error) => {
            address_error.value = true;
            console.log(error);
          });
        onInput();
      }
    };
    const getFileExtension = () => file.value.name.substring(file.value.name.indexOf('.'));
    const preparePresignedUrl = async (file_name) => {
      const requestData = {
        file_name: file_name,
        resources: props.presigned.resourceKey,
        conditions: props.presigned.conditions,
        method: props.presigned.method,
      };
      return await axios.post(props.presigned.pathPresigned, requestData, {
        headers: props.presigned.headers,
      });
    };
    const prepareFormData = (presigned) => {
      let formData = new FormData();
      formData.append('key', presigned.data.fields.key);
      formData.append('AWSAccessKeyId', presigned.data.fields.AWSAccessKeyId);
      formData.append('x-amz-security-token', presigned.data.fields['x-amz-security-token']);
      formData.append('policy', presigned.data.fields.policy);
      formData.append('signature', presigned.data.fields.signature);
      formData.append('file', file.value);
      return formData;
    };
    const uploadFile = async () => {
      if (props.presigned?.pathPresigned) {
        const file_name = String(Date.now()) + getFileExtension();
        const presigned = await preparePresignedUrl(file_name);
        if (presigned.data) {
          const body = prepareFormData(presigned);
          //axios.defaults.headers.post['Content-Type'] = 'application/vnd.ms-excel';
          const success = await axios.post(presigned.data.url, body, {
            headers: { 'Content-Type': 'application/vnd.ms-excel' },
          });
          if (success.status == 204) {
            loading_file.value = false;
            myQuestion.value.value = file_name;
            console.log('File loaded successful!!');
          } else error_file.value = ValidateErrors.fileLoad;
        } else error_file.value = ValidateErrors.presigned;
      } else {
        throw ERRORS.PRESIGNED.CONFIG;
      }
      loading_file.value = false;
    };
    const fileValidations = async (e) => {
      file.value = e.target.files[0];
      error_file.value = '';
      try {
        loading_file.value = true;
        if (!file.value) {
          error_file.value = ValidateErrors.extension;
          return;
        }
        const extension = '.' + file.value.name.split('.').pop();
        if (!myQuestion.value.inputValid.includes(extension)) {
          error_file.value = ValidateErrors.extension;
          return;
        }
        const validSize = 1024 * 1024 * Number(myQuestion.value.validate.size);
        if (file.value.size > validSize) {
          file.value = null;
          error_file.value = ValidateErrors.size.replace(
            '{{value}}',
            myQuestion.value.validate.size,
          );
          return;
        }
        await uploadFile();
        onInput();
        error_file.value = '';
      } catch (error) {
        console.log(typeof error);
        if (typeof error === 'string') error_file.value = error;
        console.warn(error);
      } finally {
        console.log('false');
        loading_file.value = false;
      }
    };
    const handlerViewFileUploaded = async () => {
      const loadingComponent = oruga.loading.open();
      const prepareDownloadPresigned = async function (url, body, headers) {
        const result = await axios.post(url, body, headers);
        if (result.status == 200) return result.data;
      };
      if (myQuestion.value.value) {
        const url = await prepareDownloadPresigned(
          props.presigned.pathPresigned,
          { key: myQuestion.value.value, expires_in: 3600 },
          { headers: props.presigned.headers },
        );
        window.open(url.url, '_blank');
      }
      loadingComponent.close();
    };

    const minDatetime = computed(() => {
      let date,
        time = '';
      if (myQuestion.value.validate.minDate) date = myQuestion.value.validate.minDate.split('T')[0];
      if (myQuestion.value.validate.minHour) time = myQuestion.value.validate.minHour.split('T')[1];
      let minDatetime = new Date(date + 'T' + time);
      if (minDatetime.getTime()) return minDatetime;
      else return null;
    });
    const maxDatetime = computed(() => {
      let date,
        time = '';
      if (myQuestion.value.validate.maxDate) date = myQuestion.value.validate.maxDate.split('T')[0];
      if (myQuestion.value.validate.maxHour) time = myQuestion.value.validate.maxHour.split('T')[1];
      let maxDatetime = new Date(date + 'T' + time);
      if (maxDatetime.getTime()) return maxDatetime;
      else return null;
    });

    watch(question, (value) => (myQuestion.value = value));
    watch(myQuestion, (value) => {
      emit('updateQuestion', { sectionId: props.sectionId, value });
    });
    // Función auto ejecutada
    (async () => {
      myQuestion.value = question.value || {};
      const _myQuestion = question.value;
      const types = ['select', 'checkbox', 'radio'];
      if (types.includes(_myQuestion.type) && !props.isVisualizator) {
        // if is necesary fill questions whit an url: ["select", "checkbox", "radio"]
        if (_myQuestion.data.length <= 0 && _myQuestion.dataurl.length > 0) {
          const resolve = async function (url, token) {
            const result = await axios.get(url, {
              headers: { Authorization: token },
            });
            if (result.status == 200) return result.data.data;
          };
          isLoading.value = true;
          _myQuestion.data = await resolve(
            _myQuestion.dataurl,
            _myQuestion.validate.requireToken ? props.authorizationToken : '',
          );
          isLoading.value = false;
        }
      }
    })();
    onMounted(() => {
      if (props.index === 0 && root.value) {
        if (root.value.querySelector('input')) root.value.querySelector('input').focus();
        else if (root.value.querySelector('focus')) root.value.querySelector('focus').focus();
      }
    });
    return {
      // data
      isLoading,
      myQuestion,
      selected_date,
      selected_hour,
      show_other_input,
      address_error,
      center_marker,
      marker,
      file,
      loading_file,
      error_file,
      format,
      data_from_url,
      root,
      // computed
      minDatetime,
      maxDatetime,
      // Methods
      questionTitle,
      onInput,
      onEnter,
      selectDate,
      selectHour,
      onInputAdress,
      handlerAddMarker,
      fileValidations,
      handlerViewFileUploaded,
    };
  },
};
</script>
<style lang="sass" scoped>
:deep(.is-disabled)
  color: #000 !important
  opacity: 0.9 !important
:deep(.select) select[disabled]
  color: #000
  border: 1px solid #dbdbdb
:deep(.input):disabled,
:deep(.input[disabled])
  color: #000 !important
  border: 1px solid #dbdbdb
</style>

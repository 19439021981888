export default [
  'fullScreen',
  'full-screen',
  'scroll',
  'active',
  'animation',
  'ariaLabel',
  'aria-label',
  'ariaModal',
  'aria-modal',
  'aria-role',
  'autoFocus',
  'auto-focus',
  'canCancel',
  'can-cancel',
  'close-icon',
  'close-icon-size',
  'component',
  'content',
  'destroyOnHide',
  'events',
  'fullScreen',
  'full-screen',
  'mobile-breakpoint',
  'onCancel',
  'on-cancel',
  'onClose',
  'on-close',
  'override',
  'programmatic',
  'promise',
  'trapFocus',
  'trap-focus',
  'trapFocus',
];

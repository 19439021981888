<template>
  <ModalAddStages
    v-if="isModalAddStageOpen"
    v-model:is-open="isModalAddStageOpen"
    v-model:monthlyDetailsData="initialMonthlyDetailsData"
    :monthly-run-id="currentMonthlyRunId"
    @call:reload-table="reloadMonyhlyTable"
    @call:reload-monthly-details="handleReloadMonthlyDetails($event)"
  />
  <ModalUploadDocumentsMonthly
    v-if="isModalEditStageDocuments"
    v-model:is-open="isModalEditStageDocuments"
    :documents="documents"
    :api-upload-documents-id="apiUploadDocumentsId"
    @success="handleReloadMonthlyDetails($event)"
  />
  <BookMarks
    ref="MonthlyBookMarks"
    :api-url="apiUrl"
    class="bookmarks-montlhy-table"
    type="boxed"
    :loading="isLoading"
    :data="bookMarksItems"
    :params="filterParams"
    counter
    @update:item="selected"
  />
  <Table
    id="table-monthly"
    ref="tableMonthly"
    empty-message="No hay clientes con este estado"
    :per-page="8"
    :focusable="false"
    paginated
    :api-url="apiUrl"
    detailed
    :show-detail-icon="false"
    detail-key="id"
    :params="params"
  >
    <o-table-column
      v-if="false"
      v-slot="{ row }"
      sortable
      label="Mes anterior"
      field="month"
      sort-icon-size="large"
      width="100"
    >
      <TrafficLight
        :key="row?.id"
        :label="checkLastMonth(row?.last_month, 'date')"
        :last-month="checkLastMonth(row?.last_month)"
      />
      <div class="is-flex is-justify-content-center mt-2">
        <o-tooltip
          v-if="checkLastMonth(row?.last_month, 'icon') == 'USUARIO'"
          class="small-tooltip"
          label="Despacho"
          variant="black"
        >
          <o-icon icon="bank" size="small" />
        </o-tooltip>
        <o-tooltip
          v-if="checkLastMonth(row?.last_month, 'icon') == 'CLIENTE'"
          class="small-tooltip"
          label="Cliente"
          variant="black"
        >
          <o-icon icon="account-circle" size="small" variant="primary" />
        </o-tooltip>
      </div>
    </o-table-column>
    <o-table-column
      v-slot="{ row }"
      label="Mes"
      sortable
      field="current_month"
      sort-icon-size="large"
    >
      <div class="current-month">
        <p>
          {{ monthName(row.month) }}
        </p>
        <p>
          {{ row.fiscal_year }}
        </p>
      </div>
    </o-table-column>
    <o-table-column
      v-slot="{ row }"
      label="Cliente"
      sortable
      field="customer_name"
      sort-icon-size="large"
    >
      <div class="has-text-centered w-100">
        <p class="has-text-weight-semibold mb-2">
          {{ row.customer_name }}
        </p>
      </div>
    </o-table-column>
    <o-table-column v-slot="{ row }" label="Etapa actual" field="customer_name" width="150">
      <div class="tag-center has-text-centered w-100">
        <template v-if="preprocesor(row.stages, true)">
          <template v-for="(stage, index) in [preprocesor(row.stages, true)]" :key="index">
            <p class="has-text-weight-semibold mb-2">
              {{ stage.stage_name }}
            </p>
            <StatusTag
              class="w-100"
              :status-id="stage.stage_status_id"
              :label="getStatusNameByID(stage.stage_status_id)"
              :indicator="stage.indicator"
            />
          </template>
        </template>
        <p v-else class="has-text-weight-semibold">
          {{ row.monthly_run_status_id === 3 ? 'Etapas concluidas' : '-' }}
        </p>
      </div>
    </o-table-column>
    <o-table-column
      v-slot="{ row }"
      label="Supervisor"
      sortable
      field="supervisor_customer_associate_name"
      sort-icon-size="large"
    >
      <div class="tag-center has-text-centered w-100">
        <p class="mb-2 is-uppercase">
          {{ formatResponsibles(row.supervisor_customer_associate_name) }}
        </p>
        <Tag rounded class="mx-auto f-xs" :label="row.service_name" :variant="['info']" />
      </div>
    </o-table-column>
    <o-table-column
      v-slot="{ row }"
      label="Estado de las etapas principales"
      field="customer_name"
      sort-icon-size="large"
    >
      <div class="stage-container">
        <StatusTag
          v-for="{ stage_id, stage_status_id, stage_name, stage_indicator } in preprocesor(
            row.main_stages,
          )"
          :key="`${row.id}-${stage_id}`"
          :label="getStatusNameByID(stage_status_id)"
          :status-id="stage_status_id"
          :tag-tittle="stage_name"
          :indicator="stage_indicator"
        />
      </div>
    </o-table-column>
    <o-table-column
      v-slot="{ row }"
      sortable
      label="Estado"
      field="monthly_run_status_id"
      sort-icon-size="large"
    >
      <p class="has-text-weight-semibold has-text-centered">
        {{ row.monthly_run_status_name }}
      </p>
    </o-table-column>
    <o-table-column v-slot="{ row }" label="" field="details">
      <div class="link" @click="toggleDetails(row)">
        <o-icon pack="mdi" :icon="row.isOpen ? 'chevron-up' : 'chevron-down'" size="14px" />
      </div>
    </o-table-column>
    <template #detail="props">
      <MontlyDetails
        v-model:monthly-run-id="props.id"
        :monthly-run-to-reload="monthlyRunToReload"
        @add-stage="onModalOpen"
        @edit-stage="onEditMonthlyDetails"
        @on-modal-open-edit-documents="onModalOpenEditDocuments"
      />
    </template>
  </Table>
</template>
<script>
import { ref, toRefs, watch, onMounted, computed, nextTick } from 'vue';
import { BookMarks, StatusTag, Table, Tag, TrafficLight, ModalAddStages } from '@/components';
import ModalUploadDocumentsMonthly from '@/components/modals/ModalUploadDocumentsMonthly.vue';
import MontlyDetails from '@/components/MontlyDetails.vue';
import moment from 'moment';
import { useComponentUtils } from '@/components/conf/composables';
import { useContabilidad } from '@/utils/composables';

export default {
  components: {
    BookMarks,
    ModalUploadDocumentsMonthly,
    MontlyDetails,
    StatusTag,
    Table,
    Tag,
    TrafficLight,
    ModalAddStages,
  },
  inheritAttrs: false,
  props: {
    loading: { type: Boolean, default: false },
    filterParams: { type: Array, default: () => [] },
  },
  emits: ['update:loading'],
  setup(props, { emit }) {
    const MonthlyBookMarks = ref(null);
    const { loading, filterParams } = toRefs(props);
    const isLoading = ref(props.loading);
    const apiUrl = ref('monthly-run');
    const tableMonthly = ref(null);
    const isModalAddStageOpen = ref(false);
    const isModalEditStageDocuments = ref(false);
    const initialMonthlyDetailsData = ref(null);
    const monthlyRunToReload = ref(null);
    const { getStatusNameByID, INDICATOR } = useContabilidad();
    const handleReloadMonthlyDetails = (id) => {
      monthlyRunToReload.value = id;
      nextTick(() => {
        monthlyRunToReload.value = null;
      });
    };
    const checkLastMonth = (data, operation = '') => {
      if (operation == 'date') {
        if (!data) return null;
        const lastMonth = JSON.parse(data);
        return monthName(lastMonth.month);
      }
      if (operation == 'icon') {
        if (!data) return null;
        const lastMonth = JSON.parse(data);
        if (lastMonth?.monthly_run_indicator == 'VERDE') return null;
        return lastMonth?.responsible_entity_indicator?.responsible_entity;
      }
      return data ? JSON.parse(data) : null;
    };
    const { table } = useComponentUtils();
    const bookMarksItems = ref([
      { label: 'Ver todos', param: null, icon: 'format-list-bulleted' },
      { label: 'En orden', param: INDICATOR.VERDE.param, icon: 'check-circle' },
      { label: 'Necesita atención', param: INDICATOR.AMARILLO.param, icon: 'pause-circle' },
      { label: 'Detenido', param: INDICATOR.ROJO.param, icon: 'alert-octagon' },
    ]);
    const bookMarksItemSelected = ref(null);
    const bookMarksParams = ref([]);
    const currentMonthlyRunId = ref();
    const documents = ref([]);
    const apiUploadDocumentsId = ref({});

    const toggleDetails = (row) => {
      if (tableMonthly.value) {
        row.isOpen = !row.isOpen;
        tableMonthly.value.oTable.toggleDetails(row);
      }
    };
    const formatResponsibles = (names) => {
      if (!names) return '-';
      const responsibles = names.split(',').map((n) => n.trim());
      return responsibles.join(' • ');
    };
    const selected = (value) => (bookMarksItemSelected.value = value);
    const monthName = (numberMonth) =>
      moment()
        .month(numberMonth - 1)
        .format('MMM')
        .toUpperCase()
        .substring(0, 3);
    const preprocesor = (value, firstValue = false) => {
      if (!value) return [];
      const data = JSON.parse(value);
      if (firstValue && data.length) return data[0];
      else return data.length ? data : false;
    };
    const reloadBookMarks = () => {
      if (MonthlyBookMarks.value) MonthlyBookMarks.value.reload();
    };
    const onModalOpen = (id) => {
      currentMonthlyRunId.value = id;
      isModalAddStageOpen.value = true;
    };
    const onEditMonthlyDetails = (id, monthlyDetailsData) => {
      initialMonthlyDetailsData.value = monthlyDetailsData;
      onModalOpen(id);
    };
    const onModalOpenEditDocuments = (_documents, _apiUploadDocuments) => {
      documents.value = _documents;
      apiUploadDocumentsId.value = _apiUploadDocuments;
      isModalEditStageDocuments.value = true;
    };
    const reloadMonyhlyTable = () => {
      if (tableMonthly.value) tableMonthly.value.reload();
    };

    const params = computed(() => {
      let oParams = [...filterParams.value];
      if (bookMarksItemSelected.value && bookMarksItemSelected.value.param)
        oParams.push(bookMarksItemSelected.value.param);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      bookMarksParams.value = [...filterParams.value];
      return oParams;
    });

    watch(loading, (value) => (isLoading.value = value));
    watch(isLoading, (value) => {
      emit('update:loading', value);
    });
    watch(isModalAddStageOpen, (value) => {
      if (!value) {
        currentMonthlyRunId.value = null;
        initialMonthlyDetailsData.value = null;
      }
    });
    onMounted(() => {
      if (tableMonthly.value) table.value = tableMonthly.value;
      bookMarksParams.value = [...props.filterParams];
    });
    return {
      apiUploadDocumentsId,
      apiUrl,
      bookMarksItems,
      bookMarksParams,
      currentMonthlyRunId,
      documents,
      formatResponsibles,
      getStatusNameByID,
      isLoading,
      isModalAddStageOpen,
      isModalEditStageDocuments,
      MonthlyBookMarks,
      table,
      tableMonthly,
      toggleDetails,
      initialMonthlyDetailsData,
      monthlyRunToReload,

      // computed
      params,

      // Method
      onEditMonthlyDetails,
      selected,
      monthName,
      preprocesor,
      reloadBookMarks,
      onModalOpen,
      reloadMonyhlyTable,
      onModalOpenEditDocuments,
      handleReloadMonthlyDetails,
      checkLastMonth,
    };
  },
};
</script>
<style lang="sass" scoped>
.bookmarks-montlhy-table :deep(.tabs)
  div:nth-child(2)
    border-color: $primary
  div:nth-child(3)
    border-color: $color-bright-sun
  div:nth-child(4)
    border-color: $color-radical-red
#table-monthly
  .small-tooltip :deep(.tooltip-content)
    font-size: $f-xs
    padding-inline: 8px
  :deep(.table)
    border-top: none
    thead tr th
      padding: 13.4px 0 !important
      vertical-align: middle
      &:first-child
        max-width: 100px
        > span
          margin-left: 14px
          white-space: normal
          justify-content: flex-start
    tbody tr
      &.detail
        z-index: 0
        td
          background-color:  inherit !important
      td
        padding: 6px 4px
        &:first-child
          padding-left: 8px
        &:nth-child(4)
          @include from($bp-lg)
            max-width: 250px
          @include from($bp-xxl)
            max-width: 340px
        &:last-child
          padding-right: 16px
        .current-month
          font-weight: 500
          font-size: 16px
          color: #000000
          text-align: center
        .status-tag
          &.w-100 .tag
            width: 90%
    tbody tr.detail td
      padding-top: 32px
  :deep(.traffic-light) > p
      margin-top: 8px
  .stage-container
    padding: 4px 0
    max-height: 185px
    overflow-y: auto
    display: grid
    grid-template-columns: repeat(2, auto)
    grid-auto-rows: 1fr
    row-gap: 8px
    column-gap: 8px
  .tag-center :deep(.status-tag) .tag
      margin: auto
</style>
<style lang="sass">
.link:hover
  cursor: pointer
</style>

/* eslint-disable no-unused-vars */
import { createRouter, createWebHashHistory } from 'vue-router';
import aRoutes from './routes';
import { serviceName } from '@/config/constants';
import { useAuthStore, useNavigationStore } from '@/store';
import { Permissions, PermissionValidator } from '@/utils/Secure';
const AppName = process.env.VUE_APP_TITLE;
const routes = [
  {
    path: '/dinamic-form-viewer',
    name: 'DFV',
    meta: {
      title: 'Example of DFV',
      secure: { permission: null, redirect: null },
    },
    component: () =>
      import(/* webpackChunkName: "FormularioDinamico" */ '../views/DinamicFormViewerView'),
  },
  {
    path: '/',
    name: 'home',
    meta: { title: 'Home', secure: { permission: Permissions.MonthlyRun.ReadAll, redirect: null } },
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/HomeView'),
  },
  {
    path: '/etapas',
    name: 'stages',
    meta: { title: 'etapas', secure: { permission: null, redirect: null } },
    component: () => import(/* webpackChunkName: "Etapas" */ '../views/StageView'),
  },
  {
    path: '/auditorias',
    name: 'auditorias',
    meta: {
      title: 'Auditorias',
      service: serviceName.AUDITORIA,
      secure: { permission: Permissions.Auditing.ReadAll, redirect: null },
    },
    component: () =>
      import(/* webpackChunkName: "Auditoria" */ '../views/AuditoriasView/index.vue'),
  },
  {
    path: '/auditorias/:auditingId(\\d+)',
    name: 'AuditingConcepts',
    meta: {
      title: 'Auditorias',
      service: serviceName.AUDITORIA,
      secure: { permission: Permissions.Auditing.ReadAll, redirect: null },
    },
    component: () =>
      import(/* webpackChunkName: "Auditoria conceptos" */ '../views/AuditoriasView/index.vue'),
  },
  {
    path: '/auditorias/:auditingId(\\d+)/conceptos/:conceptoId(\\d+)/procedimiento',
    name: 'AuditingOperations',
    meta: {
      title: 'Auditorias',
      service: serviceName.AUDITORIA,
      secure: { permission: Permissions.Auditing.ReadAll, redirect: null },
    },
    component: () =>
      import(/* webpackChunkName: "Auditoria operaciones" */ '../views/AuditoriasView/index.vue'),
  },
  {
    path: '/facturacion',
    name: 'Facturacion',
    meta: {
      title: 'Facturación',
      secure: { permission: Permissions.Bills.ReadAll, redirect: null, notAllowClient: true },
    },
    component: () => import(/* webpackChunkName: "Facturacion" */ '../views/Facturacion.vue'),
  },
  {
    path: '/facturacion/:billsId',
    name: 'FacturacionDetalle',
    meta: {
      title: 'Detalle de facturación',
      secure: { permission: Permissions.ClientsBills.ReadAll, redirect: null },
    },
    component: () => import(/* webpackChunkName: "Facturacion" */ '../views/Facturacion.vue'),
  },
  {
    path: '/lista-usuarios',
    name: 'lista-usuarios',
    meta: {
      title: 'lista usuarios',
      secure: { permission: Permissions.Users.ReadAll, redirect: { name: '403' } },
    },
    component: () => import(/* webpackChunkName: "lista-usuarios" */ '../views/UserListView'),
  },
  {
    path: '/lista-clientes',
    name: 'lista-clientes',
    meta: {
      title: 'lista clientes',
      secure: { permission: Permissions.Clients.ReadAll, redirect: { name: '403' } },
    },
    component: () => import(/* webpackChunkName: "lista-clientes" */ '../views/ClientCrudView'),
  },
  {
    path: '/lista-clientes/corrida-mensual/:id',
    name: 'corrida-mensual',
    meta: {
      title: 'lista clientes',
      secure: { permission: Permissions.Clients.ReadAll, redirect: { name: '403' } },
    },
    component: () => import(/* webpackChunkName: "lista-clientes" */ '../views/CorridaMensualView'),
  },
  ...aRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: { isPublic: true, secure: { permission: null, redirect: null } },
    component: () => import(/* webpackChunkName: "404" */ '../views/Errors/404'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '403',
    meta: { isPublic: true, secure: { permission: null, redirect: null } },
    component: () => import(/* webpackChunkName: "403" */ '../views/Errors/403'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { setLastRoute } = useNavigationStore();
  const { hasAuth, checkAUTH, getAUTH, getClientServicesName } = useAuthStore();
  setLastRoute(from);
  checkAUTH();

  const { title, isPublic, secure, service } = to.meta;
  const isClient = getAUTH?.role === 'Cliente';
  const hasAssociateService = isClient && service && !getClientServicesName(service);
  const isClientNotAllowed = isClient && secure?.notAllowClient;

  const isLogin = to.name && to.name.toLocaleLowerCase() === 'login';
  const isAllowed = PermissionValidator(secure?.permission);
  if (to.name === '403' || (to.name === '404' && hasAuth)) to.meta.isPublic = false;

  document.title = title ? `${title} - ${AppName}` : AppName;

  if (hasAuth && isLogin && getAUTH?.role === 'Auditor') next('/auditorias');
  else if (hasAuth && isLogin) next('/');

  if (isClientNotAllowed || hasAssociateService) next({ name: '403' });
  else if (hasAuth && !isAllowed)
    next(secure?.redirect || getAUTH?.role === 'Auditor' ? '/auditorias' : '/');
  else if (isPublic || hasAuth) next();
  else next('/login');
});

export default router;

<template>
  <Modal
    id="addHoursModal"
    v-model:is-open="isModalActive"
    header="Capturar horas"
    button-label="Añadir horas"
    :loading="loading"
    :save-disabled="disabled"
    @save="saveHours"
    @cancel="resetForm"
  >
    <form ref="form" @submit.prevent="saveHours">
      <Field label="Usuario" required>
        <Radio
          v-if="isResponsible || isAdmin"
          v-model="responsableSelected"
          class="is-inline-block mr-2"
          label="Supervisor"
          name="responsable"
          :disabled="loading.save"
          native-value="SUPERVISOR"
        />
        <Radio
          v-model="responsableSelected"
          class="is-inline-block ml-2"
          label="Auditor"
          name="responsable"
          :disabled="loading.save"
          native-value="ASISTENTE"
        />
      </Field>
      <TimePicker
        v-model="hoursWorked"
        hour-format="24"
        placeholder="10:00"
        :disabled="loading.save"
        label="Horas a añadir"
        :max-time="maxDate"
        required
      />
      <p class="text-danger mt-0">Límite de captura de 20 horas</p>
    </form>
  </Modal>
</template>
<script>
import { Modal, Field, Radio, TimePicker } from '@/components';
import useDialog from '@/utils/composables/useDialog';
import { useAuthStore } from '@/store';
import { useComponentUtils } from '@/components/conf/composables';
import { ref, watch, toRefs, reactive, computed, getCurrentInstance, onBeforeMount } from 'vue';

import moment from 'moment';
export default {
  components: {
    Modal,
    Field,
    Radio,
    TimePicker,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    reload: { type: Function, default: null },
    isResponsible: { type: Boolean, default: false },
    id: { type: [Number, String], default: null },
    operation: { type: Object, default: () => ({}) },
  },
  emits: ['update:active', 'update:is-open', 'success:add'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { Notify } = useDialog();
    const isModalActive = ref(props.isOpen);
    const isOpen = toRefs(props).isOpen;
    const loading = reactive({ save: false });
    const hoursWorked = ref(undefined);
    const responsableSelected = ref('ASISTENTE');
    const form = ref(null);
    const { roles } = useComponentUtils();
    const { getAUTH } = useAuthStore();
    const userRole = getAUTH.role;

    const isAdmin = computed(() => roles.Administrador === userRole);
    const disabled = computed(() => {
      const initialHours = new Date();
      initialHours.setHours(0);
      initialHours.setMinutes(0);
      return (
        loading.save ||
        !hoursWorked.value ||
        !responsableSelected.value ||
        (initialHours.getHours() == hoursWorked.value.getHours() &&
          initialHours.getMinutes() == hoursWorked.value.getMinutes())
      );
    });

    const validate = () => {
      let htmlValidator = false;
      if (form.value) {
        htmlValidator = form.value.checkValidity();
        if (!htmlValidator) form.value.reportValidity();
      }
      return htmlValidator;
    };
    const saveHours = async () => {
      if (!validate()) return;
      loading.save = true;
      try {
        const { id } = props.operation;
        const { data } = await Api.post(`/operation/${id}/accumulated-time`, {
          executor_rol: responsableSelected.value,
          time: moment(hoursWorked.value).format('HH:mm:ss'),
          executor_user_id: getAUTH.id,
        });
        emit('success:add', data);
        if (props.reload) await props.reload(false);
        Notify('success', 'Horas agregadas al procedimiento');
        isModalActive.value = false;
      } catch (error) {
        console.log(error);
      }
      loading.save = false;
    };
    const resetForm = () => {
      hoursWorked.value = null;
      responsableSelected.value = null;
    };

    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      if (!value) resetForm();
    });
    onBeforeMount(() => {
      const currentHour = new Date();
      currentHour.setHours(0);
      currentHour.setMinutes(0);
      hoursWorked.value = currentHour;
    });
    return {
      isAdmin,
      hoursWorked,
      loading,
      isModalActive,
      saveHours,
      disabled,
      responsableSelected,
      resetForm,
      form,
      maxDate: computed(() => {
        const _maxdate = new Date();
        _maxdate.setHours(19);
        _maxdate.setMinutes(59);
        return _maxdate;
      }),
    };
  },
};
</script>
<style lang="sass" scoped>
#addHoursModal
  :deep(.animation-content)
    max-width: 342px !important
    .card-content
      overflow: unset
      padding-bottom: 20px
      .text-danger
        margin-top: 10px
        margin-bottom: 16px
        color: $color-radical-red
        font-size: $f-xss
</style>

import Api from '@/utils/Api.js';
import axios from 'axios';
const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};
// eslint-disable-next-line no-unused-vars
const prepareUploadPresigned = async ({ API = null, fileName, apiUrl }) => {
  const { data } = await (API || Api).post(apiUrl, {
    file_name: typeof fileName == 'string' ? fileName : { ...fileName },
  });
  if (data.length) return data[0];
  // Borrar .url cuando se corriga en back
  else return data.fields ? data : data.url;
};
const savePrepareForm = ({ fields, file, base64image }) => {
  let formData = new FormData();
  const oFile = file ? file : dataURItoBlob(base64image);
  formData.append('key', fields.key);
  formData.append('AWSAccessKeyId', fields.AWSAccessKeyId);
  formData.append('x-amz-security-token', fields['x-amz-security-token']);
  formData.append('policy', fields.policy);
  formData.append('signature', fields.signature);
  formData.append('file', oFile);
  return formData;
};
const upload = async ({ base64image, file, fields, url }) => {
  const body = savePrepareForm({ fields, file, base64image });
  const ax = axios.create();
  return await ax.post(url, body);
};

const uploadFile = async ({
  API,
  base64image,
  file,
  fileName,
  apiUrl,
  validation = async () => true,
}) => {
  if ((base64image || file) && fileName && apiUrl) {
    const presignedResult = await prepareUploadPresigned({ API, fileName, apiUrl });
    const { fields, url } = presignedResult;
    const isValid = await validation(presignedResult);
    if (isValid) await upload({ base64image, file, fields, url });
    return presignedResult;
  }
};
const uploadMultipleFiles = async ({ API, base64image, files, fileNames, apiUrl }) => {
  if ((base64image || files) && fileNames && apiUrl) {
    const presignedResult = await prepareUploadPresigned({ API, fileName: fileNames, apiUrl });
    const presignedResultKeys = Object.keys(presignedResult);
    presignedResultKeys.forEach(async (key) => {
      const { fields, url } = presignedResult[key];
      await upload({ base64image, file: files[key], fields, url });
    });
  }
};

const downloadFile = async ({ fileName, apiUrl, API = null }) => {
  const { data } = await (API || Api).post(apiUrl, { file_name: fileName });
  return data.url;
};
export { downloadFile, uploadFile, uploadMultipleFiles };

<template>
  <Modal
    id="ModalAuditoria"
    v-model:is-open="isModalActive"
    :header="header"
    :is-disabled="loading.save"
    :save-disabled="disabled || !isEdited"
    :loading="loading"
    :button-label="buttonConfirm"
    size="is-medium"
    button-label-cancel="Cerrar"
    @save="onSubmit"
  >
    <form ref="form">
      <Field v-if="!onEdit" class="mb-5" label="Tipo:">
        <Radio
          v-model="type"
          native-value="procedimiento"
          name="descrption-type"
          label="Procedimiento"
        />
        <Radio
          v-model="type"
          class="mr-3"
          native-value="titulo"
          name="descrption-type"
          label="Título"
        />
        <Radio
          v-model="type"
          class="mr-3"
          native-value="endTitle"
          name="descrption-type"
          label="Fin de segmento"
        />
      </Field>
      <div v-if="!isTitle" class="is-flex mb-5">
        <TimeTrackerField
          v-model="hours.supervisor.value"
          v-model:is-completed="hours.supervisor.completed"
          class="mr-5 mb-0"
          label="Horas planeadas supervisor:"
        />
        <TimeTrackerField
          v-model="hours.auditor.value"
          v-model:is-completed="hours.auditor.completed"
          class="mb-0 pl-3"
          label="Horas planeadas auditor:"
        />
      </div>
      <Field
        v-if="!isTitle"
        :class="hasTypeSelected ? 'mb-5' : ''"
        label="Tipos de archivos permitidos:"
      >
        <div class="types-container is-flex is-flex-wrap-wrap">
          <CheckBox
            v-for="(documentType, key) in tiposPermitidos"
            :key="key"
            v-model="documentType.selected"
            class="f-sm"
            :label="documentType.label"
            :disabled="documentType.disabled"
          />
        </div>
      </Field>
      <p v-if="!isTitle && !hasTypeSelected" class="has-text-danger mb-5 f-xs">
        Selecciona mínimo una opción
      </p>
      <Field
        v-if="type !== 'endTitle'"
        v-model="operationData.description"
        :label="fieldHandler.label"
        :type="fieldHandler.type"
        :placeholder="fieldHandler.placeholder"
        required
      />
    </form>
  </Modal>
</template>
<script>
import { toRefs, ref, watch, reactive, computed, getCurrentInstance, onMounted } from 'vue';
import { Modal, Field, Radio, TimeTrackerField, CheckBox } from '@/components';
import timestamp from '@/utils/timestamp';
import { useComponentUtils } from '@/components/conf/composables';
import useDialog from '@/utils/composables/useDialog';

export default {
  components: {
    Modal,
    Field,
    Radio,
    TimeTrackerField,
    CheckBox,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    data: {
      type: Object,
      default: () => ({
        status: 'CREADA',
        description: '',
        title: 0,
        planned_time_auditor: '',
        planned_time_supervisor: '',
      }),
    },
    conceptoId: { type: Number, default: null },
  },
  emits: [
    'update:active',
    'update:is-open',
    'update:comments',
    'update:observations',
    'update:data',
  ],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy.Api;
    const { table } = useComponentUtils();
    const form = ref(null);
    const { isOpen, data } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const loading = reactive({ save: false });
    const operationData = ref({ ...props.data });
    const tiposPermitidos = reactive({
      documento: {
        label: 'Documento (.doc, .docx, .odt)',
        types: ['.doc', '.docx', '.odt'],
        selected: false,
        disabled: false,
      },
      calculo: {
        label: 'Hoja de cálculo (.xls, .xlsx, .ods)',
        types: ['.xls', '.xlsx', '.ods'],
        selected: false,
        disabled: false,
      },
      pdf: { label: 'PDF (.pdf)', types: ['.pdf'], selected: false, disabled: false },
      todos: {
        label: 'Todos',
        types: ['.doc', '.docx', '.odt', '.xls', '.xlsx', '.ods', '.pdf'],
        selected: false,
        disabled: false,
      },
    });
    const selectedDocumentTypes = ref(data.value.document_types);
    const hours = reactive({
      supervisor: {
        value: operationData.value.planned_time_supervisor
          ? timestamp.format('h:m', operationData.value.planned_time_supervisor)
          : '',
        completed: false,
      },
      auditor: {
        value: operationData.value.planned_time_auditor
          ? timestamp.format('h:m', operationData.value.planned_time_auditor)
          : '',
        completed: false,
      },
    });
    const type = ref('procedimiento');
    const { Notify } = useDialog();

    const isTitle = computed(
      () => type.value == 'titulo' || type.value === 'endTitle' || operationData.value.title,
    );
    const fieldHandler = computed(() =>
      isTitle.value
        ? {
            label: 'Título para procedimientos:',
            placeholder: 'Escribe un título',
            type: 'text',
          }
        : {
            label: 'Descripción del procedimiento:',
            placeholder: 'Procedimiento a realizar',
            type: 'textarea',
          },
    );

    const checkSelectedDocumentTypes = () => {
      if (!selectedDocumentTypes.value) return;
      const selectedDocumentTypesArray = JSON.parse(selectedDocumentTypes.value);
      for (const key in tiposPermitidos) {
        if (key === 'todos') continue;
        if (tiposPermitidos[key].types.some((type) => selectedDocumentTypesArray.includes(type))) {
          tiposPermitidos[key].selected = true;
        }
      }
      tiposPermitidos.todos.types.every((type) => selectedDocumentTypesArray.includes(type))
        ? (tiposPermitidos.todos.selected = true)
        : (tiposPermitidos.todos.selected = false);
    };
    const validate = () => {
      let htmlValidator = false;
      if (form.value) {
        htmlValidator = form.value.checkValidity();
        if (!htmlValidator) form.value.reportValidity();
      }
      return htmlValidator;
    };
    const reload = async () => {
      if (table.value) {
        await table.value.reload(false);
      }
    };
    const fireToast = () => {
      const { description } = operationData.value;
      const dataNotification = {
        variant: 'success',
        title: '',
        message: '',
      };
      if (!isTitle.value) {
        dataNotification.title = 'Procedimiento creado';
        dataNotification.message = `El procedimiento ${description} se ha agregado`;
      } else if (description != 'is-end-title') {
        dataNotification.title = 'Título creado';
        dataNotification.message = `El título ${description} se ha agregado`;
      } else {
        dataNotification.title = 'Fin de segmento creado';
        dataNotification.message = `Fin de segmento agregado`;
      }

      let { variant, title, message } = dataNotification;
      if (onEdit.value) {
        title = title.replace(/(creado)/g, 'actualizado');
        message = message.replace(/(agregado)/g, 'actualizado');
      }
      Notify(variant, title, message);
    };

    const onSubmit = async () => {
      if (!validate()) return;
      loading.save = true;
      try {
        const { supervisor, auditor } = hours;
        const body = {
          description: operationData.value.description.length
            ? operationData.value.description
            : 'is-end-title',
          title: isTitle.value ? 1 : 0,
          status: isTitle.value ? 'FINALIZADA' : 'CREADA',
        };
        let typesSelected = [];
        if (!tiposPermitidos.todos.selected)
          for (const key in tiposPermitidos) {
            if (tiposPermitidos[key].selected && key != 'todos')
              typesSelected = [...tiposPermitidos[key].types, ...typesSelected];
          }
        else typesSelected = tiposPermitidos.todos.types;

        body.document_types = JSON.stringify(typesSelected);
        if (supervisor.completed) body.planned_time_supervisor = supervisor.value + ':00';
        if (auditor.completed) body.planned_time_auditor = auditor.value + ':00';
        if (onEdit.value) {
          delete body.title;
          delete body.status;
          await Api.put(`/concept/${props.conceptoId}/operation/${operationData.value.id}`, body);
        } else {
          await Api.post(`/concept/${props.conceptoId}/operation`, body);
        }
        await reload();
        fireToast();
        isModalActive.value = false;
      } catch (error) {
        console.log(error);
      }
      loading.save = false;
    };
    const onEdit = computed(() => operationData.value.id);
    const hasTypeSelected = computed(() => {
      return Object.keys(tiposPermitidos).some((t) => tiposPermitidos[t].selected);
    });
    const header = computed(() => (onEdit.value ? 'Editar' : 'Agregar'));
    const buttonConfirm = computed(() => (onEdit.value ? 'Guardar cambios' : 'Agregar'));
    const computedDocumentTypes = computed(() => {
      const selecteds = tiposPermitidos.todos.selected
        ? tiposPermitidos.todos.types
        : Object.keys(tiposPermitidos).reduce((acc, key) => {
            if (tiposPermitidos[key].selected) {
              acc.push(...tiposPermitidos[key].types);
            }
            return acc;
          }, []);
      return selecteds;
    });

    const isEdited = computed(() => {
      if (!selectedDocumentTypes.value) return true;
      const { description, planned_time_supervisor, planned_time_auditor } = operationData.value;
      const slicedSupervisorTime = planned_time_supervisor?.slice(0, 5);
      const slicedAuditorTime = planned_time_auditor?.slice(0, 5);
      const _computedDocumentTypes = computedDocumentTypes.value.slice().sort();
      const _selectedDocumentTypes = JSON.parse(selectedDocumentTypes.value).slice().sort();
      const isDocumentSelectionChanged =
        JSON.stringify(_computedDocumentTypes) !== JSON.stringify(_selectedDocumentTypes);
      const isTime = slicedSupervisorTime ? (slicedAuditorTime ? true : false) : false;
      if (isTime) {
        return (
          description !== data.value.description ||
          slicedSupervisorTime !== hours.supervisor.value ||
          slicedAuditorTime !== hours.auditor.value ||
          isDocumentSelectionChanged
        );
      } else {
        return (
          description !== data.value.description ||
          isDocumentSelectionChanged ||
          hours.supervisor.value.length ||
          hours.auditor.value.length
        );
      }
    });
    const deselectAllExceptTodos = () => {
      for (const type in tiposPermitidos) {
        if (type !== 'todos') {
          tiposPermitidos[type].selected = false;
          tiposPermitidos[type].disabled = true;
        }
      }
    };
    watch(type, () => {
      hours.supervisor.value = '';
      hours.auditor.value = '';
      operationData.value.description = '';
    });
    watch(
      data,
      (value) => {
        operationData.value = { ...value };
        checkSelectedDocumentTypes();
      },
      { immediate: true },
    );
    watch(
      () => tiposPermitidos.todos.selected,
      (value) => {
        if (value) {
          deselectAllExceptTodos();
        } else {
          for (const type in tiposPermitidos) {
            tiposPermitidos[type].disabled = false;
          }
        }
      },
    );
    watch(
      () =>
        Object.keys(tiposPermitidos)
          .filter((key) => key !== 'todos')
          .map((key) => tiposPermitidos[key].selected),
      (value) => {
        if (value.every((v) => v)) {
          tiposPermitidos.todos.selected = false;
          tiposPermitidos.todos.disabled = true;
        } else {
          tiposPermitidos.todos.disabled = false;
        }
      },
    );
    onMounted(() => {
      const allSelected = Object.keys(tiposPermitidos)
        .filter((key) => key !== 'todos')
        .map((key) => tiposPermitidos[key].selected);
      if (allSelected.every((v) => v)) {
        tiposPermitidos.todos.selected = true;
        tiposPermitidos.todos.disabled = false;
        deselectAllExceptTodos();
      }
    });
    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
    });
    return {
      form,
      hasTypeSelected,
      tiposPermitidos,
      buttonConfirm,
      header,
      onEdit,
      table,
      isModalActive,
      isTitle,
      loading,
      onSubmit,
      operationData,
      fieldHandler,
      type,
      hours,
      isEdited,
      disabled: computed(() => {
        const { supervisor, auditor } = hours;
        const hasHoursPlanned =
          (supervisor.value.length && !supervisor.completed) ||
          (auditor.value.length && !auditor.completed);
        return Boolean(
          (type.value !== 'endTitle' && !operationData.value.description.length) ||
            hasHoursPlanned ||
            (type.value === 'procedimiento' && !hasTypeSelected.value),
        );
      }),
    };
  },
};
</script>
<style lang="sass" scoped>
.types-container
  gap: 11px
</style>

<template>
  <div class="notification-container">
    <h3 class="subtitle-2 has-text-weight-bold">
      <o-icon :icon="cIcon" size="small" class="mr-1" />
      <span v-html="title"></span>
    </h3>
    <p v-if="message" class="notofocation-message mt-2" v-html="message"></p>
  </div>
</template>
<script>
import { computed, toRefs } from 'vue';
export default {
  props: {
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    variant: { type: String, default: 'primary' },
    icon: { type: String, default: '' },
  },
  setup(props) {
    const icon = toRefs(props).icon;

    const cIcon = computed(() => {
      switch (props.variant) {
        case 'success':
          return 'check-circle-outline';
        case 'info':
          return 'information-outline';
        case 'warning':
          return 'alert-outline';
        case 'danger':
          return 'close-circle-outline';
        default:
          return icon.value;
      }
    });
    return {
      cIcon,
    };
  },
};
</script>
<style lang="sass" scoped>
.notification-container
  .notofocation-message
    font-size: 13px
</style>

<template>
  <div class="modal-component">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">¿Estás seguro de cambiar la etapa de estado?</div>
      </div>
      <div class="card-content">
        <!-- eslint-disable-next-line -->
        <h1 v-html="message"></h1>
      </div>
      <div class="card-footer">
        <Button
          variant="danger"
          :disabled="isLoading"
          label="No"
          inverted
          @click.prevent="$emit('close', { action: 'no' })"
        />
        <Button
          variant="primary"
          :disabled="isLoading"
          :loading="isLoading"
          label="Si"
          @click.prevent="onChangeStatus"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from '@/components';
import { getCurrentInstance, ref } from 'vue';
import { useComponentUtils } from '@/components/conf/composables';
import useDialog from '@/utils/composables/useDialog';

export default {
  components: {
    Button,
  },
  props: {
    data: { type: [Array, Object], default: () => {} },
    message: { type: String, default: '' },
    stageStatusReload: { type: Function, default: null },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { table } = useComponentUtils();
    const isLoading = ref(false);
    const reload = async () => {
      if (table.value) {
        await table.value.reload(false);
      }
    };
    const { Notify } = useDialog();

    const onChangeStatus = async () => {
      let isSuccefullChange = false;
      isLoading.value = true;
      try {
        await Api.patch(
          `/monthly-run/${props.data.monthlyId}/monthly-run-details/${props.data.id}/change-status`,
          {
            monthly_run_stage_status_id: props.data.status_id,
          },
        );
        await reload();
        await props.stageStatusReload();
        isSuccefullChange = true;
        emit('close', { action: 'yes' });
      } catch (error) {
        console.log(error);
        if (!isSuccefullChange) {
          Notify('danger', 'No se logro actualizar el estado');
        }
        emit('close', { action: isSuccefullChange ? 'si' : 'no' });
      }
      isLoading.value = false;
    };
    return {
      isLoading,
      onChangeStatus,
      table,
    };
  },
};
</script>
<style lang="sass" scoped>
.modal-component
  z-index: 1000
  :deep(.animation-content)
    width: 100%
  .card
    color: $black
    border-radius: 5px
    width: 100%
    .card-header
      padding: 16px
      color: #FFFFFF
      background-color:  $primary
      .card-header-title
        color: white
        font-weight: 600
        padding: 0
        font-size: $f-sm
      :deep(.icon)
        margin: auto
        &:hover
          cursor: pointer
    .card-content
      padding: 40px 38px
    .card-footer
      display: flex
      justify-content: flex-end
      background-color: $grey-light
      padding: 17px 10px
      :deep(.button)
        margin-left: 10px
        padding: 0px 10px
        font-weight: 400
        height: 30px
        &.is-primary
          min-width: 88px
</style>

<template>
  <o-field class="file mb-0">
    <o-upload v-model="file" :accept="accept" @click="file = null">
      <o-button :disabled="loading || disabled" tag="a" variant="primary" expanded>
        <o-loading
          v-if="loading"
          v-model:active="loading"
          class="loading-file"
          :full-page="false"
        />
        <template v-else>
          <span class="has-text-weight-semibold f-sm">{{ oTitle }}</span>
          <o-icon icon="upload" />
        </template>
      </o-button>
    </o-upload>
  </o-field>
</template>
<script>
import { uploadFile } from '@/utils/Presigned';
import { computed, ref, watch, getCurrentInstance } from 'vue';
import useDialog from '@/utils/composables/useDialog';
import { useComponentUtils } from '@/components/conf/composables';
export default {
  props: {
    disabled: { type: Boolean, default: false },
    accept: { type: String, default: '' },
    autosave: { type: Boolean, default: true },
    label: { type: String, default: '' },
    title: { type: String, default: null },
    apiUrl: { type: String, default: '' },
    setFileNameByPatch: { type: String, default: '' },
    documentType: { type: String, default: null },
    fileName: { type: [Function, String], default: `Upload-${Math.round(Math.random() * 1000)}` },
    documentName: { type: String, default: null },
  },
  emits: ['update:file', 'success', 'fail'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const { Notify } = useDialog();
    const Api = proxy?.Api;
    const file = ref(null);
    const loading = ref(false);
    const { CleanText } = useComponentUtils();
    const save = async (_file) => {
      const { fileName, apiUrl, setFileNameByPatch, documentType, documentName } = props;
      const extension = _file.name.split('.')[1];
      const _cleanFileName = CleanText(_file.name);
      const _fileName = typeof props.fileName === 'function' ? fileName() : fileName;
      try {
        loading.value = true;
        if (setFileNameByPatch) {
          await uploadFile({
            fileName: documentName ? `${documentName}.${extension}` : _cleanFileName,
            apiUrl: apiUrl,
            file: file.value,
          });
          const body = {
            file_name: _cleanFileName,
          };

          if (documentType) body.type = documentType;
          if (documentName) {
            body.file_name = `${documentName}.${extension}`;
            body.document_name = documentName;
          }
          await Api.patch(setFileNameByPatch, body);
          Notify(
            'success',
            'Documento subido exitosamente',
            `El documento ${body.file_name} se subió.`,
          );
          emit('success', body.file_name);
        } else {
          await uploadFile({
            fileName: `${_fileName}.${extension}`,
            apiUrl: apiUrl,
            file: file.value,
          });
          Notify('success', 'Documento subido exitosamente', `El documento ${_fileName} se subió.`);
          emit('success', _fileName);
        }
        _file = null;
      } catch (error) {
        console.log(error);
        emit('fail');
      }
      loading.value = false;
    };
    const oTitle = computed(() => props.title ?? 'Subir archivo');
    watch(file, (_file) => {
      if (props.autosave && _file) save(_file);
      else emit('update:file', _file);
    });
    return { oTitle, file, loading };
  },
};
</script>
<style lang="sass" scoped>
.file
  width: 100%
  :deep(.upload) .button
    padding: 5px 16px
    .button-wrapper > span
      display: flex
      align-items: center
      > span
        margin-right: 8px
      .icon
        margin: 0
  :deep(.o-load).loading
    .o-load__overlay
      background: unset
</style>

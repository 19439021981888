<template>
  <section class="set-password-container">
    <div class="flex form-container">
      <form ref="form" @submit.prevent="onSubmit">
        <PasswordCreator
          label="Introduce tu nueva contraseña"
          @input="(value = '') => (password.new = value)"
        />
        <Field
          v-model="password.confirm"
          :custom-class="passwordsDontMatch ? 'is-danger' : ''"
          label="Confirma tu contraseña"
          :upper="false"
          icon="key"
          password-reveal
          required
          type="password"
          validation-message=" "
        />
        <div v-if="passwordsDontMatch" class="help is-danger validationMsg">
          Las contraseñas no coinciden
        </div>

        <Button
          v-if="!hideButton"
          class="submit-btn"
          variant="primary"
          label="Restablecer contraseña"
          native-type="submit"
          rounded
          :disabled="isDisabled"
          :loading="loading"
        />
      </form>
    </div>
  </section>
</template>
<script>
import { Field, PasswordCreator, Button } from '@/components';
import { computed, reactive, ref, getCurrentInstance, watch } from 'vue';
import { useAuthStore } from '@/store';
import useDialog from '@/utils/composables/useDialog';
import { useRouter, useRoute } from 'vue-router';

export default {
  components: {
    Button,
    PasswordCreator,
    Field,
  },
  props: {
    hideButton: { type: Boolean, default: false },
  },
  emits: ['button-disabled'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const router = useRouter();
    const route = useRoute();
    const { Notify } = useDialog();
    const { AUTH, Authorization: _Authorization } = useAuthStore();
    const password = reactive({ new: '', confirm: '' });
    const loading = ref(false);
    const Authorization = computed(
      () => _Authorization || token.value || new Date().toDateString(),
    );
    const isSomethingEmpty = computed(() => !password.new.length || !password.confirm.length);
    const passwordsDontMatch = computed(
      () => password.new !== password.confirm && password.new !== '' && password.confirm !== '',
    );
    const isDisabled = computed(() => Boolean(isSomethingEmpty.value || passwordsDontMatch.value));
    const token = computed(() => route.query.token);
    const userId = computed(() => AUTH?.id);
    const onSubmit = async () => {
      if (!passwordsDontMatch.value) await makeRequest();
    };

    const makeRequest = async () => {
      loading.value = true;
      try {
        const handleRequest = {
          url: 'recover-password-from-token',
          method: 'post',
          payload: { password: password.new },
        };
        let { url, method, payload } = handleRequest;
        if (userId.value) {
          url = `${userId.value}/change-password`;
          method = 'put';
        }

        await Api[method](`/users/${url}`, payload, {
          Authorization: Authorization.value,
        });
        if (!userId.value) {
          Notify(
            'success',
            'La contraseña ha sido reestablecida correctamente:',
            'Inicia sesión con tu <b>nueva contraseña</b>.',
            3000,
          );
          router.push('login');
        } else Notify('success', 'La contraseña ha sido modificada correctamente', '', 3000);
      } catch (error) {
        console.log(error);
      }
      loading.value = false;
    };

    watch(isDisabled, (value) => emit('button-disabled', value), { inmediate: true });
    return {
      password,
      loading,

      //computed
      Authorization,
      isDisabled,
      isSomethingEmpty,
      passwordsDontMatch,
      token,
      userId,

      //Methods
      onSubmit,
    };
  },
};
</script>
<style lang="sass" scoped>
.set-password-container
  border-radius: 10px
  max-width: 750px
  .validationMsg
    font-size: f-xss
    margin: -7px 0 5px 5px
  .field
    margin-bottom: 10px
</style>

import { defineStore } from 'pinia';

export const useNavigationStore = defineStore('useNavigationStore', {
  state: () => ({
    lastRoute: null,
  }),
  getters: {
    getLastRoute: (state) => state.lastRoute,
  },
  actions: {
    setLastRoute(state, lastRoute) {
      state.lastRoute = lastRoute;
    },
  },
});

<template>
  <div id="step3-aditional-data">
    <h2 class="has-text-weight-semibold mb-3">DETALLES ADICIONALES</h2>
    <article class="columns">
      <Field class="column is-field-radio" label="Ejecución de la etapa" required>
        <Radio
          v-model="is_omissible"
          v-bind="is_omissibleAttrs"
          native-value="1"
          name="ejecucion"
          label="Omisible"
        />
        <Radio
          v-model="is_omissible"
          v-bind="is_omissibleAttrs"
          native-value="0"
          name="ejecucion"
          label="Obligatoria"
        />
      </Field>
      <Field class="column is-field-radio" label="¿Agregar por defecto?" required>
        <Radio
          v-model="is_default"
          v-bind="is_defaultAttrs"
          native-value="1"
          name="default"
          label="Sí"
        />
        <Radio
          v-model="is_default"
          v-bind="is_defaultAttrs"
          native-value="0"
          name="default"
          label="No"
        />
      </Field>
      <TimeTrackerField
        v-model="estimated_time"
        v-bind="estimated_timeAttrs"
        :horizontal="false"
        class="column"
        label="Tiempo estimado"
      />
    </article>
    <article class="columns">
      <Field class="column is-field-radio" label="¿Es etapa principal?" required>
        <Radio v-model="main" v-bind="mainAttrs" native-value="1" name="main" label="Si" />
        <Radio v-model="main" v-bind="mainAttrs" native-value="0" name="main" label="No" />
      </Field>
      <Field class="column is-field-radio" label="¿Es visible al cliente?" required>
        <Radio
          v-model="visibility_level"
          v-bind="visibility_levelAttrs"
          native-value="VISIBLE"
          name="visible"
          label="Sí"
        />
        <Radio
          v-model="visibility_level"
          v-bind="visibility_levelAttrs"
          native-value="NO VISIBLE"
          name="visible"
          label="No"
        />
      </Field>
    </article>
    <Field
      v-model="description"
      v-bind="descriptionAttrs"
      label="AGREGAR DESCRIPCIÓN"
      type="textarea"
      maxlength="500"
      placeholder="Descripción de la etapa"
      required
    />
    <p class="counter-field">{{ textLength }} / 500</p>
  </div>
</template>
<script setup>
/* eslint-disable no-unused-vars */
import { Field, Radio, TimeTrackerField } from '@/components';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { schemas } from '@/utils/YupSchemas';
import { computed, watch, onMounted, ref } from 'vue';

const props = defineProps({
  form: { type: Object, default: () => {} },
  isStage: { type: Boolean, default: false },
  isEditable: { type: Boolean, default: false },
});
const isEdit = ref(props.isEditable);
const editRowFormData = ref(props.form);
const emit = defineEmits(['update:form', 'update:ready']);

const setInialValues = computed(() => {
  const { is_omissible, is_default, main, visibility_level, description, estimated_time } =
    props.form;
  return {
    is_omissible: is_omissible ? is_omissible : '1',
    is_default: is_default ? is_default : '1',
    main: main ? main : '1',
    visibility_level: visibility_level ? visibility_level : 'VISIBLE',
    description: description ? description : '',
    estimated_time: estimated_time ? estimated_time : '',
  };
});

const { defineField, handleSubmit, meta, values, schema } = useForm({
  initialValues: {
    ...setInialValues.value,
  },
  validationSchema: yup.object({
    is_omissible: schemas.yesNoRadioSchema,
    is_default: schemas.yesNoRadioSchema,
    main: schemas.yesNoRadioSchema,
    visibility_level: yup
      .string()
      .required('Completa este campo')
      .oneOf(['VISIBLE', 'NO VISIBLE'], 'Selección no válida'),
    description: yup.string().required('Completa este campo'),
  }),
});
const [is_omissible, is_omissibleAttrs] = defineField('is_omissible');
const [is_default, is_defaultAttrs] = defineField('is_default');
const [main, mainAttrs] = defineField('main');
const [visibility_level, visibility_levelAttrs] = defineField('visibility_level');
const [description, descriptionAttrs] = defineField('description');
const [estimated_time, estimated_timeAttrs] = defineField('estimated_time');

const onSumbit = handleSubmit((values) => emit('update:form', values));

const textLength = computed(() => (description.value ? description.value.length : 0));

watch(
  () => meta.value.valid,
  (value) => {
    emit('update:ready', value);
    if (value) onSumbit();
  },
  { immediate: true },
);

watch(
  values,
  (value) => {
    if (meta.value.valid) {
      onSumbit();
    }
  },
  { deep: true },
);
onMounted(async () => {
  if (isEdit.value) {
    if (editRowFormData.value.estimated_time.length === 7) {
      let slicedHour = editRowFormData.value.estimated_time.slice(0, -3);
      if (slicedHour.length === 4) slicedHour = `0 ${slicedHour}`;
      estimated_time.value = slicedHour;
    }
    is_omissible.value = editRowFormData.value.is_omissible;
    is_default.value = editRowFormData.value.is_default;
    main.value = editRowFormData.value.main;
    visibility_level.value = editRowFormData.value.visibility_level;
  }
});
</script>
<style lang="sass" scoped>
#step3-aditional-data
  color: $black
  .is-field-radio > :deep(.control) > .field.has-addons
    flex-direction: column
    gap: 8px
  .column :deep(.time-tracker-field) > .control > .field.has-addons
    width: fit-content
</style>

export default [
  'appendToBody',
  'append-to-body',
  'defaultMinutes',
  'default-minutes',
  'defaultSeconds',
  'default-seconds',
  'disabled',
  'editable',
  'enableSeconds',
  'enable-seconds',
  'expanded',
  'hourFormat',
  'hour-format',
  'icon',
  'iconPack',
  'icon-pack',
  'incrementHours',
  'increment-hours',
  'incrementMinutes',
  'increment-minutes',
  'incrementSeconds',
  'increment-seconds',
  'inline',
  'maxTime',
  'max-time',
  'maxlength',
  'minTime',
  'min-time',
  'mobileBreakpoint',
  'mobile-breakpoint',
  'mobile-native',
  'open-on-focus',
  'openOnFocus',
  'override',
  'placeholder',
  'position',
  'resetOnMeridianChange',
  'reset-on-meridian-change',
  'rounded',
  'size',
  'statusIcon',
  'status-icon',
  'timeCreator',
  'time-creator',
  'timeFormatter',
  'time-formatter',
  'time-parser',
  'timeParser',
  'unselectableTimes',
  'unselectable-times',
  'useHtml5Validation',
  'use-html5-validation',
  'validationMessage',
];

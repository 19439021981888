<template>
  <div id="app">
    <router-view v-if="isPublic" />
    <DefaultLayout v-else>
      <router-view />
    </DefaultLayout>
  </div>
</template>
<script>
import { useAuthStore } from '@/store/auth';
import { computed, ref, watch } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useRoute } from 'vue-router';
export default {
  components: {
    DefaultLayout,
  },
  setup() {
    const route = useRoute();
    const authStore = useAuthStore();
    const isPublic = ref(true);
    watch(route, (value) => {
      isPublic.value = value.meta.isPublic;
    });
    return {
      auth: computed(() => authStore.getAUTH),
      isPublic,
    };
  },
};
</script>

<template>
  <Modal
    id="endConclusion"
    v-model:is-open="isModalActive"
    header="Ingresa la conclusión del cierre de concepto"
    button-label="Terminar concepto"
    :is-disabled="loading.save"
    :save-disabled="!conlusion.trim().length"
    size="is-medium"
    :loading="loading"
    @save="sendRequest"
    @cancel="resetForm"
  >
    <form ref="form">
      <Field
        v-model="conlusion"
        type="textarea"
        label="Conclusion de concepto:"
        placeholder="Escribe la conclusión para dar cierre al concepto"
        required
        maxlength="500"
      />
      <p class="ml-auto counter-field">{{ textLength }} / 500</p>
    </form>
  </Modal>
</template>
<script>
import { Modal, Field } from '@/components';
import { useComponentUtils } from '@/components/conf/composables';
import useDialog from '@/utils/composables/useDialog';
import { watch, toRefs, ref, reactive, getCurrentInstance, computed } from 'vue';
export default {
  components: {
    Modal,
    Field,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    concept: { type: Object, default: null },
    auditingId: { type: Number, default: null },
  },
  emits: ['update:active', 'update:is-open', 'update:concept', 'success'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { Normalize } = useComponentUtils();
    const loading = reactive({ save: false });
    const { Notify } = useDialog();
    const { isOpen } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const conlusion = ref('');
    const form = ref(null);
    const validate = () => {
      let htmlValidator = false;
      if (form.value) {
        htmlValidator = form.value.checkValidity();
        if (!htmlValidator) form.value.reportValidity();
      }
      return htmlValidator;
    };
    const sendRequest = async () => {
      loading.save = true;
      try {
        if (!validate()) return;
        const { id, short_name } = props.concept;
        const conclusion = Normalize(conlusion.value, {
          replace: { find: ['\\n'], value: [' '] },
        });

        await Api.patch(`/auditing/${props.auditingId}/concept/${id}/prepared`);
        await Api.patch(`/auditing/${props.auditingId}/concept/${id}/approved`);
        await Api.patch(`/auditing/${props.auditingId}/concept/${id}/conclusion`, {
          conclusion,
        });
        await Api.patch(`/auditing/${props.auditingId}/concept/${id}/change-status`, {
          status: 'COMPLETADO',
        });
        emit('update:concept', {
          id,
          conclusion,
          status: 'COMPLETADO',
        });
        emit('success');
        isModalActive.value = false;
        Notify('success', `El concepto ${short_name} se ha marcado como terminado`);
      } catch (error) {
        console.log(error);
      }
      loading.save = false;
    };
    const resetForm = () => (conlusion.value = '');
    const textLength = computed(() => conlusion.value.length);
    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      if (!value) resetForm();
    });
    return { isModalActive, sendRequest, resetForm, loading, conlusion, textLength, form };
  },
};
</script>
<style lang="sass" scoped></style>

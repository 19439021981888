<template>
  <div v-if="!loading.isLoadStageStatus" class="headerMonthlyDetails">
    <Button
      v-if="isAdminAuditor"
      :permission="allowAddStage"
      class="mr-6"
      :disabled="loading.getStageDetails"
      icon-left="plus-circle"
      label="Agregar etapa"
      variant="primary"
      @click="$emit('add-stage', monthlyRunId)"
    />
  </div>
  <StageStatus
    v-if="monthlyRunId"
    ref="monthlyRunDetails"
    v-model:loading="loading.isLoadStageStatus"
    :monthly-run-id="monthlyRunId"
    :api-url="`monthly-run/${monthlyRunId}/monthly-run-details?sort=sequence`"
    :positive-negative-status="stageData?.positiveNegativeStatus"
    @update:selected="onSelect"
  />
  <div v-if="!loading.isLoadStageStatus" class="documents-reception">
    <hr />
    <header class="header-details is-flex is-align-content-center mb-4">
      <o-skeleton v-if="loading.getStageDetails" width="20%" />
      <h3 v-else class="fh-xs has-text-weight-semibold">
        <o-icon :icon="statusStageSelect.icon" size="small" :variant="statusStageSelect.variant" />
        {{ stageData?.stage_name }}
      </h3>

      <Button
        v-if="isAdminAuditor"
        :disabled="loading.getStageDetails"
        class="ml-auto mr-4"
        icon-left="pencil"
        label="Editar etapa"
        variant="primary"
        @click="$emit('edit-stage', monthlyRunId, stageData)"
      />
      <Button
        v-if="isAdminAuditor"
        :disabled="loading.getStageDetails"
        icon-left="power"
        :label="stageData?.is_active ? 'Desactivar etapa' : 'Activar etapa'"
        :variant="stageData?.is_active ? 'danger' : 'primary'"
        :inverted="stageData?.is_active > 0"
        @click="onChangeStatus"
      />
      <Select
        v-if="showPositiveNegaticeSelector()"
        :model-value="stageData?.positiveNegativeStatus?.value || positiveNegativeDefaultValue"
        class="ml-4 positiveNegativeSelector"
        label="Estado: "
        :data="opinionDataArray"
        @change="updatePositiveNegativeStatus($event.target.value)"
      />
    </header>
    <section>
      <h3 class="f-sm has-text-weight-semibold mb-2">Descripción de la etapa:</h3>
      <o-skeleton v-if="loading.getStageDetails" width="20%" />
      <template v-else>
        <p class="body-text">
          {{ stageDescription }}
        </p>
        <template v-if="hasDocuments">
          <h3 class="f-sm has-text-weight-semibold mb-3 mt-4">
            Documentos capturados: {{ documentsCaptured }}
          </h3>
          <div class="document-container">
            <div v-for="(document, key) in documents" :key="`doc-${key}`" class="document-handler">
              <o-tooltip
                :active="document.document_name.length >= 50"
                :label="document.document_name"
                variant="black"
              >
                <DownloadDocument
                  v-if="document.file_name"
                  :api-url="apiDocumentDownload"
                  icon-left="download"
                  :class="document.required ? 'is-required' : ''"
                  :file-name="document.file_name"
                  :cut-name="document.document_name.length >= 50"
                />
                <p v-else :class="`${document.required ? 'is-required' : ''}`">
                  {{
                    addExtensionToFileName(
                      formatName(document.document_name),
                      getFileMasksOnString(document.file_masks),
                    )
                  }}
                </p>
              </o-tooltip>
            </div>
          </div>
          <div class="actions-documents">
            <span class="is-required mr-2 f-xxs">Documento requerido</span>
            <Button
              label="Editar documentos"
              icon-left="pencil"
              variant="primary"
              @click="$emit('on-modal-open-edit-documents', documents, monthlyIds)"
            />
          </div>
        </template>
      </template>
      <hr />
      <h3 class="f-sm has-text-weight-semibold mb-3">Observaciones de la etapa</h3>
      <template v-if="loading.getStageDetails">
        <o-skeleton class="mb-2" width="90%" />
        <o-skeleton class="mb-2" width="90%" />
        <o-skeleton class="mb-2" width="90%" />
      </template>
      <section v-else class="overview-stage">
        <ol v-for="(coment, key) in observations" :key="key" :start="key + 1" class="coments">
          <li>
            {{ coment }}
          </li>
          <o-icon
            class="delete-coment"
            icon="delete-forever"
            @click="() => !loading.observations && onComment(key)"
          />
        </ol>
      </section>
      <Field class="addNewObs">
        <o-input
          v-model="newObservation"
          placeholder="Escribir observación"
          expanded
          :disabled="loading.getStageDetails"
          @keyup.enter="
            () =>
              newObservation && !loading.observations && newObservation.trim() != ''
                ? onComment()
                : ''
          "
        />
        <Button
          :disabled="!newObservation || loading.observations || newObservation.trim() == ''"
          :loading="loading.observations"
          variant="primary"
          label="Agregar observación"
          @click="onComment"
        />
      </Field>
    </section>
  </div>
</template>
<script>
import { Button, Field, DownloadDocument, Select } from '@/components';
import { StageStatus } from './';
import { getCurrentInstance, ref, computed, reactive, watch } from 'vue';
import { useComponentUtils } from '@/components/conf/composables';
import ModalConfirm from './modals/ModalConfirm.vue';
import useDialog from '@/utils/composables/useDialog';
import { useProgrammatic } from '@oruga-ui/oruga-next';
import { useAuthStore } from '@/store';
import { Permissions } from '@/utils/Secure';
import { useContabilidad, useStages } from '@/utils/composables';
const positiveNegativeDefaultValue = 'POR PROCESAR';
export default {
  components: {
    Button,
    DownloadDocument,
    Select,
    Field,
    StageStatus,
  },
  inheritAttrs: false,
  props: {
    monthlyRunId: { type: [String, Number], default: null },
    monthlyRunToReload: { type: Number, default: null },
  },
  emits: ['add-stage', 'edit-stage', 'on-modal-open-edit-documents'],
  setup(props) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { stageHandleStyle, getStatusNameByID } = useContabilidad();
    const { roles } = useComponentUtils();
    const { oruga } = useProgrammatic();
    const { Notify } = useDialog();
    const loading = reactive({
      getStageDetails: false,
      isChangeStatus: false,
      isLoadStageStatus: false,
      observations: false,
      isUploadingPositiveNegativeStatus: false,
    });
    const stageData = ref(null);
    const newObservation = ref('');
    const cancelToken = ref(null);
    const timeout = ref(null);
    const monthlyRunDetails = ref(null);
    const observations = ref([]);
    const { getAUTH } = useAuthStore();
    const userRole = getAUTH.role;
    const documents = ref([]);
    const { STAGETYPES, formatName, addExtensionToFileName } = useStages();

    const opinionDataArray = [
      { name: positiveNegativeDefaultValue, disabled: true },
      { name: STAGETYPES.positiveNegative.config.ROJO.positive_negative_value },
      { name: STAGETYPES.positiveNegative.config.VERDE.positive_negative_value },
    ];

    const cancelRequest = (message = 'Avoid multiple') => {
      if (cancelToken.value) cancelToken.value.cancel(message);
    };
    const reloadDetails = async () => {
      if (monthlyRunDetails.value) await monthlyRunDetails.value.reload(false);
      await getData(stageData.value.id);
    };
    const changeStatus = async () =>
      await Api.patch(
        `/monthly-run/${props.monthlyRunId}/monthly-run-details/${stageData.value.id}/${
          stageData.value.is_active ? 'disable' : 'enable'
        }`,
      );
    const getFileMasksOnString = (fileMasks) => {
      try {
        return eval(fileMasks)
          .map((ext) => ext.replace('*', ''))
          .join(', ');
      } catch (error) {
        return '';
      }
    };
    const promptModal = async () => {
      const { stage_name, is_active } = stageData.value;
      const instance = oruga.modal.open({
        component: ModalConfirm,
        canCancel: false,
        props: {
          title: `¿Estás seguro de ${is_active ? 'desactivar' : 'activar'} la etapa?`,
          message: `La etapa <b>${stage_name}</b> se va a <b>${
            is_active ? 'desactivar' : 'activar'
          }</b>`,
          forceReload: reloadDetails,
          action: changeStatus,
        },
        trapFocus: true,
      });
      return instance.promise;
    };
    const confirmDeleteObservation = async () => {
      const instance = oruga.modal.open({
        component: ModalConfirm,
        canCancel: false,
        props: {
          title: `¿Estás seguro de eliminar la observación?`,
          message: `La obsevación de la etapa <b>${stageData.value.stage_name}</b> sera eliminada.</b>`,
          needReloadTable: false,
        },
        trapFocus: true,
      });
      return instance.promise;
    };
    const getData = async (stageId) => {
      loading.getStageDetails = true;
      cancelToken.value = Api.cancelToken;
      try {
        let { data } = await Api.get(
          `/monthly-run/${props.monthlyRunId}/monthly-run-details/${stageId}`,
          {
            cancelToken: cancelToken.value.token,
          },
        );
        observations.value = JSON.parse(data.observations);

        const positiveNegativeStatus = {
          id: stageId,
          value: data.positive_negative_value,
        };
        stageData.value = { ...data, positiveNegativeStatus };

        documents.value = JSON.parse(data.documents).map((_document) => {
          return {
            ..._document,
            required: _document.required === 'true',
          };
        });
      } catch (error) {
        console.log(error);
      }
      loading.getStageDetails = false;
    };
    const onComment = async (index = false) => {
      loading.observations = true;
      try {
        let observationes = observations.value;
        if (typeof index === 'number') {
          const { action } = await confirmDeleteObservation();
          if (action !== 'yes') throw '';
          observationes = observations.value.filter((_, idx) => idx != index);
        } else {
          observationes = [...observations.value, newObservation.value];
          newObservation.value = '';
        }
        await Api.patch(
          `/monthly-run/${props.monthlyRunId}/monthly-run-details/${stageData.value.id}/observations`,
          { observations: JSON.stringify(observationes) },
        );
        observations.value = observationes;

        Notify(
          'success',
          `La observación ${typeof index === 'number' ? 'fue eliminada' : 'se ha agregado'}`,
        );
      } catch (error) {
        console.log(error);
      }
      loading.observations = false;
    };
    const onSelect = (stageId) => {
      stageData.value = null;
      cancelRequest();
      clearTimeout(timeout.value);
      timeout.value = setTimeout(() => {
        getData(stageId);
      }, 40);
    };
    const onChangeStatus = async () => {
      try {
        const { stage_name, is_active } = stageData.value;
        const modalEmit = await promptModal();
        if (modalEmit?.action === 'yes') {
          Notify(
            'success',
            'Procedimiento exitoso',
            `La etapa <b>${stage_name}</b> ha sido <b>${
              is_active ? 'activada' : 'desactivada'
            }</b>`,
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    const monthlyIds = computed(() => ({
      monthlyId: props.monthlyRunId,
      monthlyDetailsId: stageData.value.id,
    }));
    const stageDescription = computed(() => {
      const description = stageData.value?.stage_description;
      return description ? description : 'Sin descripción';
    });
    const allowAddStage = computed(() => Permissions.Stages.Create);
    const statusStageSelect = computed(() => {
      if (!stageData.value) return {};
      const { monthly_run_stage_status_id: statusId, indicator } = stageData.value;
      return { ...stageHandleStyle(statusId, indicator), ...getStatusNameByID(statusId) };
    });
    const isAdminAuditor = computed(
      () => userRole === roles.Administrador || userRole === roles.Auditor,
    );
    const apiDocumentDownload = computed(() => {
      if (!stageData.value) return '';
      const { monthly_run_id, id } = stageData.value;
      return `/monthly-run/${monthly_run_id}/monthly-run-details/${id}/presigned/download`;
    });
    const hasDocuments = computed(() => documents.value.length);
    const formatedDoc = (docName, fileMasks) => {
      return addExtensionToFileName(formatName(docName), getFileMasksOnString(fileMasks));
    };
    const documentsCaptured = computed(() => {
      if (hasDocuments.value) {
        const _documentsReady = documents.value.filter((doc) => doc.file_name).length;
        return `${_documentsReady} de ${documents.value.length}`;
      }
      return '';
    });
    const updatePositiveNegativeStatus = async (value) => {
      loading.isUploadingPositiveNegativeStatus = true;
      try {
        await Api.patch(
          `/monthly-run/${props.monthlyRunId}/monthly-run-details/${stageData.value.id}/positive-negative`,
          { positive_negative_value: value },
        );
        stageData.value.positiveNegativeStatus.value = value;
      } catch (error) {
        console.log(error);
      }
      loading.isUploadingPositiveNegativeStatus = false;
    };
    const showPositiveNegaticeSelector = () =>
      stageData.value?.stage_type_name === 'Positiva/Negativa' ||
      stageData.value?.stage_type_name === 'Positiva/Negativa/Fecha';
    watch(
      () => props.monthlyRunToReload,
      (newValue) => {
        if (newValue === props.monthlyRunId) reloadDetails();
      },
    );
    return {
      apiDocumentDownload,
      loading,
      stageData,
      monthlyRunDetails,
      newObservation,
      observations,
      documents,
      opinionDataArray,
      positiveNegativeDefaultValue,

      // methods
      onComment,
      onSelect,
      onChangeStatus,
      formatName,
      addExtensionToFileName,
      getFileMasksOnString,
      formatedDoc,
      showPositiveNegaticeSelector,
      updatePositiveNegativeStatus,

      //  Computed
      allowAddStage,
      documentsCaptured,
      hasDocuments,
      isAdminAuditor,
      monthlyIds,
      stageDescription,
      statusStageSelect,
    };
  },
};
</script>
<style lang="sass" scoped>
.documents-reception
  padding: 0 48px
  padding-bottom: 32px
  position: relative
  min-height: 80px
  .positiveNegativeSelector
    display: flex
    align-items: center
  .document-container
    display: flex
    flex-direction: row
    flex-wrap: wrap
    column-gap: 16px
    row-gap: 8px
    .document-handler
      :deep(.b-tooltip)
        align-items: center
        .tooltip-content
          width: 400px
          white-space: normal
          text-align: justify
  .is-required::after
    color: $color-radical-red
    content: '*'
  :deep(.o-load) .o-load__overlay
    background: unset
  .addNewObs
    :deep(.field.has-addons)
      display: flex
      .input
        height: 100%
    :deep(.button)
      min-width: 196.742px
  .overview-stage
    max-height: 196px
    overflow-y: auto
  .coments
    border-radius: 8px
    position: relative
    background-color: $color-blue-hues
    margin-bottom: 16px
    padding: 10px
    padding-left: 26px
    width: 100%
    max-width: calc(100% - 48px)
    font-size: 13px
    .delete-coment
      position: absolute
      right: -32px
      top: calc(50% - 12px)
      :deep(.mdi::before)
        color: $color-radical-red
        font-size: 20px
      &:hover
        cursor: pointer
      &:active :deep(.mdi::before)
        color: $color-radical-dark
.header-details, .actions-documents
  > :deep(.button)
    max-height: 32px
    .mdi::before
      font-size: 18px
hr
  height: 1px
  background: $grey-light
.headerMonthlyDetails
  display: flex
  justify-content: flex-end
  :deep(.button)
    height: 32px
    .mdi::before
      font-size: 16px
.actions-documents
  display: flex
  margin-top: 16px
  width: 100%
  justify-content: flex-end
  align-items: center
</style>

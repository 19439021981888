export default [
    'ariaLabelledby',
    'aria-labelled-by',
    'autocomplete',
    'falseValue',
    'false-value',
    'indeterminate',
    'name',
    'nativeValue',
    'native-value',
    'required',
    'size',
    'trueValue',
    'true-value',
    'useHtml5Validation',
    'use-html5-validation',
    'variant',
    'class',
  ];
  
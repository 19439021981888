<template>
  <Modal
    id="endConclusion"
    v-model:is-open="isModalActive"
    header="Solicitud de cierre de procedimiento"
    button-label="Solicitar cierre"
    :is-disabled="loading.save"
    :save-disabled="!conlusion.trim().length"
    size="is-medium"
    :loading="loading"
    @save="sendRequest"
    @cancel="resetForm"
  >
    <form ref="form">
      <Field
        v-model="conlusion"
        type="textarea"
        label="Conclusiones"
        placeholder="Escribe la conclusión para dar cierre al procedimiento"
        required
        maxlength="500"
      />
      <p class="ml-auto counter-field">{{ textLength }} / 500</p>
    </form>
  </Modal>
</template>
<script>
import { Modal, Field } from '@/components';
import { useAuthStore } from '@/store';
import moment from 'moment';
import { useComponentUtils } from '@/components/conf/composables';
import useDialog from '@/utils/composables/useDialog';
import { watch, toRefs, ref, reactive, getCurrentInstance, computed } from 'vue';
export default {
  components: {
    Modal,
    Field,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    operation: { type: Object, default: null },
  },
  emits: ['update:active', 'update:is-open', 'succes:request-end'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { getAUTH } = useAuthStore();
    const { Normalize } = useComponentUtils();
    const loading = reactive({ save: false });
    const { Notify } = useDialog();
    const { isOpen } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const conlusion = ref('');
    const form = ref(null);
    const validate = () => {
      let htmlValidator = false;
      if (form.value) {
        htmlValidator = form.value.checkValidity();
        if (!htmlValidator) form.value.reportValidity();
      }
      return htmlValidator;
    };
    const sendRequest = async () => {
      loading.save = true;
      try {
        if (!validate()) return;
        const { id: operationId, concept_id, conclusions } = props.operation;
        const { full_name, id } = getAUTH;
        const prepareConclusion = {
          user_id: id,
          user_name: full_name,
          message: Normalize(conlusion.value, {
            replace: { find: ['\\n'], value: [' '] },
          }),
          date: moment(new Date()).format('DD/MM/YYYY'),
          is_attended: 0,
        };
        const conclusionsUpdated = JSON.stringify([...JSON.parse(conclusions), prepareConclusion]);
        await Api.patch(`/concept/${concept_id}/operation/${operationId}/conclusions`, {
          conclusions: conclusionsUpdated,
        });

        emit('succes:request-end', {
          operationId,
          conclusionsUpdated,
        });
        isModalActive.value = false;
        Notify('success', 'Solicitud de cierre enviada');
      } catch (error) {
        console.log(error);
      }
      loading.save = false;
    };
    const resetForm = () => (conlusion.value = '');
    const textLength = computed(() => conlusion.value.length);
    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      if (!value) resetForm();
    });
    return { isModalActive, sendRequest, resetForm, loading, conlusion, textLength, form };
  },
};
</script>
<style lang="sass" scoped></style>

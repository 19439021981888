export default [
  'ariaCurrentLabel',
  'aria-current-label',
  'ariaNextLabel',
  'aria-next-label',
  'aria-page-label',
  'ariaPageLabel',
  'ariaPreviousLabel',
  'aria-previous-label',
  'bordered',
  'checkable',
  'checkboxPosition',
  'checkbox-position',
  'checkedRows',
  'checked-rows',
  'columns',
  'currentPage',
  'current-page',
  'customDetailRow',
  'custom-detail-row',
  'customIsChecked',
  'custom-is-checked',
  'customRowKey',
  'custom-row-key',
  'defaultSort',
  'default-sort',
  'detailIcon',
  'detail-icon',
  'detailKey',
  'detail-key',
  'detailTransition',
  'detail-transition',
  'detailed',
  'draggable',
  'draggable-column',
  'draggableColumn',
  'filtersEvent',
  'filters-event',
  'focusable',
  'has-detailed-visible',
  'headerCheckable',
  'header-checkable',
  'height',
  'hoverable',
  'icon-pack',
  'iconPack',
  'is-row-checkable',
  'isRowCheckable',
  'isRowSelectable',
  'is-row-selectable',
  'mobile-breakpoint',
  'mobileBreakpoint',
  'mobileCards',
  'mobile-cards',
  'mobile-sort-placeholder',
  'mobileSortPlaceholder',
  'narrowed',
  'openedDetailed',
  'opened-detailed',
  'override',
  'paginationPosition',
  'pagination-position',
  'rowClass',
  'row-class',
  'scrollable',
  'selected',
  'showDetailIcon',
  'show-detail-icon',
  'showHeader',
  'show-header',
  'sortIcon',
  'sort-icon',
  'sortIconSize',
  'sort-icon-size',
  'sticky-checkbox',
  'stickyCheckbox',
  'stickyHeader',
  'sticky-header',
  'striped',
  'total',
];

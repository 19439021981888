import { defineStore } from 'pinia';
import Api from '@/utils/Api.js';
import { enc, AES } from 'crypto-js';
import moment from 'moment';
import router from '@/router';
import jwt_decode from 'jwt-decode';
import { setCookie, getCookie, removeCookie } from '@/utils/Cookies.js';
import { setLocalStorage, getLocalStorage } from '@/utils/LocalStorage';
import { userRoles } from '@/config/constants';
import { user, client } from '@/utils/usersStates';

const COOKIE_ID = process.env.VUE_APP_COOKIE_ID || null;

const _encrypt = (data) => {
  if (typeof data === 'object') data = JSON.stringify(data);
  return AES.encrypt(data, 'xD').toString();
};
const _decrypt = (encrypted) => {
  if (encrypted) {
    const bytes = AES.decrypt(encrypted, 'xD');
    const data = bytes.toString(enc.Utf8);
    return JSON.parse(data);
  } else return null;
};

export const useAuthStore = defineStore('useAuthStore', {
  state: () => ({
    AUTH: _decrypt(getCookie(`${COOKIE_ID}_AH`)),
    clientServices: _decrypt(getLocalStorage(`${COOKIE_ID}_services`)),
    permissions: _decrypt(localStorage.getItem(`${COOKIE_ID}_pIds`)) || [],
    isDeauthing: false,
  }),
  getters: {
    getAUTH: (state) => state.AUTH,
    getClientServicesName: (state) => (serviceName) => {
      if (!state.clientServices) return null;
      const service = state.clientServices.find(
        (s) => s.service_name === serviceName && s.is_active,
      );
      return service && service.is_active ? service : null;
    },
    Authorization: (state) => state.AUTH && state.AUTH.Authorization,
    hasAuth: (state) => !!state.AUTH,
    Permissions: (state) => state.permissions,
  },
  actions: {
    async setDeAUTH({ redirect = true, allowSignOut = true } = {}) {
      if (this.isDeauthing) return;
      this.isDeauthing = true;
      try {
        if (allowSignOut) {
          if (this.authorization) Api.Authorization = this.authorization;
          await Api.delete(`users/signout`);
        }
      } catch (error) {
        if (error.status !== 403) return;
      }
      this.AUTH = null;
      sessionStorage.clear();
      localStorage.clear();
      removeCookie(`${COOKIE_ID}_AH`);
      Api.Authorization = null;
      this.isDeauthing = false;
      if (redirect) window.location.reload();
    },
    setAUTH(P, exp) {
      const data = {};
      (P.role === userRoles.CLIENTE ? client : user).forEach((value) => (data[value] = P[value]));
      setCookie(`${COOKIE_ID}_AH`, _encrypt(data), moment.unix(exp).toDate());
      this.AUTH = data;
      Api.Authorization = data.Authorization;
    },
    async setClientService() {
      const { customer_id, customer_associates_id } = this.AUTH;
      let { data } = await Api.get('clients/services');
      const {
        data: { associate_services },
      } = await Api.get(`clients/${customer_id}/associates/${customer_associates_id}`);
      const associateServices = JSON.parse(associate_services);
      data = data.map(({ service_name, is_active, service_id, id }) => ({
        service_name,
        is_active: is_active && associateServices.some((s) => s.name === service_name),
        service_id,
        id,
      }));
      setLocalStorage(`${COOKIE_ID}_services`, _encrypt(data));
      this.clientServices = data;
    },
    async onAUTH(AUTH_data) {
      try {
        this.setAUTH(AUTH_data);
        if (this.getAUTH.role == userRoles.CLIENTE) {
          await this.setClientService();
        } else {
          setLocalStorage(`${COOKIE_ID}_services`, _encrypt([]));
          this.clientServices = [];
        }
        await this.requestPermisions(AUTH_data);
        router.push({ name: 'home' });
      } catch (error) {
        this.deAUTH();
      }
    },
    checkAUTH() {
      const cookie = getCookie(`${COOKIE_ID}_AH`);
      if (!cookie && this.hasAuth) this.setDeAUTH();
      else if (cookie) {
        const currentDay = moment().unix();
        const deCookie = _decrypt(cookie);
        const { exp } = jwt_decode(deCookie.Authorization);
        if (currentDay > exp) this.deAUTH({ allowSignOut: false });
      }
    },
    uninstallServiceWorker() {
      if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (let registration of registrations) {
            registration.unregister();
          }
        });
      }
    },
    async deAUTH(params = {}) {
      this.uninstallServiceWorker();
      this.setDeAUTH(params);
      sessionStorage.clear();
      localStorage.clear();
      removeCookie(`${COOKIE_ID}_AH`);
      Api.Authorization = null;
      await new Promise((resolve) => setTimeout(() => resolve(), 5000));
    },
    async requestPermisions() {
      const { data } = await Api.get('/users/retrieve-routes?per_page=999');
      const perms = data.map((P) => ({ method: P.http_method, path: P.path_route }));
      this.permissions = perms;
      localStorage.setItem(`${COOKIE_ID}_pIds`, _encrypt(perms));
    },
  },
});

<template>
  <Transition name="fadeInLeft">
    <slot />
  </Transition>
</template>
<style lang="sass" scoped>
.fadeInLeft-enter-active
  animation: fadeInLeft 250ms ease-in
@keyframes fadeInLeft
    from
        opacity: 0
    to
        opacity: 1
</style>

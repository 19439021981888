import VueCookies from 'vue-cookies';

const COOKIE_ID = process.env.VUE_APP_COOKIE_ID || null;
const DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN || null;
const EXTERNAL = process.env.VUE_APP_EXTERNAL_COOKIE_NAME;

export const setCookie = (name, value, expiration) => {
  VueCookies.set(`${COOKIE_ID}_${name}`, value, expiration, null, DOMAIN, true);
};

export const getCookie = (name) => {
  const externalCookie = EXTERNAL && VueCookies.get(`${EXTERNAL}_${name}`);
  if (externalCookie) return externalCookie;
  else return VueCookies.get(`${COOKIE_ID}_${name}`);
};

export const removeCookie = (name) => {
  VueCookies.remove(`${COOKIE_ID}_${name}`, null, DOMAIN);
  EXTERNAL && VueCookies.remove(`${EXTERNAL}_${name}`, null, DOMAIN);
};

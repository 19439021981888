<template>
  <SlickList
    id="table-procedimientos"
    key="operation-view"
    v-model:list="listOperations"
    :hide-sortable-ghost="false"
    :lock-offset="['5%', '10%']"
    lock-to-container-edges
    tag="div"
    axis="y"
    lock-axis="y"
    group="operations"
    helper-class="is-tr-dragged"
    append-to="#tbody-drag-operation"
    :class="oLoading ? 'filter-loading' : ''"
    class="table-container drag-table-operation"
    @sort-end="onEndSort"
    @sort-start="onStartSort"
  >
    <div class="b-table">
      <div class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th style="width: 90px">
                <span>ESTADO</span>
              </th>
              <th style="width: 520px">
                <span>PROCEDIMIENTO</span>
              </th>
              <th style="width: 192px">
                <span>ACTUALIZADO</span>
              </th>
              <th style="width: 134px">
                <span>SUPERVISOR</span>
              </th>
              <th style="width: 134px">
                <span>AUDITOR</span>
              </th>
              <th style="width: 110px">
                <span>EFECTIVAS</span>
              </th>
              <th style="width: 40px">
                <span>DOCUMENTO</span>
              </th>
              <th style="width: 24px">
                <span></span>
              </th>
              <th style="width: 24px">
                <span></span>
              </th>
            </tr>
          </thead>
          <tbody id="tbody-drag-operation">
            <o-loading :active="oLoading" />
            <SlickItem
              v-for="(operation, idx) in listOperations"
              :key="operation.id"
              tag="tr"
              :index="idx"
              :class="operation.title ? 'is-title' : 'is-operation'"
              :disabled="oLoading"
            >
              <td data-label="ESTADO" style="width: 90px">
                <div class="is-flex is-justify-content-center is-align-items-center">
                  <p
                    v-if="!operation.title"
                    class="has-text-centered"
                    :class="operation.conclusion_request ? 'conclusion-request' : ''"
                  >
                    <o-tooltip class="status-info" :label="operation.status" variant="black">
                      <o-icon
                        class="status-icon"
                        :icon="statusIcon(operation.status)"
                        :variant="operation.status == 'FINALIZADA' ? 'primary' : 'light-grey'"
                      />
                    </o-tooltip>
                  </p>
                </div>
              </td>
              <td data-label="PROCEDIMIENTO" style="width: 520px">
                <div class="is-flex is-align-items-center">
                  <p :class="operation.title ? 'is-title-operation' : ''">
                    {{ operation.description === 'is-end-title' ? '' : operation.description }}
                  </p>
                </div>
              </td>
              <td data-label="ACTUALIZADO" style="width: 192px">
                <div class="is-flex is-justify-content-center is-align-items-center">
                  <p v-if="!operation.title" class="has-text-centered">
                    {{ formatLastUpdate(operation.last_updated) }}
                  </p>
                </div>
              </td>
              <td data-label="SUPERVISOR" style="width: 134px">
                <div class="is-flex is-justify-content-center is-align-items-center">
                  <template v-if="!operation.title">
                    <p v-if="operation.planned_time_supervisor" class="has-text-centered">
                      <span
                        :class="
                          isOutOfRange(
                            operation.responsible_time,
                            operation.planned_time_supervisor,
                          )
                            ? 'has-text-danger-dark has-text-weight-semibold'
                            : ''
                        "
                        >{{ accumulatedTime(operation.responsible_time) }}</span
                      >
                      /
                      <span>{{ accumulatedTime(operation.planned_time_supervisor) }}</span>
                      horas
                    </p>
                    <p v-else class="has-text-centered">
                      {{ accumulatedTime(operation.responsible_time) }} horas
                    </p>
                  </template>
                </div>
              </td>
              <td data-label="AUDITOR" style="width: 134px">
                <div class="is-flex is-justify-content-center is-align-items-center px-1">
                  <template v-if="!operation.title">
                    <p v-if="operation.planned_time_auditor" class="has-text-centered">
                      <span
                        :class="
                          isOutOfRange(operation.assistant_time, operation.planned_time_auditor)
                            ? 'has-text-danger-dark has-text-weight-semibold'
                            : ''
                        "
                        >{{ accumulatedTime(operation.assistant_time) }}</span
                      >
                      /
                      <span>{{ accumulatedTime(operation.planned_time_auditor) }}</span>
                      horas
                    </p>
                    <p v-else class="has-text-centered">
                      {{ accumulatedTime(operation.assistant_time) }} horas
                    </p>
                  </template>
                </div>
              </td>
              <td data-label="EFECTIVAS" style="width: 110px">
                <div class="is-flex is-justify-content-center is-align-items-center">
                  <p
                    v-if="!operation.title"
                    class="has-text-centered"
                    :class="
                      isOutOfRange(operation.responsible_time, operation.planned_time_supervisor) ||
                      isOutOfRange(operation.assistant_time, operation.planned_time_auditor)
                        ? 'has-text-danger-dark has-text-weight-semibold'
                        : ''
                    "
                  >
                    {{ accumulatedTime(operation.accumulated_time) }} horas
                  </p>
                </div>
              </td>
              <td v-if="allowUpload" data-label="DOCUMENTO" style="width: 40px">
                <DocumentHandle
                  :data="operation"
                  :concepto-id="conceptoId"
                  disabled-download
                  disabled
                />
              </td>
              <td class="add-hours-tr" style="width: 24px">
                <div class="px-3 is-flex is-justify-content-center is-align-items-center">
                  <Button
                    v-if="!operation.title && allowUpdate && operation.status !== 'FINALIZADA'"
                    disabled
                    class="mr-2 btn-edit"
                    icon-right="pencil"
                    variant="primary"
                  />
                  <Button
                    v-if="!operation.title && operation.status !== 'FINALIZADA'"
                    disabled
                    icon-right="plus"
                    variant="primary"
                  />
                  <div
                    v-else-if="
                      !operation.title && operation.status == 'FINALIZADA' && operation.indicator
                    "
                    class="indicator"
                  >
                    <o-tooltip
                      class="semaphoring-indicator"
                      :label="setIndicator(operation.indicator).label"
                      variant="black"
                      multiline
                    >
                      <o-icon
                        :icon="setIndicator(operation.indicator).icon"
                        :variant="setIndicator(operation.indicator).variant"
                      />
                    </o-tooltip>
                  </div>
                </div>
              </td>
              <td style="width: 24px">
                <div class="is-flex is-justify-content-center is-align-items-center">
                  <o-icon icon="arrow-all" />
                </div>
              </td>
            </SlickItem>
          </tbody>
        </table>
      </div>
    </div>
  </SlickList>
</template>
<script>
import { SlickList, SlickItem } from 'vue-slicksort';
import useTableOperation from './useTableOperation';
import DocumentHandle from './DocumentHandle.vue';
import { Button } from '@/components';
import { ref, watch, toRefs, getCurrentInstance } from 'vue';
export default {
  components: {
    Button,
    DocumentHandle,
    SlickList,
    SlickItem,
  },
  props: {
    conceptoId: { type: [String, Number], default: null },
    columns: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  emits: ['update:loading'],
  setup(props, { emit }) {
    const {
      accumulatedTime,
      allowAddHour,
      allowDeleteOperation,
      allowUpdate,
      allowUpload,
      formatLastUpdate,
      isLoadingSort,
      isOutOfRange,
      setIndicator,
      statusIcon,
    } = useTableOperation();
    const { proxy } = getCurrentInstance();
    const Api = proxy.Api;
    const listOperations = ref(JSON.parse(JSON.stringify(props.columns)));
    const itemDragged = ref(NaN);
    const { loading, columns: data } = toRefs(props);
    const oLoading = ref(props.loading);
    const target = ref({});

    const onStartSort = ({ index }) => {
      const trDragged = document.getElementsByClassName('is-tr-dragged')[0];
      trDragged.classList.add('tr-dragged-modified');
      itemDragged.value = index;
    };
    const onEndSort = ({ newIndex, oldIndex }) => {
      itemDragged.value = NaN;
      if (newIndex === oldIndex) return;

      target.value = listOperations.value[oldIndex];
      setSequence(newIndex + 1);
    };

    const setSequence = async (sequence) => {
      oLoading.value = true;
      try {
        await Api.put(`/concept/${props.conceptoId}/operation/${target.value.id}`, {
          sequence: sequence,
        });
        listOperations.value.forEach((concept, idx) => (concept.sequence = idx + 1));
      } catch (error) {
        console.log(error);
      }
      target.value = {};
      oLoading.value = false;
    };
    watch(data, () => {
      listOperations.value = JSON.parse(JSON.stringify(props.columns));
    });
    watch(loading, (value) => (oLoading.value = value));
    watch(oLoading, (value) => {
      isLoadingSort.value = value;
      emit('update:loading', value);
    });
    return {
      isLoadingSort,
      oLoading,
      onEndSort,
      onStartSort,
      listOperations,
      accumulatedTime,
      allowAddHour,
      allowDeleteOperation,
      allowUpdate,
      allowUpload,
      formatLastUpdate,
      isOutOfRange,
      setIndicator,
      statusIcon,
    };
  },
};
</script>
<style lang="sass" scoped>
#table-procedimientos.drag-table-operation
  max-height: 50vh
  overflow: auto
  margin-bottom: 50px
  &.filter-loading :deep(tbody)
    transition: filter 0.2s ease-in
    filter: grayscale(1)
    pointer-events: none
  .b-table
    .table-wrapper
      .table
        font-size: $f-sm
        background: none
        td
          border: none
        thead
          th
            font-size: $f-sm
            background-color:  $black
            color: white
            text-align: center
            &:nth-child(2)
              text-align: left
        :deep(tbody)
          tr
            cursor: grab
            width: 100%
            &.tr-dragged-modified td[data-label="DOCUMENTO"] .document-handler .download-link
              overflow-wrap: unset
              white-space: unset
            &.is-title
              &:hover > td div
                background: $color-blue-hues
                pointer-events: none
              .is-title-operation
                white-space: nowrap
            td
              height: inherit !important
          .is-tr-dragged
            z-index: 1100000
            pointer-events: auto
            background: none

            td[data-label="DOCUMENTO"] .document-handler .download-link
              overflow-wrap: unset
              white-space: unset
  :deep(.mdi-arrow-all):before
    color: $grey-dark
</style>

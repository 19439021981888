/* eslint-disable no-useless-escape */
export const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/,
);
export const rfcRegex = /[a-zA-Z]{3,4}[0-9]{6}([a-zA-Z0-9]){3}/;
export const fileNameRegex = new RegExp(/^[^<>:"|?*\/.^\\]*$/);
export const numberToMonth = '^(((?!0)(?![4-9]))\\d{2}|(?!0)\\d{1})$';

export default {
  emailRegex,
  fileNameRegex,
  numberToMonth,
  rfcRegex,
};

export default [
  'animation',
  'appendToBody',
  'autocomplete',
  'placeholder',
  'checkInfiniteScroll',
  'check-infinite-scroll',
  'clearOnSelect',
  'clear-on-select',
  'clearable',
  'confirmKeys',
  'confirm-keys',
  'customFormatter',
  'custom-formatter',
  'debounceTyping',
  'debounce-typing',
  'expanded',
  'field',
  'groupField',
  'group-field',
  'groupOptions',
  'group-options',
  'icon',
  'iconPack',
  'icon-pack',
  'iconRight',
  'icon-right-clickable',
  'iconRightClickable',
  'itemTag',
  'item-tag',
  'keepFirst',
  'keep-first',
  'keep-open',
  'keepOpen',
  'maxHeight',
  'max-height',
  'maxlength',
  'menuPosition',
  'menu-position',
  'menu-tag',
  'menuTag',
  'override',
  'rounded',
  'select-on-click-outside',
  'selectOnClickOutside',
  'selectableFooter',
  'selectable-footer',
  'selectable-header',
  'selectableHeader',
  'size',
  'statusIcon',
  'status-icon',
  'type',
  'useHtml5Validation',
  'use-html5-validation',
  'validation-message',
  'validationMessage',
];

import { useProgrammatic } from '@oruga-ui/oruga-next';
import { Notification } from '@/components';
const useDialog = () => {
  const { oruga } = useProgrammatic();
  const toast = (t = 'success', m = 'toast', duration = 5000, opt = {}) => {
    let options = {
      container: '#app ',
      variant: t,
      message: m,
      duration,
      animation: 'fade',
      position: 'top-right',
      hasIcon: true,
      queue: false,
      ...opt,
    };

    const notific = oruga.notification.open(options);
    if (options.onClose) {
      notific.$on('close', () => {
        options.onClose('closed');
      });
    }
    if (options.onClick) {
      notific.$on('click', () => {
        if (notific.isActive) {
          options.onClick(notific);
          notific.close();
        }
      });
    }
  };

  const Notify = (v, title, message, duration = 5000, opt = {}, closable = true) => {
    const component = () => <Notification title={title} message={message} variant={v} />;

    let options = {
      container: '#app ',
      variant: v,
      duration,
      animation: 'fade',
      position: 'top-right',
      hasIcon: true,
      queue: false,
      component,
      closable,
      ...opt,
    };
    const notific = oruga.notification.open(options);
    if (options.onClose) {
      notific.$on('close', () => {
        options.onClose('closed');
      });
    }
    if (options.onClick) {
      notific.$on('click', () => {
        if (notific.isActive) {
          options.onClick(notific);
          notific.close();
        }
      });
    }
  };

  return {
    toast,
    Notify,
  };
};
export default useDialog;

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Api from '@/utils/Api.js';
import { useAuthStore } from '@/store';
import Oruga from '@oruga-ui/oruga-next';
import { bulmaConfig } from '@oruga-ui/theme-bulma';
import '@oruga-ui/theme-bulma/dist/bulma.css';
import '@mdi/font/css/materialdesignicons.min.css';
import './assets/sass/style.sass';
import moment from 'moment';
import { vMaska } from 'maska';
import 'moment/locale/es';
import FirebaseMessaging from '@/utils/FirebaseMessaging.js';
import { ClickOutside } from './utils/directives';
import Vue3ColorPicker from 'vue3-colorpicker';
import 'vue3-colorpicker/style.css';
import { plugin as Slicksort } from 'vue-slicksort';

import { createPinia } from 'pinia';
moment.locale('es');

const pinia = createPinia();
const app = createApp(App);

// formulario dinamico

import VueGoogleMaps from '@fawmi/vue-google-maps';

const googleApiKey = 'AIzaSyCnTGvHk5eqeDlv-tyLNLUSQUySBuPA0-0';
app.use(VueGoogleMaps, {
  load: {
    key: googleApiKey,
  },
});

app.use(pinia);
app.use(router);
app.use(Oruga, bulmaConfig);
app.use(Vue3ColorPicker);
app.use(Slicksort);
app.directive('maska', vMaska);
app.directive('click-outside', ClickOutside);

const apiStore = useAuthStore();
const Authorization = apiStore.Authorization;
Api.Authorization = Authorization;

app.config.globalProperties.pushNotifications = new FirebaseMessaging(false);
app.config.globalProperties.Api = Api;
app.mount('#app');

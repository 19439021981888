<template>
  <div class="module-users">
    <ModalUser v-model:is-open="isModalCreateUserActive" @success:save="reload" />
    <div class="button-group">
      <Button
        class="large-button"
        variant="primary"
        inverted
        icon-left="account-plus"
        label="Crear usuario"
        :permission="userPermissions.Create"
        @click="isModalCreateUserActive = !isModalCreateUserActive"
      />
      <Button
        class="large-button"
        variant="primary"
        inverted
        icon-left="list-box-outline"
        label="Lista de usuarios"
        :permission="userPermissions.Read"
        @click="() => onChangePage('/lista-usuarios')"
      />
    </div>
    <p class="type-module">Usuarios</p>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Button, ModalUser } from '@/components';
import { useComponentUtils } from '@/components/conf/composables';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Button,
    ModalUser,
  },
  emits: ['onClosePanel'],
  setup(props, { emit }) {
    const router = useRouter();
    const { table } = useComponentUtils();
    const isModalCreateUserActive = ref(false);
    const userPermissions = computed(() => {
      return Permissions.Users;
    });
    return {
      userPermissions,
      router,
      isModalCreateUserActive,
      reload: () => {
        if (table.value) {
          table.value.reload();
        }
      },
      onChangePage: (page) => {
        emit('onClosePanel');
        router.push(page);
      },
    };
  },
};
</script>
<style lang="sass" scoped>
.module-users
  .button-group
    :deep(.button)
      margin-right: 24px
  .type-module
    margin-right: 24px
    text-align: center
    margin-top: 8px
    line-height: 21px
    font-weight: 400
    color: $grey-dark
    font-size: $f-sm
</style>

<template>
  <div :class="classes">
    <a class="step-link"
      ><div class="step-marker">
        <o-icon v-if="icon" :icon="icon" variant="primary" size="small" />
      </div>
      <slot />
    </a>
  </div>
</template>
<script>
import { toRefs, computed } from 'vue';
export default {
  props: {
    status: {
      type: String,
      enum: ['done', 'doing', 'toDo', 'stoped', 'needAtention'],
      default: 'toDo',
    },
  },
  setup(props) {
    const { status } = toRefs(props);
    const states = {
      DONE: 'done',
      DOING: 'doing',
      TODO: 'toDo',
      STOPED: 'stoped',
      NEEDATENTION: 'needAtention',
    };
    const icon = computed(() => {
      if (states.DONE === status.value) return 'check-bold';
      else if (states.DOING === status.value) return 'circle';
      else return null;
    });
    const classes = computed(() => `step-item is-${status.value}`);

    return {
      icon,
      classes,
    };
  },
};
</script>
<style lang="sass" scoped>
.step-item
    background-color:  inherit !important
    scroll-snap-align: end
    min-width: 180px
    &::before, &::after
        background: $grey-100 !important
    .step-link
        height: 100%
        display: flex
        flex-direction: flex-start
        background-color:  inherit !important
        cursor: auto !important
        .step-marker
            background-color:  inherit !important
            border: solid 3.2px $primary !important
            margin-bottom: 18px
        .step-link
          max-width: unset !important
    &.is-toDo .step-link .step-marker
            border: solid 3.2px $grey-100 !important
    &.is-doing .step-link .step-marker :deep(.icon)
        ::before
            font-size: 11px
            margin-bottom: 3px
    &.is-done .step-link .step-marker
        background: $primary !important
        :deep(.icon) ::before
            color: $white
            font-size: 15px
</style>

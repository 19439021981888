<template>
  <div class="user-actions" :class="{ 'no-contability': !isContabilityActive }">
    <div class="buttons" :class="{ 'no-contability': !isContabilityActive }">
      <o-tooltip variant="black" label="Editar">
        <Button
          variant="primary"
          :permission="clientPermissions.Update"
          icon-right="pencil"
          @click="$emit('on-edit-client')"
        />
      </o-tooltip>
      <o-tooltip variant="black" label="Asociados">
        <Button
          class="ml-3 mr-0"
          variant="primary"
          :permission="clientAssociates.ReadAll"
          icon-right="account-multiple"
          @click="$emit('on-edit-associates')"
        />
      </o-tooltip>
      <o-tooltip variant="black" label="Etapas de cliente">
        <Button
          v-if="isContabilityActive"
          variant="primary"
          class="ml-3"
          :permission="plannedRunPermissions.ReadAll"
          icon-right="calendar-sync"
          @click="onNavigatePlannedRun(data.id)"
        />
      </o-tooltip>
    </div>
    <div class="switchBtn">
      <o-tooltip
        variant="black"
        :label="data.is_active ? 'Desactivar' : 'Activar'"
        :active="!isDisabled"
      >
        <Switch
          ref="switchActive"
          v-model="isActive"
          :disabled="isDisabled"
          class="switch-client"
          :permission="clientPermissions.Enable"
          @click="fireNotify"
          @update:model-value="activeUser($event)"
        />
      </o-tooltip>
    </div>
  </div>
</template>
<script>
import { computed, toRefs, ref, watch, getCurrentInstance } from 'vue';
import useDialog from '@/utils/composables/useDialog';
import { Switch, Button } from '@/components';
import { Permissions } from '@/utils/Secure';
import { useRouter } from 'vue-router';

export default {
  components: {
    Button,
    Switch,
  },
  props: {
    loading: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    reload: { type: Function, default: null },
  },
  emits: ['on-edit-client', 'on-edit-associates', 'update:loading'],
  setup(props, { emit }) {
    const { Notify } = useDialog();
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const router = useRouter();

    const { loading } = toRefs(props);
    const isLoading = ref(props.loading);
    const isActive = ref(Boolean(props.data.is_active));
    const switchActive = ref(null);
    const activeServices = computed(() => {
      return props.data.services;
    });

    const isContabilityActive = computed(() => {
      return activeServices.value.some((s) => s.service_name === 'Contabilidad');
    });

    const activeUser = async (value) => {
      const { id, is_active, name } = props.data;
      const CurrentVlaue = Boolean(is_active);
      if (CurrentVlaue === value) return;
      try {
        isLoading.value = true;
        await Api.patch(`/clients/${id}/${value ? 'enable' : 'disable'}`);
        Notify(
          'success',
          `Cliente ${value ? 'activado' : 'desactivado'} exitosamente`,
          `Cliente: ${name}`,
          2000,
        );
        if (props.reload) await props.reload();
      } catch (error) {
        Notify('warning', `No se logró ${value ? 'Activar' : 'Desactivar'}`);
        console.log(error);
      }
      isLoading.value = false;
    };
    const fireNotify = () => {
      if (isDisabled.value)
        Notify(
          'warning',
          `Atención`,
          'No es posible desactivar cliente con servicios en proceso',
          2000,
        );
    };
    const onNavigatePlannedRun = (id) =>
      router.push({ path: `/lista-clientes/corrida-mensual/${id}` });

    const clientPermissions = computed(() => {
      return Permissions.Clients;
    });
    const clientAssociates = computed(() => {
      return Permissions.clientsAssociates;
    });
    const plannedRunPermissions = computed(() => {
      return Permissions.PlannedRun;
    });
    const isDisabled = computed(() => {
      const { is_active, services } = props.data;
      return Boolean(services.some((s) => s.is_load_info) && is_active);
    });

    watch(loading, (value) => (isLoading.value = value));
    watch(isLoading, (value) => emit('update:loading', value));
    return {
      // data
      isActive,
      isLoading,
      switchActive,

      // method
      activeUser,
      fireNotify,
      onNavigatePlannedRun,

      // computed
      clientAssociates,
      clientPermissions,
      plannedRunPermissions,
      isDisabled,
      isContabilityActive,
    };
  },
};
</script>
<style lang="sass" scoped>
.user-actions
    display: flex
    flex-direction: column
    padding: 0 20px
    align-items: left
    justify-content: center
    margin: auto
    &.no-contability
      flex-direction: row
      align-items: unset
    .buttons
        display: flex
        flex-direction: row
        justify-content: left
        margin: 0
        &.no-contability
          flex-wrap: nowrap
          :deep(.button)
            margin: 0
    &:not(.no-contability)
        .switchBtn
            width: fit-content
            margin-left:18px
    :deep(.button)
        border-radius: 3px
        height: 24px
        width: 24px
        padding: 0
        .icon
            margin: 0 !important
            .mdi::before
              font-size: 18px
    :deep(.switch-client) .switch
      margin: 0 0 0 .75rem
    :deep(.b-tooltip) .tooltip-content
      font-size: 12px
      padding: 6px
</style>

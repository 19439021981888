<template>
  <Modal
    id="resetPasswordModal"
    v-model:is-open="isModalActive"
    header="Restablecer contraseña"
    size="is-large"
    :loading="loading"
    :is-disabled="loading.save"
    :save-disabled="!meta.valid || loading.save"
    button-label="Restablecer contraseña"
    variant-cancel="info"
    @cancel="resetForm"
    @save="onSubmit"
  >
    <form ref="formResetPasword" class="formResetPasword">
      <Field
        v-model="email"
        v-bind="emailAttrs"
        :error-message="errors.email"
        class="email-reset w-100"
        label="Ingresa el correo electrónico asociado a tu cuenta:"
        placeholder="correo@ejemplo.com"
        :pattern="emailRegex"
        type="email"
        required
      />
    </form>
  </Modal>
</template>
<script>
import { Modal, Field } from '@/components';
import { ref, toRefs, watch, getCurrentInstance, reactive, computed } from 'vue';
import { emailRegex } from '@/utils/RegexValidations';
import useDialog from '@/utils/composables/useDialog';
import { useForm } from 'vee-validate';
import * as yup from 'yup';

export default {
  components: {
    Modal,
    Field,
  },
  props: {
    isOpen: { type: Boolean, default: false },
  },
  emits: ['update:active', 'update:is-open'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { Notify } = useDialog();
    const isDisabledModal = ref(false);
    const isModalActive = ref(props.isOpen);
    const isOpen = toRefs(props).isOpen;
    const formResetPasword = ref(null);

    //FORM
    const { defineField, meta, errors, handleSubmit, isSubmitting, resetForm } = useForm({
      initialValues: {
        email: '',
      },
      validationSchema: yup.object({
        email: yup
          .string()
          .required('Completa este campo')
          .max(255)
          .matches(
            emailRegex,
            'Por favor, introduzca una dirección de correo válida. (Ejemplo: nombre@correo.com)',
          ),
      }),
    });
    const loading = reactive({ save: false });
    const [email, emailAttrs] = defineField('email', {
      validateOnInput: true,
      validateOnModelUpdate: true,
    });
    const onSubmit = handleSubmit(async () => {
      try {
        await Api.post(
          '/users/recover-password',
          { email: email.value },
          { Authorization: 'Bearer ' },
        );
        Notify(
          'info',
          'Correo enviado:',
          'Revisa tu bandeja de correo y haz clic en el link para continuar.',
        );
      } catch (error) {
        console.log(error);
      }
    });
    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isSubmitting, (value) => (loading.save = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      if (!value) resetForm();
    });
    return {
      email,
      emailRegex,
      resetForm,
      isDisabledModal,
      isModalActive,
      onSubmit,
      loading,
      formResetPasword,
      emailAttrs,
      meta,
      errors,
      isDisabled: computed(() => {
        return !email.value.length || loading.save || !formResetPasword.value.checkValidity();
      }),
    };
  },
};
</script>
<style lang="sass" scoped>
#resetPasswordModal
    .email-reset > :deep(.control) .field.has-addons
      font-size: $f-sm
    .formResetPasword
        height: 200px
</style>

<template>
  <div v-if="isLoading" class="loading-stage-stepper">
    <o-loading v-if="isLoading" :active="isLoading" :full-page="false" />
  </div>
  <section v-else class="b-steps" :class="variantClass">
    <nav class="steps is-animated is-rounded">
      <StepItem
        v-for="(stage, index) in stagesData"
        :key="stage.id"
        :status="statusStage(stage.monthly_run_stage_status_id)"
      >
        <StatusButton
          :date-status="stage.monthly_run_stage_status_datetime"
          :is-active="stage.is_active"
          :disable-change-status="index > disableChangeStatus"
          :stage-data="stage"
          :stage-status-reload="reload"
          :status="statusStage(stage.monthly_run_stage_status_id)"
          :selected="isSelected(stage.id)"
          @click.stop="() => onSelect(stage.id, stage)"
        />
      </StepItem>
    </nav>
  </section>
  <div></div>
</template>
<script>
import { computed, ref, watch, toRefs, getCurrentInstance, onMounted, watchEffect } from 'vue';
import { StepItem, StatusButton } from '@/components';
import { useComponentUtils } from '@/components/conf/composables';
import { useAuthStore } from '@/store';
export default {
  components: { StepItem, StatusButton },
  inheritAttrs: false,
  props: {
    positiveNegativeStatus: { type: Object, default: null },
    apiUrl: { type: String, default: null },
    data: { type: Array, default: () => [] },
    header: { type: String, default: '' },
    monthlyRunId: { type: Number || String, default: null },
    variant: {
      type: String,
      enum: ['primary', 'info', 'success', 'warning', 'danger'],
      default: 'primary',
    },
    loading: { type: Boolean, default: false },
  },
  emits: ['update:selected', 'update:loading', 'update:data', 'reload:status'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const selected = ref(null);
    const { loading, data, positiveNegativeStatus } = toRefs(props);
    const isLoading = ref(props.loading);
    const stagesData = ref(props.data);
    const { getAUTH } = useAuthStore();
    const { roles } = useComponentUtils();

    const isAdminAuditor = computed(
      () => getAUTH.role === roles.Administrador || getAUTH.role === roles.Auditor,
    );
    const disableChangeStatus = computed(() => {
      let notOmissible = 100000;
      if (!stagesData.value) return notOmissible;
      stagesData.value.forEach(
        ({ is_omissible, is_active, monthly_run_stage_status_id: mrssId }, idx) => {
          if (!is_omissible && is_active && mrssId != 3 && notOmissible === 100000)
            notOmissible = idx;
        },
      );
      return notOmissible;
    });
    const variantClass = computed(() => `is-${props.variant}`);

    const isSelected = (id) => id === selected.value;
    const getData = async () => {
      isLoading.value = true;
      try {
        let { data } = await Api.get(props.apiUrl);
        stagesData.value = isAdminAuditor.value ? data : data.filter((stage) => stage.is_active);
        selected.value = stagesData.value[0].id;
        emit('update:selected', selected.value);
      } catch (error) {
        console.log(error);
      }
      isLoading.value = false;
    };
    const statusStage = (monthlyRunStageStatusId) => {
      if (monthlyRunStageStatusId == 2) return 'doing';
      else if (monthlyRunStageStatusId == 3) return 'done';
      else return 'toDo';
    };
    const reload = () => {
      emit('reload:status');
      getData();
    };
    const onSelect = (id) => {
      if (selected.value === id) return;
      selected.value = id;
      emit('update:selected', id);
    };

    watch(data, (value) => (stagesData.value = value));
    watch(stagesData, (value) => emit('update:data', value));
    watch(loading, (value) => (isLoading.value = value));
    watch(isLoading, (value) => emit('update:loading', value));
    watchEffect(() => {
      if (!positiveNegativeStatus.value) return;

      stagesData.value.map((stage) => {
        if (stage.id === positiveNegativeStatus.value.id) {
          stage.positive_negative_value = positiveNegativeStatus.value.value;
        }
      });
    });

    onMounted(() => getData());
    return {
      variantClass,
      onSelect,
      isSelected,
      stagesData,
      isLoading,
      statusStage,
      disableChangeStatus,
      reload,
    };
  },
};
</script>
<style lang="sass" scoped>
.loading-stage-stepper
  position: relative
  height: 220px
  :deep(.loading) .loading-overlay
    background-color: inherit
.b-steps
  z-index: 0
  width: calc( 100vw - 202px )
  position: relative
  background-color:  inherit !important
  border: none
  margin: 0
  .steps
    background-color:  inherit !important
    flex-wrap: nowrap
    overflow-x: auto
    scroll-behavior: smooth
    scroll-snap-type: x mandatory
    .step-item
      min-width: fit-content
      flex-basis: unset
      padding: 0 4px
      .step-link
        height: 100%
</style>

<template>
  <o-dropdown-item aria-role="listitem" class="mb-1" :class="classes" @click="onNotificationClick">
    <o-loading v-model:active="loading" :full-page="false"></o-loading>
    <div class="n-header is-flex">
      <div class="n-icon is-inline-block pr-1">
        <o-icon v-if="notiData.icon" :icon="notiData.icon" />
        <img v-else :src="notification.imageIconUrl" alt="" />
      </div>
      <span class="n-title" v-html="title"> </span>
    </div>
    <div class="n-content">
      <div class="n-body" v-html="notiData.body || notification.body || '-'" />
      <div class="n-date">{{ formatTime(notification.created_at) }}</div>
    </div>
  </o-dropdown-item>
</template>
<script>
import { useRouter } from 'vue-router';
import useDialog from '@/utils/composables/useDialog';
import moment from 'moment';
import { useAuthStore } from '@/store';
import { useComponentUtils } from './conf/composables';
import { computed, ref, toRefs, onMounted } from 'vue';
export default {
  components: {},
  props: {
    notification: { type: Object, required: true },
    setNotificationAttendedFunction: { type: Function, required: true },
  },
  emits: ['fire-modal-signout'],
  setup(props, { emit }) {
    const { deAUTH } = useAuthStore();
    const { table } = useComponentUtils();
    const loading = ref(false);
    const router = useRouter();
    const { Notify } = useDialog();
    const { notification } = toRefs(props);
    const getVariant = (indicator) => {
      switch (indicator) {
        case 'VERDE':
          return 'success';
        case 'ROJO':
          return 'danger';
        case 'AMARILLO':
          return 'warning';
        default:
          return 'success';
      }
    };
    const setIcon = (variant) => {
      switch (variant) {
        case 'success':
          return 'check-circle-outline';
        case 'info':
          return 'information-outline';
        case 'warning':
          return 'alert-outline';
        case 'danger':
          return 'close-circle-outline';
        default:
          return 'bell';
      }
    };
    const goToOperation = async () => {
      const { auditing_id, concept_id } = notification.value.jsonBody;
      const path = `/auditorias/${auditing_id}/conceptos/${concept_id}/procedimiento`;
      if (router.currentRoute.value.path === path && table.value) {
        if (
          window.confirm(
            '¡Atención! Si continúas, perderás todo lo que has escrito. ¿Estás seguro de que deseas continuar?',
          )
        ) {
          loading.value = true;
          await table.value.reload(false);

          setTimeout(() => {
            loading.value = false;
          }, 1000);
        }
      } else router.push({ path });
    };
    const notiData = computed(() => {
      const { jsonBody, body, type, title } = notification.value;
      const { indicator, notification_id, notification_type } = jsonBody || {};
      const notiData = {
        monthly_run_indicator_changed: {
          body,
          variant: getVariant(indicator),
          title,
          onClickAction: null,
        },
        conclution_request: {
          body,
          variant: 'success',
          title,
          onClickAction: goToOperation,
        },
        conclution_rejected: {
          body,
          variant: 'danger',
          title,
          onClickAction: goToOperation,
        },
        conclution_accepted: {
          body,
          variant: 'success',
          title,
          onClickAction: goToOperation,
        },
        sign_out: {
          body,
          variant: 'warning',
          title,
        },
      }[type] || { body };
      if (type === 'sign_out') emit('fire-modal-signout');
      return {
        notification_id,
        notification_type,
        type,
        icon: setIcon(notiData.variant),
        ...notiData,
      };
    });
    const classes = computed(() => {
      const classes = [];
      classes.push(`is-${notiData.value.variant}`);
      if (notification.value.is_attended) classes.push('is-attended');
      if (notiData.value.onClickAction || !notification.value.is_attended)
        classes.push('is-clickable');
      return classes.join(' ');
    });
    // Methods
    const init = () => {
      if (notification.value.dispatch) showNotification();
    };
    const setAttended = async () => {
      loading.value = true;
      if (props.setNotificationAttendedFunction) {
        const { is_attended } = notification.value;
        if (!is_attended) {
          try {
            await props.setNotificationAttendedFunction(notification.value.id);
            notification.value.is_attended = 1;
          } catch (error) {
            error;
          }
        }
      }
      loading.value = false;
    };
    const showNotification = () => {
      const { variant, type } = notiData.value;
      Notify(variant, notification.value.title, notification.value.body);
      if (type === 'sign_out') {
        emit('fire-modal-signout');
        deAUTH({
          redirect: false,
        });
      }
    };
    const formatTime = (dateTime) => {
      if (!dateTime) return '-';
      const utcDate = moment.utc(dateTime).toDate();
      return moment(utcDate).format('Y-MM-DD hh:mm a');
    };
    const onNotificationClick = async () => {
      if (!notification.value.is_attended) await setAttended();
      const { onClickAction } = notiData.value;
      onClickAction && (await onClickAction());
    };
    const title = computed(
      () => notiData.value.title || notification.value.title || notification.value.type,
    );
    onMounted(() => init());
    return {
      classes,
      loading,
      table,
      notiData,
      title,
      onNotificationClick,
      formatTime,
    };
  },
};
</script>
<style lang="sass" scoped>
.dropdown-item
  padding: 16px !important
  word-break: break-spaces
  white-space: pre-wrap
  border-radius: 5px
  .n-header
    vertical-align: middle
    .n-icon :deep(.icon)
      width: unset
      .mdi::before
        font-size: 16px
        line-height: initial
    .n-title
      font-weight: 600
      line-height: initial
  .n-content
    .n-date
      text-align: right
      font-size: 11px
    .n-body
      white-space: pre-wrap
      font-size: 13px
  @each $className, $object in $tagColors
    &.is-#{$className}, &.is-#{$className}.is-active
      color: $black
      background-color: map-get($object,'background' ) !important
    &.is-#{$className}
      &.is-attended
        background-color: rgba(map-get($object,'background' ),0.4 )  !important
        cursor: initial
</style>

<template>
  <div id="step1-add-stage">
    <Autocomplete
      :value="stageName"
      v-bind="stageNameAttrs"
      label="Seleccionar etapa"
      required
      field="name"
      placeholder="Seleccionar etapa"
      :api="AutocompleteAPI"
      :error-message="errors.stageName"
      @select-raw="onSelectStage"
      @error:not-found="isNotFound = $event"
      @error:not-selected="isNotSelected = $event"
    />
    <Select
      v-model="sequenceName"
      v-bind="sequenceFieldAttrs"
      v-model:data="stagesData"
      label="Secuencia"
      placeholder="Selecciona una opción"
      :api-url="currentApi"
      :params="params"
      model="name"
      :data-pre-procesor="preprocessorData"
      required
      :error-message="errors.sequenceField"
      @on-select="onSelectSequence"
      @change="onSelectSequence($event.target.value)"
    />
    <div class="is-flex is-justify-content-space-between">
      <Field
        v-model="responsibleEntitie"
        v-bind="responsibleEntitieAttrs"
        :error-message="errors.responsibleEntitie"
        class="w-50"
        label="Responsable"
        :value="responsibleEntities"
        placeholder="Nombre responsable"
        disabled
      />
      <Field
        v-model="stageTypeName"
        v-bind="stageTypeAttrs"
        :error-message="errors.stageTypeName"
        class="w-50"
        label="Tipo de etapa"
        placeholder="Etapa"
        disabled
      />
    </div>
  </div>
</template>
<script setup>
import { Select, Autocomplete, Field } from '@/components';
import { useRoute } from 'vue-router';
import { ref, watch, toRefs, computed } from 'vue';
import { useForm } from 'vee-validate';
import useStages from '@/utils/composables/useStages';
import { selectSchema } from '@/utils/YupSchemas';
import * as yup from 'yup';

// Config
const route = useRoute();
const props = defineProps({
  stage: { type: Object, default: () => ({}) },
  stageType: { type: Object, default: () => ({}) },
  form: { type: Object, default: () => ({}) },
  isPlannedRun: { type: Boolean, default: false },
  monthlyRunId: { type: [String, Number], default: null },
  initialPlannedData: { type: Object, default: () => ({}) },
  initialMonthlyDetailsData: { type: Object, default: () => ({}) },
});
const monthlyRunId = ref(props.monthlyRunId);
const isNotFound = ref(false);
const isNotSelected = ref(false);
watch([isNotFound, isNotSelected], ([notFound, notSelected]) => {
  if (notFound || notSelected) {
    emit('update:ready', false);
  }
});

const apiMonthlyDetails = ref(`monthly-run/${monthlyRunId.value}/monthly-run-details`);
const apiStages = ref('/planned-run');
const emit = defineEmits([
  'update:model-value',
  'update:form',
  'update:stage',
  'update:ready',
  'update:sequence',
]);
const currentCustomerID = ref(route.params.id);
const params = computed(() => {
  let eParams = props.isPlannedRun ? [`customer_id=${currentCustomerID.value}`] : ['sort=sequence'];
  return eParams;
});

const { stage, stageType } = toRefs(props);
const stageData = ref(props.stage);
const sequenceName = ref(props.form.sequenceName);
const AutocompleteAPI = {
  url: '/stages',
  query: 'name',
};

const stagesData = ref([]);
const stageTypeData = ref(props.stageType);
const { RESPONSIBLE_ENTITIES } = useStages({
  loadStageTypes: true,
  formStep: 'initialStep',
});
const responsibleEntities = [
  { name: RESPONSIBLE_ENTITIES.USUARIO },
  { name: RESPONSIBLE_ENTITIES.CLIENTE },
];
const selectData = ref([]);

// FORM
const { defineField, setValues, handleSubmit, errors, schema, meta } = useForm({
  initialValues: {
    stageName: props.form.stageName,
    responsibleEntitie: props.form.responsibleEntitie,
    stageTypeName: props.form.stageTypeName,
    sequenceField: props.form.sequenceField,
  },
  validationSchema: yup.object({
    stageName: selectSchema,
    responsibleEntitie: yup.string(),
    stageTypeName: yup.string(),
    sequenceField: selectSchema,
  }),
});

// FORM FIELDS
const [stageName, stageNameAttrs] = defineField('stageName');
const [responsibleEntitie, responsibleEntitieAttrs] = defineField('responsibleEntitie');
const [stageTypeName, stageTypeAttrs] = defineField('stageTypeName');
const [sequenceField, sequenceFieldAttrs] = defineField('sequenceField');

const onSelectStage = (_stageData) => (stageData.value = _stageData);

const onSubmit = handleSubmit(() => {
  emit('update:stage', stageData.value);
  emit('update:sequence', sequenceField.value);
});

const selectInitialSequence = (data, initialSequence) => {
  if (data.length === 2) return onSelectSequence(data[0]), (sequenceName.value = data[0].name);
  let currentStage = data.find(({ sequence }) => sequence === initialSequence);
  if (currentStage.sequence === 1) currentStage = data[1];
  currentStage && onSelectSequence(currentStage), (sequenceName.value = currentStage.name);
};

const initialPlannedData = computed(() => {
  if (props.initialPlannedData) {
    const { stage_name, stage_responsible_entity, stage_type_name } = props.initialPlannedData;
    setValues({
      stageName: stage_name,
      responsibleEntitie: stage_responsible_entity,
      stageTypeName: stage_type_name,
    });
  }
  return props.initialPlannedData;
});

const initialMonthlyDetailsData = computed(() => {
  if (props.initialMonthlyDetailsData) {
    const { stage_name, responsible_stage, stage_type_name } = props.initialMonthlyDetailsData;
    setValues({
      stageName: stage_name,
      responsibleEntitie: responsible_stage,
      stageTypeName: stage_type_name,
    });
  }
  return props.initialMonthlyDetailsData;
});

const setStagesMappedWhenEditing = (stages, conditional) => {
  return stages.map((value) => {
    value.stage_name === conditional
      ? (value.name = `Misma posición`)
      : (value.name = `Antes de la etapa ${value.stage_name}`);

    return value;
  });
};

const setStagesMappedNormally = (stages) => {
  return stages.map((value) => {
    value.name = `Antes de la etapa ${value.stage_name}`;
    return value;
  });
};

const changeStagesSequenceAfterCurrent = (stagesMapped) => {
  let startSequenceChanging = false;
  stagesMapped.forEach((element) => {
    if (startSequenceChanging) element.sequence = element.sequence - 1;
    if (element.name === `Misma posición`) startSequenceChanging = true;
  });
};

const preProcessorDataPlannedRunEditing = (stages, beginOfSequence) => {
  if (stages.length === 1) {
    selectData.value = [beginOfSequence];
    return [beginOfSequence];
  }
  const endOfSequenceNumber = stages[stages.length - 1].sequence;
  const endOfSequence = { name: 'Al final de todas las etapas', sequence: endOfSequenceNumber };
  const stagesMapped = setStagesMappedWhenEditing(stages, initialPlannedData.value.stage_name);
  changeStagesSequenceAfterCurrent(stagesMapped);
  const data = [beginOfSequence, ...stagesMapped, endOfSequence];
  selectData.value = data;
  return data;
};

const preprocessorData = (stages) => {
  const beginOfSequence = { name: 'Al inicio de todas las etapas', sequence: 1 };

  if (initialPlannedData.value) return preProcessorDataPlannedRunEditing(stages, beginOfSequence);

  if (stages.length === 0) return [beginOfSequence];

  const endOfSequenceNumber = stages[stages.length - 1].sequence + 1;
  const endOfSequence = { name: 'Al final de todas las etapas', sequence: endOfSequenceNumber };
  let stagesMapped = [];

  initialMonthlyDetailsData.value
    ? ((stagesMapped = setStagesMappedWhenEditing(
        stages,
        initialMonthlyDetailsData.value.stage_name,
      )),
      changeStagesSequenceAfterCurrent(stagesMapped))
    : (stagesMapped = setStagesMappedNormally(stages));

  if (stages.length === 1) {
    const data = [...stagesMapped, endOfSequence];
    selectData.value = data;
    return data;
  }

  const data = [beginOfSequence, ...stagesMapped, endOfSequence];
  selectData.value = data;
  return data;
};

const onSelectSequence = (value) => {
  if (value?.sequence) sequenceField.value = value.sequence;
  onSubmit();
};

const currentApi = computed(() => {
  return props.isPlannedRun ? apiStages.value : apiMonthlyDetails.value;
});

watch(
  () => meta.value.valid,
  (value) => {
    emit('update:ready', value);
    if (value) onSubmit();
  },
);

watch(
  stageData,
  (value) => {
    if (value?.name) {
      const { name, responsible_entity, stage_type_name } = value;
      setValues({
        stageName: name,
        responsibleEntitie: responsible_entity,
        stageTypeName: stage_type_name,
      });
    }
    if (meta.value.valid) {
      onSubmit();
      const isSearchingError = isNotFound.value || isNotSelected.value;
      emit('update:ready', !isSearchingError);
    }
  },
  { deep: true },
);

watch(selectData, (value) => {
  if (value.length > 0 && (initialPlannedData.value || initialMonthlyDetailsData.value)) {
    const sequence =
      initialPlannedData.value?.sequence || initialMonthlyDetailsData.value?.sequence;
    if (selectData.value.length === 1) {
      onSelectSequence(value[0]);
      sequenceName.value = value[0].name;
    } else {
      selectInitialSequence(value, sequence);
    }
  }
});
watch(stageType, (value) => (stageTypeData.value = value));

watch(stage, (value) => (stageData.value = value));

watch(stagesData, (value) => {
  let stageNames = value
    .filter((_stage) => _stage.stage_name)
    .map((_stage) => {
      return _stage.stage_name;
    });
  if (initialPlannedData.value || initialMonthlyDetailsData.value) {
    const initialStageName =
      initialPlannedData.value?.stage_name || initialMonthlyDetailsData.value?.stage_name;
    stageNames = stageNames.filter((stage) => stage !== initialStageName);
  }
  schema.fields.stageName = schema.fields.stageName.notOneOf(
    stageNames,
    'Esta etapa ya está agregada',
  );
});
</script>
<style lang="sass" scoped>
#step1-add-stage
  color: $black
  font-size: $f-sm
  .w-50
    width: calc( 50% - 12px )
  .text-more-info
    white-space: nowrap
    font-size: $f-sm
    color: $grey-info
  .addon-text
    vertical-align: sub
    font-size: $f-sm
    color: $grey-dark

  .has-text-verde > :deep(.field-label) .label
    color: $primary
  .has-text-amarillo > :deep(.field-label) .label
    color: $color-orange
  .has-text-rojo > :deep(.field-label) .label
    color: $color-radical-red
  .addon-text
    margin-left: 8px
    margin-top: 7.5px
  :deep(.checkbox-required)
    margin: .25rem 0 0 .85rem
  .checkbox-text
    font-weight: 700
    font-size: $f-xs
    position: absolute
    top: -1rem
    left: 0rem
  .input-day >
    :deep(.field-label)
      margin-right: 8px
      .label
        margin: 0
        font-weight: 400
        margin-top: 7.5px
    :deep(.field-body)
      .control input, .field.has-addons .control
        width: 125px
</style>

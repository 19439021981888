const useContabilidad = () => {
  // constants
  const INDICATOR = {
    VERDE: { value: 'VERDE', param: 'indicator=VERDE' },
    AMARILLO: { value: 'AMARILLO', param: 'indicator=AMARILLO' },
    ROJO: { value: 'ROJO', param: 'indicator=ROJO' },
  };
  const STATUS = {
    1: 'Pendiente',
    2: 'En transcurso',
    3: 'Completado',
  };
  const STATES = {
    DONE: { key: 'done', text: 'COMPLETADO' },
    DOING: { key: 'doing', text: 'EN TRANSCURSO' },
    TODO: { key: 'toDo', text: 'PENDIENTE' },
    STOPED: { key: 'stoped', text: 'DETENIDO' },
    NEEDATENTION: { key: 'needAtention', text: 'NECESITA ATENCIÓN' },
  };
  const stageHandleStyle = (statusId, indicator) => {
    const { VERDE, AMARILLO, ROJO } = INDICATOR;
    const styleProps = {
      variant: 'neutral',
      icon: 'progress-question',
    };
    if (VERDE.value === indicator) {
      switch (statusId) {
        case 3:
          styleProps.icon = 'check-circle';
          styleProps.variant = 'primary';
          break;
        case 2:
          styleProps.icon = 'timer-sand-complete';
          styleProps.variant = 'link';
          break;
        case 1:
          return styleProps;
      }
    } else
      switch (indicator) {
        case AMARILLO.value:
          styleProps.icon = 'pause-circle';
          styleProps.variant = 'warning';
          break;
        case ROJO.value:
          styleProps.icon = 'alert-octagon';
          styleProps.variant = 'danger';
          break;
      }
    return styleProps;
  };

  // Methods
  const getStatusNameByID = (status_id) => STATUS[status_id];
  const getStateByName = (nameState = '') => {
    if (!nameState.length) return STATES.TODO.text;
    const { DONE, DOING, TODO, STOPED, NEEDATENTION } = STATES;
    switch (nameState) {
      case DONE.key:
        return DONE;
      case DOING.key:
        return DOING;
      case TODO.key:
        return TODO;
      case STOPED.key:
        return STOPED;
      case NEEDATENTION.key:
        return NEEDATENTION;
      default:
        return TODO;
    }
  };

  return {
    // Constants
    INDICATOR,
    STATUS,
    STATES,

    // Methods
    getStatusNameByID,
    getStateByName,
    stageHandleStyle,
  };
};
export default useContabilidad;

<template>
  <div id="step1-add-stage">
    <Field
      v-model="stageName"
      v-bind="stageNameAttrs"
      label="Nombre de la etapa"
      required
      field="name"
      placeholder="Escribir nombre"
      :error-message="errors.stageName"
    />
    <Select
      v-if="false"
      v-model="sequenceName"
      v-bind="sequenceFieldAttrs"
      v-model:data="stagesData"
      label="Secuencia"
      placeholder="Selecciona una opción"
      :api-url="apiMonthlyDetails"
      :params="['sort=sequence']"
      model="name"
      :data-pre-procesor="preprocesorData"
      required
      :error-message="errors.sequenceField"
      @on-select="onSelectSequence"
    />
    <div class="is-flex is-justify-content-space-between">
      <Select
        v-model="responsibleEntitie"
        v-bind="responsibleEntitieAttrs"
        :error-message="errors.responsibleEntitie"
        :loading="loading"
        class="w-50"
        label="Responsable"
        :data="responsibleEntities"
        required
        placeholder="Seleccionar"
      />
      <Select
        v-model="stageTypeName"
        :loading="loading"
        v-bind="stageTypeAttrs"
        :error-message="loading ? '' : errors.stageTypeName"
        class="w-50"
        label="Tipo de etapa"
        :data="Object.values(STAGETYPES)"
        :required="isStage"
        placeholder="Seleccionar"
        @on-select="setStageType"
      />
    </div>
    <div v-if="stageTypeData.key" class="pl-5">
      <p class="mb-3">{{ stageTypeData.description }}</p>
      <template v-if="isConfigByDate">
        <Field
          v-model="fieldVerde"
          v-maska="semaphoringByDate.verde"
          :disabled="isPlannedRun"
          horizontal
          placeholder="0"
          :error-message="errors.fieldVerde"
          v-bind="fieldVerdeAttrs"
          class="input-day has-text-verde"
          label="Verde:"
          data-maska="##"
        />
        <Field
          v-model="fieldAmarillo"
          v-maska="semaphoringByDate.amarillo"
          :disabled="isPlannedRun"
          horizontal
          placeholder="0"
          :error-message="errors.fieldAmarillo"
          v-bind="fieldAmarilloAttrs"
          class="input-day has-text-amarillo"
          label="Amarillo:"
          data-maska="##"
        />
        <Field
          v-model="fieldRojo"
          v-maska="semaphoringByDate.rojo"
          horizontal
          placeholder="0"
          :error-message="errors.fieldRojo"
          v-bind="fieldRojoAttrs"
          class="input-day has-text-rojo"
          label="Rojo:"
          data-maska="##"
          disabled
        >
          <template #addonsRight>
            <span class="addon-text"> EOM (End of Month/ Fin de Mes)</span>
          </template>
        </Field>
      </template>
      <div v-if="isPositiveNegative" class="is-flex is-flex-direction-column pl-0">
        <div class="columns is-1 is-variable">
          <Field
            v-model="fileName"
            class="column"
            type="text"
            maxlength="255"
            placeholder="Nombre archivo"
            :error-message="errors.fileName"
            v-bind="fileNameAttrs"
          />
          <CheckableDropdown
            v-model:selected-options="extencionField"
            v-bind="extencionFieldAttrs"
            class="column is-one-fifth"
            :error-message="errors.extencionField"
            placeholder="Extensión"
            :o-data="extencionArchivos"
            unique-value="name"
            position="top-left"
            required
          />
          <o-field class="column is-1 is-relative">
            <p class="checkbox-text">¿Requerido?</p>
            <CheckBox
              v-model="requiredDoc"
              v-bind="requiredDocAttrs"
              :disabled="!valueOnFileData"
              class="checkbox-required"
            />
          </o-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Field, Select, CheckableDropdown, CheckBox } from '@/components';
import { reactive, ref, watch, computed, toRefs, onMounted, nextTick } from 'vue';
import { useForm } from 'vee-validate';
import { clone } from '@/utils/Ramda';
import useStages from '@/utils/composables/useStages';
import * as yup from 'yup';

// Config
const props = defineProps({
  stage: { type: Object, default: () => ({}) },
  stageType: { type: Object, default: () => ({}) },
  form: { type: Object, default: () => ({}) },
  isEditable: { type: Boolean, default: false },
  isStage: { type: Boolean, default: false },
  isPlannedRun: { type: Boolean, default: false },
  monthlyRunId: { type: [String, Number], default: null },
});
const apiMonthlyDetails = ref(`monthly-run/${props.monthlyRunId}/monthly-run-details`);
const emit = defineEmits([
  'update:model-value',
  'update:form',
  'update:stage',
  'update:stage-type',
  'update:ready',
]);

const { stage, stageType } = toRefs(props);
const stageData = ref(props.stage);
const sequenceName = ref(props.form.sequenceName);
const isEdit = ref(props.isEditable);
const editRowFormData = ref(props.form);
const loading = ref(false);
const valueOnFileData = ref(false);
const stagesData = ref([]);
const stageTypeData = ref(props.stageType);
const semaphoringByDate = reactive({
  verde: {},
  amarillo: {},
  rojo: {},
});
const {
  STAGETYPES,
  RESPONSIBLE_ENTITIES,
  validationSchema,
  EOM,
  extencionArchivos,
  getStageNameById,
} = useStages({
  formStep: 'initialStep',
});
const responsibleEntities = [
  { name: RESPONSIBLE_ENTITIES.USUARIO },
  { name: RESPONSIBLE_ENTITIES.CLIENTE },
];

const { positiveNegative, positivaNegativaFecha, date, execution, files, filesDate } = STAGETYPES;

// FORM
const { defineField, setValues, handleSubmit, errors, schema, meta } = useForm({
  initialValues: {
    fieldRojo: props.form.fieldRojo ? props.form.fieldRojo : EOM.value,
    stageName: props.form.stageName,
    responsibleEntitie: props.form.responsibleEntitie,
    stageTypeName: props.form.stageTypeName,
    fieldVerde: props.form.fieldVerde,
    fieldAmarillo: props.form.fieldAmarillo,
    fileName: props.form.fileName,
    extencionField: props.form.extencionField ?? [],
    sequenceField: props.form.sequenceField,
    requiredDoc: props.form.requiredDoc ?? false,
  },
  validationSchema: clone(validationSchema.value),
});

// FORM FIELDS
const [stageName, stageNameAttrs] = defineField('stageName');
const [responsibleEntitie, responsibleEntitieAttrs] = defineField('responsibleEntitie');
const [stageTypeName, stageTypeAttrs] = defineField('stageTypeName');
const [fieldVerde, fieldVerdeAttrs] = defineField('fieldVerde');
const [fieldAmarillo, fieldAmarilloAttrs] = defineField('fieldAmarillo');
const [fieldRojo, fieldRojoAttrs] = defineField('fieldRojo');
const [fileName, fileNameAttrs] = defineField('fileName');
const [extencionField, extencionFieldAttrs] = defineField('extencionField');
const [sequenceField, sequenceFieldAttrs] = defineField('sequenceField');
const [requiredDoc, requiredDocAttrs] = defineField('requiredDoc');
const onSubmit = handleSubmit((_formData) => {
  emit('update:stage', stageData.value);
  emit('update:stage-type', stageTypeData.value);
  emit('update:form', { ...clone(_formData), sequenceName: sequenceName.value });
});
const setStageType = (_data) => (stageTypeData.value = stageTypeData.value = _data);

const isConfigByDate = computed(() => {
  const { key } = stageTypeData.value;
  const isFromDate = key === date.key;
  const isFromFiles = key === filesDate.key;
  const isPositiveNegative = positivaNegativaFecha.key === key;
  if (key) return isFromDate || isFromFiles || isPositiveNegative;
  return false;
});

const isPositiveNegative = computed(() => {
  const { key } = stageTypeData.value;
  const isPositiveNegative = key === positiveNegative.key;
  const isPositivaNegativaFecha = positivaNegativaFecha.key === key;
  if (key) return isPositiveNegative || isPositivaNegativaFecha;
  return false;
});
const setSemaphoringByDate = () => {
  const { semaphoring } = stageData.value;
  if (semaphoring !== undefined) {
    const { AMARILLO, ROJO, VERDE } = JSON.parse(semaphoring).config;
    setValues({
      fieldVerde: VERDE && VERDE.days ? `${VERDE.days}` : '0',
      fieldAmarillo: AMARILLO && AMARILLO.days ? `${AMARILLO.days}` : '0',
      fieldRojo: ROJO && ROJO.days ? `${ROJO.days}` : EOM.value,
    });
  }
};
const setRulesDate = () => {
  const lessThanEOM = {
    name: 'less than EOM',
    test(value, ctx) {
      const day = parseInt(value);
      if (day >= fieldRojo.value)
        return ctx.createError({
          message: `No debe superar ni ser igual a la fecha de fin de mes (${fieldRojo.value})`,
        });
      return true;
    },
  };
  const { schema: stageSchema } = stageTypeData.value;
  if (isConfigByDate.value) {
    setSemaphoringByDate();
    schema.fields.fieldVerde = stageSchema.indicatorVerde.test(lessThanEOM);
    schema.fields.fieldAmarillo = stageSchema.indicatorAmarillo.test(lessThanEOM).test({
      name: 'mayor than Verde',
      test(value, ctx) {
        const day = parseInt(value);
        if (day <= fieldVerde.value)
          return ctx.createError({
            message: 'Tiene que ser un valor mayor al indicador verde',
          });
        return true;
      },
    });
    schema.fields.fieldRojo = stageSchema.indicatorRojo;
  }
  if (!loading.value) {
    if (isPositiveNegative.value) {
      valueOnFileData.value = fileName?.value;
      watch([fileName, requiredDoc, extencionField.value], () => {
        if (extencionField.value.length || fileName.value.length) {
          valueOnFileData.value = true;
          if (fileName.value)
            schema.fields.extencionField = yup.array().min(1, 'Necesita seleccionar mínimo una');
          if (extencionField.value)
            schema.fields.fileName = yup.string().required('Completa este campo').max(255);
          if (requiredDoc.value === true) {
            schema.fields.fileName = yup.string().required('Completa este campo').max(255);
            schema.fields.extencionField = yup.array().min(1, 'Necesita seleccionar mínimo una');
          }
        }
        if (extencionField.value.length === 0 && fileName.value.length === 0) {
          schema.fields.fileName = yup.string().max(255);
          schema.fields.extencionField = yup.array();
          requiredDoc.value = false;
          valueOnFileData.value = false;
        }
      });
    }

    if (!Object.values(stageSchema).length) {
      schema.fields.fieldVerde = yup.string();
      schema.fields.fieldAmarillo = yup.string();
      schema.fields.fieldRojo = yup.string();
      schema.fields.fileName = yup.string();
      schema.fields.extencionField = yup.array().min(0);
    }
  }
  schema.fields.sequenceField = yup.string();
};

const preprocesorData = (stages) => {
  const endOfSequence = stages[stages.length - 1].sequence + 1;
  return [
    { name: 'Al inicio de todas las etapas', sequence: 1 },
    {
      name: 'Al final de todas las etapas',
      sequence: endOfSequence,
    },
    ...stages.map((value) => {
      value.name = `Antes de la etapa ${value.stage_name}`;
      return value;
    }),
  ];
};
const onSelectSequence = (value) => {
  sequenceField.value = value.sequence;
};
watch(
  () => meta.value.valid,
  (value) => {
    emit('update:ready', value);
    if (value) onSubmit();
  },
);
watch(extencionField, onSubmit, { deep: true });
watch([requiredDoc, fileName], () => {
  if (meta.value.valid) onSubmit();
});
watch(
  stageData,
  (value) => {
    if (value) {
      const { name, responsible_entity, stage_type_name } = value;
      if (
        stage_type_name === 'Positiva / Negativa' ||
        stage_type_name === 'Positiva / Negativa / Fecha'
      ) {
        const positiveNegativeDocument = JSON.parse(value.documents)[0];
        setValues({
          stageName: name,
          responsibleEntitie: responsible_entity,
          stageTypeName: stage_type_name,
          fieldVerde: undefined,
          fieldAmarillo: undefined,
          requiredDoc: positiveNegativeDocument.required ?? false,
          fileName: positiveNegativeDocument.document_name ?? '',
        });
      } else {
        setValues({
          stageName: name,
          responsibleEntitie: responsible_entity,
          stageTypeName: stage_type_name,
          fieldVerde: undefined,
          fieldAmarillo: undefined,
        });
      }
    }
    if (meta.value.valid) onSubmit();
  },
  { deep: true },
);

watch(stageTypeData, (newStage, oldStage) => {
  if (isEdit.value) {
    if (
      (oldStage.type === files.type ||
        oldStage.type === filesDate.type ||
        oldStage.type === positivaNegativaFecha.type ||
        oldStage.type === positiveNegative.type) &&
      (newStage.type === execution.type || newStage.type === date.type)
    )
      editRowFormData.value.documents = '[]';
    else if (
      (oldStage.type === files.type ||
        oldStage.type === filesDate.type ||
        oldStage.type === execution.type ||
        oldStage.type === date.type) &&
      (newStage.type === positivaNegativaFecha.type || newStage.type === positiveNegative.type)
    )
      editRowFormData.value.documents = '[]';
    else if (
      (oldStage.type === positivaNegativaFecha.type ||
        oldStage.type === positiveNegative.type ||
        oldStage.type === execution.type ||
        oldStage.type === date.type) &&
      (newStage.type === files.type || newStage.type === filesDate.type)
    )
      editRowFormData.value.documents = '[{}]';
  }
});

watch([stageTypeData, stageName, responsibleEntitie, fieldVerde, fieldAmarillo], () => {
  if (
    stageTypeData.value.key === execution.key ||
    stageTypeData.value.key === files.key ||
    stageTypeData.value.key === positiveNegative.key
  ) {
    fieldVerde.value = '';
    fieldAmarillo.value = '';
  }
  if (meta.value.valid) onSubmit();
});
watch(
  [fieldVerde, fieldAmarillo, stageTypeData, fileName, requiredDoc, extencionField.value],
  setRulesDate,
);
watch(stageType, (value) => (stageTypeData.value = value));
watch(stage, (value) => (stageData.value = value));
watch(stagesData, (value) => {
  if (props.isPlannedRun) {
    const stageNames = value
      .filter((_stage) => _stage.stage_name)
      .map((_stage) => {
        return _stage.stage_name;
      });
    schema.fields.stageName = schema.fields.stageName.notOneOf(
      stageNames,
      'Esta etapa ya está agregada',
    );
  }
});

watch(isPositiveNegative, () => {
  if (isEdit.value) {
    nextTick(() => {
      const myTimeout = setTimeout(positiveNegativeData, 100);
      return myTimeout;
    });
  }
});

const setSemaphoringData = () => {
  if (!editRowFormData.value.semaphoring) return;

  try {
    const semaphoringData = JSON.parse(editRowFormData.value.semaphoring);
    const daysYellow = semaphoringData.config.AMARILLO?.days;
    const daysGreen = semaphoringData.config.VERDE?.days;

    if (daysYellow === null || daysGreen === null) {
      fieldAmarillo.value = undefined;
      fieldVerde.value = undefined;
    } else {
      fieldAmarillo.value = daysYellow;
      fieldVerde.value = daysGreen;
    }
  } catch (error) {
    console.error(error);
  }
};

onMounted(async () => {
  if (isPositiveNegative.value) setRulesDate();
  if (isEdit.value) {
    loading.value = true;
    setSemaphoringData();
    stageName.value = editRowFormData.value.name || editRowFormData.value.stageName;
    responsibleEntitie.value =
      editRowFormData.value.responsible_entity || editRowFormData.value.responsibleEntitie;
    if (editRowFormData.value.stageTypeName) editRowFormData.value.stageTypeName;
    else {
      try {
        stageTypeName.value = await getStageNameById(editRowFormData.value.id);
      } catch (error) {
        console.log(error);
      }
    }
    loading.value = false;
  }
});
const positiveNegativeData = () => {
  if (editRowFormData.value.documents !== '[]') {
    if (editRowFormData.value.documents !== '[{}]') {
      const opinionData = JSON.parse(editRowFormData.value.documents);
      const opinionFileName = opinionData[0].document_name;
      const opinionRequirement = opinionData[0].required;
      let opinionMasks = opinionData[0].file_masks;
      opinionMasks = opinionMasks.replace(/[[\]']+/g, '').split(', ');
      const masksformattedData = opinionMasks.map((extension) => ({ name: extension }));
      fileName.value = opinionFileName;
      extencionField.value = masksformattedData;
      requiredDoc.value = opinionRequirement;
    }
  }
};
</script>
<style lang="sass" scoped>
#step1-add-stage
  color: $black
  font-size: $f-sm
  .w-50
    width: calc( 50% - 12px )
  .text-more-info
    white-space: nowrap
    font-size: $f-sm
    color: $grey-info
  .addon-text
    vertical-align: sub
    font-size: $f-sm
    color: $grey-dark

  .has-text-verde > :deep(.field-label) .label
    color: $primary
  .has-text-amarillo > :deep(.field-label) .label
    color: $color-orange
  .has-text-rojo > :deep(.field-label) .label
    color: $color-radical-red
  .addon-text
    margin-left: 8px
    margin-top: 7.5px
  :deep(.checkbox-required)
    margin: .25rem 0 0 .85rem
  .checkbox-text
    font-weight: 700
    font-size: $f-xs
    position: absolute
    top: -1rem
    left: 0rem
  .input-day >
    :deep(.field-label)
      margin-right: 8px
      .label
        margin: 0
        font-weight: 400
        margin-top: 7.5px
    :deep(.field-body)
      .control input, .field.has-addons .control
        width: 125px
</style>

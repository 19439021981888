<template>
  <template v-if="hasPermission">
    <o-skeleton v-if="skeletonLoading" width="20px" height="20px" animated />
    <o-checkbox
      v-else
      v-model="active"
      :disabled="disabled"
      v-bind="{ ...CheckBoxProps }"
      @blur="onBlur"
      @focus="onFocus"
    >
      <slot v-if="$slots.default" />
      <template v-else>
        {{ label }}
      </template>
    </o-checkbox>
  </template>
</template>
<script>
import { PermissionValidator } from '@/utils/Secure';
import { usePickProps } from './conf/composables';
import { computed, ref, toRefs, watch } from 'vue';

export default {
  inheritAttrs: false,
  props: {
    label: { type: String, default: '' },
    permission: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
    skeletonLoading: { type: Boolean, default: null },
    modelValue: { type: Boolean, default: false },
  },
  emits: ['update:model-value', 'blur', 'focus'],
  setup(props, { attrs, emit }) {
    const { modelValue } = toRefs(props);
    const active = ref(props.modelValue);
    const { propsPicked: CheckBoxProps } = usePickProps('CheckBoxProps', { props, attrs });

    const onBlur = (event) => {
      emit('blur', event);
    };
    const onFocus = (event) => {
      emit('focus', event);
    };

    const hasPermission = computed(() => {
      if (!props.permission || props.permission === true) return true;
      return PermissionValidator(props.permission);
    });

    watch(modelValue, (value) => (active.value = value));
    watch(active, (value) => emit('update:model-value', value));
    return {
      hasPermission,
      CheckBoxProps,
      active,

      // method
      onBlur,
      onFocus,
    };
  },
};
</script>
<style lang="sass" scoped>
.b-skeleton
  width: auto
:deep(.o-load).loading
  .o-load__overlay
    background: unset
  .icon .mdi::before
    font-size: 24px !important
</style>

<template>
  <div class="modal-component">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">{{ title }}</div>
      </div>
      <div class="card-content">
        <!-- eslint-disable-next-line -->
        <h1 v-html="message"></h1>
      </div>
      <div class="card-footer">
        <Button
          :variant="rejectVariant"
          :disabled="isLoading"
          :label="rejectLabel"
          inverted
          @click.prevent="$emit('close', { action: 'no' })"
        />
        <Button
          :variant="confirmVariant"
          :disabled="isLoading"
          :loading="isLoading"
          :label="confirmLabel"
          @click.prevent="onConfirm"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from '@/components';
import { getCurrentInstance, ref } from 'vue';
import { useComponentUtils } from '@/components/conf/composables';
import useDialog from '@/utils/composables/useDialog';

export default {
  components: {
    Button,
  },
  props: {
    action: { type: Function, default: null },
    message: { type: String, default: '' },
    title: { type: String, default: '¿Estás seguro de que deseas realizar esta acción?' },
    forceReload: { type: Function, default: null },
    needReloadTable: { type: Boolean, default: true },
    cleanReload: { type: Boolean, default: false },
    confirmLabel: { type: String, default: 'Si' },
    rejectLabel: { type: String, default: 'No' },
    confirmVariant: { type: String, default: 'primary' },
    rejectVariant: { type: String, default: 'danger' },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    // eslint-disable-next-line no-unused-vars
    const Api = proxy?.Api;
    const { table } = useComponentUtils();
    const isLoading = ref(false);
    const reload = async () => {
      if (table.value) {
        await table.value.reload(props.cleanReload);
      }
    };
    const { Notify } = useDialog();

    const onConfirm = async () => {
      let isSuccefullChange = false;
      isLoading.value = true;
      try {
        if (props.action) await props.action();
        if (props.needReloadTable) await reload();
        if (props.forceReload) await props.forceReload();
        emit('close', { action: 'yes' });
      } catch (error) {
        console.log(error);
        if (!isSuccefullChange) {
          Notify('danger', 'No se logro realizar la acción');
        }
        emit('close', { action: 'no' });
      }
      isLoading.value = false;
    };
    return {
      isLoading,
      onConfirm,
      table,
    };
  },
};
</script>
<style lang="sass" scoped>
.modal-component
  z-index: 1000
  :deep(.animation-content)
    width: 100%
  .card
    color: $black
    border-radius: 5px
    width: 100%
    .card-header
      padding: 16px
      color: #FFFFFF
      background-color:  $primary
      .card-header-title
        color: white
        font-weight: 600
        padding: 0
        font-size: $f-sm
      :deep(.icon)
        margin: auto
        &:hover
          cursor: pointer
    .card-content
      padding: 40px 38px
    .card-footer
      display: flex
      justify-content: flex-end
      background-color: $grey-light
      padding: 17px 10px
      :deep(.button)
        margin-left: 10px
        padding: 0px 10px
        font-weight: 400
        height: 30px
        &.is-primary
          min-width: 88px
</style>

<template>
  <Transition name="collapse" @after-leave="(e) => $emit('after-leave', e)">
    <div v-if="isShow" class="panelTools collapsableDiv">
      <FadeIn @on-close-panel="closeHandlre">
        <slot />
      </FadeIn>
    </div>
  </Transition>
</template>
<script>
import { watch, ref, toRefs } from 'vue';
import { FadeIn } from '../transition';
export default {
  components: {
    FadeIn,
  },
  props: {
    modelValue: { type: Boolean, default: false },
  },
  emits: ['update:model-value', 'after-leave'],
  setup(props, { emit }) {
    const isShow = ref(props.modelValue);
    const panelOpen = toRefs(props).modelValue;

    watch(panelOpen, (value) => (isShow.value = value));
    watch(isShow, (value) => emit('update:model-value', value));

    return {
      isShow,
      closeHandlre: () => {
        isShow.value = false;
      },
    };
  },
};
</script>
<style lang="sass" scoped>
.collapse
  z-index: 10000
.collapsableDiv
  display: flex
  background-color: $white
  width: 100%
  margin-top: -1px
  overflow: hidden
  padding: 24px 24px 8px 24px
  border-top: 1px solid $grey-300 !important
  box-shadow: 0px 9px 10px 0px rgba(0,0,0,0.32)
.collapse-enter-active
  animation: collapse reverse 250ms ease-in
.collapse-leave-active
  animation: collapse 250ms ease-out
@keyframes collapse
  0%
    max-height: 200px
  50%
    max-height: 100px
  100%
    max-height: 0px
</style>

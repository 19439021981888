export default [
  'addons',
  'autocomplete',
  'autosize',
  'clearable',
  'expanded',
  'group-multiline',
  'grouped',
  'groupMultiline',
  'has-counter',
  'hasCounter',
  'disabled',
  'icon-clickable',
  'icon-right-clickable',
  'icon-right-variant',
  'icon-right',
  'icon',
  'iconClickable',
  'iconRight',
  'iconRightClickable',
  'iconRightVariant',
  'is-password-visible',
  'isPasswordVisible',
  'maxlength',
  'mobile-breakpoint',
  'override',
  'password-reveal',
  'passwordReveal',
  'pattern',
  'placeholder',
  'rounded',
  'rounded',
  'size',
  'statusIcon',
  'type',
  'use-html5-validation',
  'useHtml5Validation',
  'validation-message',
  'validationMessage',
  'value',
  'v-maska',
  'data-maska',
  'data-maska-tokens',
  'step',
  'readonly',
];

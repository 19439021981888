<template>
  <Modal
    id="modal-change-password"
    v-model:is-open="isModalActive"
    :save-disabled="isDisabled"
    :loading="loading"
    button-label="Restablecer contraseña"
    size="is-medium"
    @save="sendRequest"
  >
    <SetPassword
      ref="setPasswordComponent"
      hide-button
      @button-disabled="(value) => (isDisabled = value)"
    />
  </Modal>
</template>
<script>
import { Modal, SetPassword } from '@/components';
import { toRefs, watch, ref, reactive } from 'vue';
export default {
  components: { Modal, SetPassword },
  props: {
    isOpen: { type: Boolean, default: false },
  },
  emits: ['update:active', 'update:is-open'],
  setup(props, { emit }) {
    const loading = reactive({ save: false, get: false });
    const { isOpen } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const isDisabled = ref(true);
    const setPasswordComponent = ref(null);

    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      if (!value) {
        isDisabled.value = true;
      }
    });
    const sendRequest = async () => {
      loading.save = true;
      await setPasswordComponent.value.onSubmit();
      isModalActive.value = false;
      loading.save = false;
    };
    return { isModalActive, isDisabled, setPasswordComponent, sendRequest, loading };
  },
};
</script>
<style lang="sass" scoped>
#modal-change-password
</style>

export default [
  'appendToBody',
  'append-to-body',
  'ariaNextLabel',
  'aria-next-abel',
  'ariaPreviousLabel',
  'aria-previous-label',
  'autocomplete',
  'closeOnClick',
  'close-on-click',
  'dateCreator',
  'date-creator',
  'dateFormatter',
  'date-formatter',
  'dateParser',
  'date-parser',
  'dayNames',
  'day-names',
  'disabled',
  'editable',
  'events',
  'expanded',
  'firstDayOfWeek',
  'first-day-of-week',
  'focusedDate',
  'focused-date',
  'icon',
  'iconNext',
  'icon-next',
  'iconPack',
  'icon-pack',
  'iconPrev',
  'icon-prev',
  'iconRight',
  'icon-right',
  'iconRightClickable',
  'icon-right-clickable',
  'indicators',
  'inline',
  'locale',
  'maxlength',
  'mobileBreakpoint',
  'mobileModal',
  'mobile-modal',
  'mobileNative',
  'mobile-native',
  'month-names',
  'multiple',
  'nearby-month-days',
  'nearbySelectableMonthDays',
  'openOnFocus',
  'open-on-focus',
  'override',
  'placeholder',
  'position',
  'range',
  'rounded',
  'rulesForFirstWeek',
  'rules-for-firstWeek',
  'selectableDates',
  'showWeekNumber',
  'trapFocus',
  'selectable-dates',
  'show-week-number',
  'trap-focus',
  'type',
  'unselectableDates',
  'unselectable-dates',
  'unselectableDaysOfWeek',
  'unselectable-days-of-week',
  'useHtml5Validation',
  'use-html5-validation',
  'validationMessage',
  'validation-message',
  'weekNumberClickable',
  'week-number-clickable',
  'yearsRange',
  'years-range',
];

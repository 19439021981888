<template>
  <div>
    <Field v-model="password" icon="key" :label="label" password-reveal required type="password" />
  </div>
  <div class="passwordRules">
    <p class="mt-2">La contraseña debe contener:</p>
    <div v-for="rule in rules" :key="rule.id" :class="rule.class" class="is-flex">
      <div class="icon-container">
        <o-icon v-if="rule.isValid" icon="check-circle" />
        <o-icon v-else-if="rule.isValid === false" icon="circle" />
        <o-icon v-else icon="circle-medium" />
      </div>
      {{ rule.text }}
    </div>
  </div>
  <br />
</template>
<script>
import { Field } from '@/components';
import { computed, ref, toRefs, watch } from 'vue';
export default {
  components: {
    Field,
  },
  inheritAttrs: false,
  props: {
    modelValue: { type: String, default: '' },
    label: { type: String, default: '' },
    minLength: { type: Number, default: 8 },
    noBlankSpaces: { type: Boolean, default: true },
    placeholder: { type: String, default: '' },
    useLowercase: { type: Boolean, default: true },
    useNumbers: { type: Boolean, default: true },
    useSpecialChars: { type: Boolean, default: true },
    useUppercase: { type: Boolean, default: true },
  },
  emits: ['input', 'update:model-value'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const password = ref(props.modelValue);
    const passwordStatus = () => {
      const pendingRules = rules.value.filter((r) => !r.isValid);
      const invalidRules = pendingRules.filter((r) => r.isValid === false);
      return { hasErrors: !!invalidRules[0], isValid: !pendingRules[0] };
    };
    const validatorHelper = (isValid) => {
      return { class: isValid === null ? '' : isValid ? 'valid' : 'invalid', isValid };
    };
    const useNumbersRule = computed(() => {
      let isValid = null;
      if (password.value) isValid = /[0-9]/.test(password.value);
      return {
        id: 'un',
        text: 'Números',
        ...validatorHelper(isValid),
      };
    });
    const minLengthRule = computed(() => {
      let isValid = null;
      if (password.value) isValid = /[\W\w\d]{8}/.test(password.value);
      return {
        id: 'ml',
        text: 'Mínimo 8 caracteres',
        ...validatorHelper(isValid),
      };
    });
    const useLowercaseRule = computed(() => {
      let isValid = null;
      if (password.value) isValid = /[a-z]/.test(password.value);
      return {
        id: 'lc',
        text: 'Minúsculas',
        ...validatorHelper(isValid),
      };
    });
    const useUppercaseRule = computed(() => {
      let isValid = null;
      if (password.value) isValid = /[A-Z]/.test(password.value);
      return {
        id: 'uc',
        text: 'Mayúsculas',
        ...validatorHelper(isValid),
      };
    });
    const useSpecialCharsRule = computed(() => {
      let isValid = null;
      if (password.value) isValid = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~Ññ]/.test(password.value);
      return {
        id: 'us',
        text: 'Caracteres especiales',
        ...validatorHelper(isValid),
      };
    });
    const rules = computed(() => {
      const rules = [];
      if (props.useNumbers) rules.push(useNumbersRule.value);
      if (props.minLength) rules.push(minLengthRule.value);
      if (props.useLowercase) rules.push(useLowercaseRule.value);
      if (props.useUppercase) rules.push(useUppercaseRule.value);
      if (props.useSpecialChars) rules.push(useSpecialCharsRule.value);
      return rules;
    });
    watch(passwordStatus, (value) => {
      emit('input', value.isValid ? password.value : '');
    });
    watch(modelValue, (value) => (password.value = value));
    watch(password, (value) => emit('update:model-value', value));
    return {
      password,
      minLengthRule,
      useLowercaseRule,
      useNumbersRule,
      useSpecialCharsRule,
      useUppercaseRule,
      rules,
    };
  },
};
</script>
<style lang="sass" scoped>
.passwordRules
  color: $black
  font-size: $f-sm
  .valid
    color: $primary
  .invalid
    color: $danger
  .icon-container
    display: flex
    align-items: center
    width: 20px
    :deep(.icon)
      line-height: 14px
      height: 14px
      i::before
        font-size: 14px
</style>

<template>
  <div class="operation-detail-client">
    <o-tabs ref="tabsOperations" type="boxed">
      <o-tab-item>
        <template #header>
          <o-icon class="icon-header" icon="eye"></o-icon>
          <span class="header-title">Observaciones</span>
        </template>
        <template v-if="observations.length">
          <div class="comments is-flex">
            <div class="comments-review">
              <template v-for="(observation, key) in observations" :key="`o-00${key}`">
                <MessageCard
                  disable-actions
                  :message="observation"
                  :position="key"
                  target="observations"
                />
              </template>
            </div>
          </div>
        </template>
        <div
          v-else
          class="empty is-flex is-justify-content-center is-align-items-center is-flex is-justify-content-center is-align-items-center"
        >
          <h2>Sin observaciones.</h2>
        </div>
      </o-tab-item>
      <o-tab-item>
        <template #header>
          <o-icon class="icon-header" icon="message-reply-text"></o-icon>
          <span class="header-title">Recomendaciones</span>
        </template>
        <template v-if="recomendations.length">
          <div class="comments is-flex">
            <div class="comments-review">
              <template v-for="(observation, key) in recomendations" :key="`r-00${key}`">
                <MessageCard
                  disable-actions
                  :message="observation"
                  :position="key"
                  target="recomendations"
                />
              </template>
            </div>
          </div>
        </template>
        <div
          v-else
          class="empty is-flex is-justify-content-center is-align-items-center is-flex is-justify-content-center is-align-items-center"
        >
          <h2>Sin recomendaciones.</h2>
        </div>
      </o-tab-item>
      <o-tab-item :disabled="isConclusionsDisabled">
        <template #header>
          <o-icon class="icon-header" icon="dots-horizontal-circle"></o-icon>
          <span class="header-title">Conclusiones</span>
        </template>
        <div class="comments is-flex">
          <div class="comments-review">
            <template v-for="(observation, key) in conclusions" :key="`co-00${key}`">
              <MessageCard
                v-if="observation.message"
                disable-actions
                :message="observation"
                :position="key"
                target="conclusions"
              />
            </template>
          </div>
        </div>
      </o-tab-item>
    </o-tabs>
  </div>
</template>
<script>
import { MessageCard } from '@/components';
import { ref, computed, onBeforeMount, onMounted } from 'vue';
export default {
  components: {
    MessageCard,
  },
  props: {
    data: { type: [Object, Array], default: () => {} },
    isResponsible: { type: Boolean, default: false },
  },
  emits: ['openModalRequest', 'update:operation-data'],
  setup(props) {
    const tabsOperations = ref({});

    const observations = ref([]);
    const recomendations = ref([]);
    const conclusions = ref([]);

    const isConclusionsDisabled = computed(() => !conclusions.value.length);

    const dataParse = (_service = 'ALL') => {
      if (_service === 'ALL' || _service === 'observations')
        observations.value = JSON.parse(props.data.observations);
      if (_service === 'ALL' || _service === 'recomendations')
        recomendations.value = JSON.parse(props.data.recomendations);
      if (_service === 'ALL' || _service === 'conclusions')
        conclusions.value = JSON.parse(props.data.conclusions);
    };

    onBeforeMount(dataParse);
    onMounted(() => {
      if (props.data.status === 'FINALIZADA' || props.data.conclusion_request)
        tabsOperations.value.activeId = 3;
    });
    return {
      isConclusionsDisabled,
      tabsOperations,
      observations,
      recomendations,
      conclusions,
    };
  },
};
</script>
<style lang="sass" scoped>
.operation-detail-client
  max-width: calc(91vw - 32px)
  z-index: 0
  :deep(.b-tabs)
    margin-bottom: 0
    .tabs.is-boxed div a.is-active
      background-color: unset !important
    .tab-content
      height: calc( 100% - 40px )
      max-height: 316px
      padding: 16px 0
      padding-bottom: 0
      .tab-item
        height: 100%
        .comments
          justify-content: space-between
          :deep(.o-load)
            min-width: unset
          .comments-review
            display: grid
            width: 100%
            grid-template-columns: repeat(auto-fit,  minmax(355px, 32%))
            max-height: 314px
            gap: 8px
            .comment
              width: 100%
              margin: 0
              min-width: unset
              max-width: unset
        @include until($bp-md)
          .comments-review
            justify-content: center
            .comment
              min-width: 250px
        @include from($bp-md)
          .comments-review
            justify-content: flex-start
  .empty
    color: $grey-dark
    font-size: $fh-md
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    height: 100%
    width: 100%
    margin: auto
    h2
      font-weight: 600
</style>

<template>
  <a
    class="download-link f-sm has-text-weight-semibold has-text-info"
    :class="classes"
    role="button"
    @click="onDownloadFile"
  >
    <o-icon
      v-if="iconLeft"
      :icon="iconLeft"
      size="small"
      :variant="disabled ? 'neutral' : 'info'"
    ></o-icon>
    <span> {{ fileNameFormated }} </span>
  </a>
</template>
<script>
import { downloadFile } from '@/utils/Presigned';
import { computed } from 'vue';
export default {
  props: {
    iconLeft: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    apiUrl: { type: String, default: '' },
    fileName: { type: String, required: true },
    cutName: { type: Boolean, default: false },
    label: { type: String, default: null },
  },
  setup(props) {
    const classes = computed(() => {
      const _classes = [];
      if (props.disabled) _classes.push('is-disabled');
      return _classes.join(' ');
    });
    const onDownloadFile = async () => {
      try {
        if (props.disabled) return;
        const { apiUrl, fileName } = props;
        const src = await downloadFile({ apiUrl, fileName });
        if (src) window.location = src;
      } catch (error) {
        console.error(error);
      }
    };
    const fileNameFormated = computed(() => {
      const _filename = props.fileName;
      if (props.cutName) {
        const [name, ext] = _filename.split('.');
        const exceededCharacters = name.length - ext.length - 47;
        return `${name.slice(0, -exceededCharacters).trim()}...${name.slice(
          name.length - 3,
        )}.${ext}`;
      } else if (props.label) return props.label;
      else return _filename;
    });
    return {
      classes,
      onDownloadFile,
      fileNameFormated,
    };
  },
};
</script>
<style lang="sass" scoped>
.download-link
  overflow-wrap: anywhere
  white-space: break-spaces
  &.is-disabled
    color: $grey-info !important
    pointer-events: none
</style>

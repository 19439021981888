import { useAuthStore } from '@/store';

class Secure {
  constructor() {}
  PermissionValidator(permission = {}) {
    if (permission === null) return true;

    const authStore = useAuthStore();
    const isAllowed = !!authStore.Permissions.find(
      ({ path, method }) => path === permission.path && method === permission.method,
    );

    return isAllowed;
  }
}

export default new Secure();

<template>
  <o-steps
    v-model="activeStep.index"
    :animated="true"
    :destroy-on-hide="true"
    @change="onStepChanged"
  >
    <o-step-item v-for="section in mySections" :key="section.id">
      <h4 class="section-title has-text-centered">{{ section.caption }}</h4>
      <div class="columns flow-wrap">
        <section
          v-for="(question, i) in section.questions"
          :key="question.id"
          :class="handlerConditionalHideQuestion(question) ? `column is-${question.columns}` : ''"
        >
          <question
            v-if="handlerConditionalHideQuestion(question)"
            :question="question"
            :section-id="section.id"
            :index="i"
            :is-visualizator="isVisualizator"
            :presigned="presigned"
            :authorization-token="authorizationToken"
            @enter-key="nextStep"
            @conditional-hide-question="handlerConditionalHideQuestion"
            @update-question="handleUpdateQuestion"
          />
        </section>
      </div>
    </o-step-item>
    <template #navigation="{ previous, next }">
      <span class="formViewerFooter">
        <span v-if="showCancelPreview" class="formViewerExitPreview">
          <o-button class="btn-step" outlined icon-pack="mdi" @click.prevent="cancelQuestionaire">
            Salir
          </o-button>
        </span>
        <span class="formViewerNavigationBtns">
          <o-button
            class="btn-step"
            outlined
            icon-pack="mdi"
            icon-left="chevron-left"
            :disabled="previous.disabled"
            @click.prevent="previousStep"
          >
            {{ previousText }}
          </o-button>
          <span v-if="!next.disabled">
            <o-button
              class="btn-step"
              outlined
              icon-pack="mdi"
              icon-right="chevron-right"
              :disabled="next.disabled"
              @click.prevent="nextStep"
            >
              {{ nextText }}
            </o-button>
          </span>
          <span v-if="next.disabled">
            <o-button class="btn-step" outlined icon-pack="mdi" @click.prevent="endQuestionaire">
              {{ endText }}
            </o-button>
          </span>
        </span>
      </span>
    </template>
  </o-steps>
</template>
<script src="./Steps.js"></script>

export default [
  'autocomplete',
  'expanded',
  'icon',
  'iconPack',
  'icon-pack',
  'iconRight',
  'icon-right',
  'maxlength',
  'multiple',
  'nativeSize',
  'native-size',
  'override',
  'placeholder',
  'rounded',
  'size',
  'statusIcon',
  'status-icon',
  'useHtml5Validation',
  'use-html5-validation',
  'v-model',
  'validationMessage',
  'validation-message',
  'variant',
];

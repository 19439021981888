<template>
  <span class="tag" :class="classes" :style="styles">
    <template v-if="$slots.default">
      <slot name="default" />
    </template>
    <template v-else>
      {{ label }}
    </template>
  </span>
</template>
<script>
import { computed } from 'vue';
import tinycolor from 'tinycolor2';

export default {
  props: {
    rounded: { type: Boolean, default: false },
    customColor: { type: String, default: '' },
    expanded: { type: Boolean, default: false },
    label: { type: String, default: '' },
    size: { type: String, enum: ['small', 'medium', 'large'], default: 'medium' },
    variant: {
      type: [String, Array],
      enum: [
        'primary',
        'bluehues',
        'link',
        'info',
        'success',
        'warning',
        'danger',
        'yellow',
        'purple',
        'neutral',
      ],
      default: 'neutral',
    },
  },
  setup(props) {
    const findColorInvert = (color) => {
      const _color = tinycolor(color);
      let invertColor = '#fff';
      if (_color.getLuminance() > 0.55) invertColor = 'rgba(#000, 0.7)';
      return invertColor;
    };

    const classes = computed(() => {
      const { rounded, variant, size, expanded } = props;
      const classes = [];
      if (typeof variant === 'object' && variant.length)
        variant.forEach((v) => classes.push(`is-${v}`));
      else classes.push(`is-${variant}`);
      classes.push(`is-${size}`);
      if (rounded) classes.push(`is-rounded`);
      if (expanded) classes.push(`is-expanded`);
      return classes.join(' ');
    });

    const styles = computed(() => {
      const { customColor } = props;
      const styles = [];
      if (customColor.length) {
        const textColor = findColorInvert(customColor);
        styles.push(`background: #${customColor};color: ${textColor};`);
      }
      return styles.join('');
    });
    return {
      classes,
      styles,
    };
  },
};
</script>

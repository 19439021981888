<template>
  <a
    v-if="!skeletonLoading"
    class="link"
    :class="{ disabled: disabled }"
    :disabled="disabled"
    @click.prevent="onClick"
  >
    <span v-if="iconLeft" :class="classes" class="icon mr-1">
      <o-icon :icon="iconLeft" />
    </span>
    <span :class="classes" class="label">
      {{ label }}
    </span>
    <span v-if="iconRight" :class="classes" class="icon ml-1">
      <o-icon :icon="iconRight" />
    </span>
  </a>
  <o-skeleton v-else width="80px" height="40px" animated />
</template>
<script>
import { computed } from 'vue';
export default {
  props: {
    label: { type: String, default: '' },
    iconRight: { type: String, default: '' },
    iconLeft: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    skeletonLoading: { type: Boolean, default: null },
    variant: {
      type: [String, Array],
      enum: ['white', 'light', 'dark', 'primary', 'link', 'info', 'success', 'warning', 'danger'],
      default: 'black',
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const classes = computed(() => {
      const { variant, disabled } = props;
      let classes = [];
      if (typeof variant === 'object' && variant.length) {
        variant.forEach((v) => classes.push(`has-text-${v}`));
      } else {
        classes.push(`has-text-${variant}`);
      }
      if (disabled) {
        classes = [];
        classes.push('has-text-grey-light');
      }
      return classes.join(' ');
    });

    const onClick = (e) => {
      const { disabled } = props;
      if (!disabled) {
        emit('click', e);
      }
    };

    return {
      classes,
      onClick,
    };
  },
};
</script>
<style lang="sass" scoped>
.link
  margin: 0 5px
  text-align: center
  justify-content: space-evenly
  &.disabled
    pointer-events: none
  .label
    display: inline-block
    margin: 0
    vertical-align: top
    font-weight: inherit
    &:hover
      text-decoration: underline
  .icon
    display: inline-block
    margin: 0
</style>

export const user = [
  'id',
  'full_name',
  'email',
  'is_active',
  'service_id',
  'notification_suscribe',
  'role',
  'Authorization',
];
export const client = [
  ...user,
  'is_active_bills',
  'customer_id',
  'customer_associates_id',
  'customer_associates_name',
  'customer_associates_role',
  'customer_asscoates_email',
  'customer_associate_phone_number',
];

export default [
  'ariaLabelledby',
  'aria-labelledby',
  'disabled',
  'false-value',
  'name',
  'nativeValue',
  'native-value',
  'override',
  'passiveVariant',
  'position',
  'required',
  'rounded',
  'size',
  'trueValue',
  'true-value',
  'variant',
];

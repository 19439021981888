<template>
  <ModalChangePassword v-model:is-open="isOpen" />
  <o-collapse
    v-model:open="collapseOpen"
    v-click-outside="closeCollapse"
    class="user-option-colapse"
    aria-id="contentIdForA11y1"
  >
    <template #trigger>
      <span class="f-xs is-uppercase">{{ userRole }}</span>
      <o-tooltip
        :active="collapseOpen ? false : undefined"
        class="link-options"
        label="Opciones"
        position="bottom"
        variant="dark"
      >
        <o-icon
          :class="collapseOpen ? 'has-text-primary' : 'has-text-white'"
          icon="cog"
          role="button"
        />
      </o-tooltip>
    </template>
    <section id="user-config" ref="userMenu">
      <o-menu>
        <o-menu-list class="reset-password">
          <o-menu-item
            v-if="isClient && allowBills && getAUTH.is_active_bills"
            tag="o-button"
            role="button"
            inverted
            variant="primary"
            class="f-sm is-justify-content-start w-100 has-text-black mb-1"
            label="Facturación"
            @click="onNavigate(`/facturacion/${getAUTH.customer_id}`)"
          />
          <o-menu-item
            tag="o-button"
            role="button"
            inverted
            variant="primary"
            class="f-sm w-100 has-text-black is-justify-content-start"
            label="Cambiar contraseña"
            @click="() => (isOpen = true)"
          />
        </o-menu-list>
      </o-menu>
    </section>
  </o-collapse>
</template>
<script>
import ModalChangePassword from '@/components/modals/ModalChangePassword.vue';
import { useAuthStore } from '@/store';
import { userRoles } from '@/config/constants';
import { Permissions } from '@/utils/Secure';
import { PermissionValidator } from '@/utils/Secure';
import { Button } from '@/components';
import { useRouter } from 'vue-router';

import { ref, computed } from 'vue';
export default {
  components: {
    ModalChangePassword,
  },
  props: {
    userRole: { type: String, default: '' },
  },
  setup() {
    const { getAUTH } = useAuthStore();
    const collapseOpen = ref(false);
    const isOpen = ref(false);
    const router = useRouter();

    const isClient = computed(() => getAUTH?.role === userRoles.CLIENTE);
    const allowBills = computed(() => {
      const { ReadAll } = Permissions.ClientsBills;
      return PermissionValidator(ReadAll);
    });

    const closeCollapse = () => (collapseOpen.value = false);
    return {
      closeCollapse,
      allowBills,
      collapseOpen,
      isOpen,
      Button,
      getAUTH,
      isClient,
      onNavigate: (path) => {
        if (path) router.push({ path });
      },
    };
  },
};
</script>
<style lang="sass" scoped>
.link-options
    &:hover
        cursor: pointer
    :deep(.tooltip-content)
        top: calc(100% + 5px + 14px) !important
    :deep(.icon)
        &.has-text-primary .mdi::before
            color: $second
        .mdi::before
            font-size: 16px
.user-option-colapse
    width: fit-content
    position: relative
#user-config
    background: white
    border-radius: 8px
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
    margin-top: 7px
    padding: 16px
    position: absolute
    right: 0
    width: max-content
    z-index: 1
    .reset-password :deep(.menu-list) a
        color: $grey-dark-second !important
</style>

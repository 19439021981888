export const vapidKey =
  'BOuwLArCytDZMTSk5wXHAAd2ruA7mr_hdvUGGW6RAeRpM1ds70ugtJX7YyxELKDTWk_aYv-f6AMEfjaDkdMrvCc';
export const config = {
  apiKey: 'AIzaSyBjT-amRK0rKGAEnjiTadrjmu75fXN9xCU',
  authDomain: 'despacho-prod.firebaseapp.com',
  projectId: 'despacho-prod',
  storageBucket: 'despacho-prod.appspot.com',
  messagingSenderId: '187213298527',
  appId: '1:187213298527:web:a7c1aa2a4c6b5b3c2094ea',
  measurementId: 'G-DZY4HNY6JD',
};
export default [
  'clickable',
  'component',
  'customClass',
  'custom-class',
  'customSize',
  'custom-size',
  'icon',
  'pack',
  'rotation',
  'size',
  'spin',
  'variant',
];
